import React, { Component } from 'react';

import {QrCodeToLink} from '../common/QrCodeToLink';
import {SampleUrls} from '../common/Constants';

export class Samples extends Component {

  async componentDidMount() 
  {
 
  }


  render () {
   

    return (
      <div id="Samples" className="parallexContainer" style={{"position" : "relative"}}>
         
      

			<div className="public Samples ColorBlock pt-0 pb-0" >

         
				<div className="Sample">
                  <div className="ColorBlockHeader">   
                    <h2 className="Title" style={{"color":"white"}}> Try Web AR</h2>
                  </div>
                  <div >
                      <div className="arrowContainer">
                          <div className="arrow"><h2></h2></div>
                      </div>
                      <div className="orangeContainer notMobile">
                          <div> Scan the </div>
                          <div> QR Codes with </div>
                          <div> your phone! </div>
                      </div>

                      <div className="orangeContainer mobile">
                        <div>Click on the</div>
                        <div>buttons below!</div>
                      </div>
                  </div>
              </div>
              <div className="Sample">
                <QrCodeToLink t={this.props.t} relativePath = { SampleUrls.sample1.webArLink} text="Superimpose Poster"> </QrCodeToLink>
              </div>
              <div className="Sample">
                <QrCodeToLink t={this.props.t} relativePath = { SampleUrls.sample2.webArLink} text="Braun L2 Speaker, Dieter Rams"> </QrCodeToLink>   
              </div>
              <div className="Sample">
                <QrCodeToLink t={this.props.t} relativePath = { SampleUrls.sample3.webArLink} text="Human Mannequin"> </QrCodeToLink>
              </div>
          </div> 

          

          { <div className="verticalLine" style={{"position" : "absolute", "width" : "100%", "height" : "100%", "top" : "0px"}}>
              <div style={{"position" : "absolute","height" : "100%", "left" : "50%", "width": "0px",  border:"solid 1px #969696" }}></div>
              {/* <div style={{"position" : "absolute","width" : "100%", "height" : "0px", "top" : "50%",  border:"solid 0.5px #969696" }}></div> */}
            </div>
          }
        </div>
    );
  }
}
