import React, { Component, Fragment, useState } from 'react';
import './public.css';
import Request from '../common/Request';
import Utilities from '../common/Utilities';
import { Validation, Paths } from '../common/Constants';
import { Redirect } from 'react-router-dom';
import OverlayContainer from '../common/OverlayContainer';
import { useForm } from "react-hook-form";

export function PasswordForgotten (props){
  var auth = props.auth;
  const [successfullySent, setSuccessfullySent] = useState(false);



	const { handleSubmit, register, getValues, errors, setError, clearError, formState, triggerValidation } = useForm();

    const onSubmit = async (values) => {

        var email = values.email;
        clearError("credentialsInvalid")
        var result = await Request.Post(Paths.ForgotPassword, { Email:  email});
        if (result.ok){
          //Utilities.storeAccessToken(result.data.accessToken);
          //auth.populateState();
          setSuccessfullySent(true);
        }
        else{
          setError("credentialsInvalid");
        }
    };

  
    const close = async () => {
		if (props.overlayStates){
			props.overlayStates.hide();
        }
        else{
          return Redirect("/login");
        }
    }
    
    var emailVal = Validation.Form.Email;

    return (
      <OverlayContainer className=""  abort={close}>
          { !successfullySent && <Fragment>
            <h1>Forgot your password?</h1>
            
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label className={((errors.email)  ? " is-invalid" : "" )}>Enter your email</label>
                <input name="email" type="email" className={"form-control" +  ((errors.email)  ? " is-invalid" : "" )}  ref={register(emailVal)} />
                {errors.email  && <div className="invalid-feedback">{props.t('emailInvalid')} </div> }
              </div>

              <button  className="btn btn-ts mb-2" type="submit">Submit</button>
            </form>

        </Fragment> }

        { successfullySent && <Fragment>
            <h1>Forgot password confirmation</h1>
            
            <p className="text-center"> Please check your email to reset your password.</p>

        </Fragment> }
      </OverlayContainer>);
  
 
  
}
