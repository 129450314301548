

import {parse } from "query-string";
import Utilities from './Utilities';
import { Paths } from './Constants';
import Cookies from 'js-cookie';

class Request{     

    RefreshUrl = "api/User/RefreshAuth";

    ParseQueryString (queryString) {
        return parse(queryString);
    }

    async Get(path, body)
    {
        return await this.Fetch('GET', path, body, 'application/json');
    }

    async Delete(path, body)
    {
        return await this.Fetch('DELETE', path, body, 'application/json');
    }

    async Post(path, body)
    {
        return await this.Fetch('POST', path, body, 'application/json');
    }

    async Put(path, body){
        return await this.Fetch('PUT', path, body, 'application/json');
    }

    async PostForm(path, body){
        return await this.Fetch('POST', path, body);
    }

    
    async Fetch(method, path, body, contentType, trySilentAuth = true){

        
        
        var token = await this.GetAccessToken(); 
 
        var headers = new Headers();
        
        if (token)
            headers.append('Authorization', `Bearer ${token}`);
          
        if (contentType != null)
            headers.append('Content-Type', contentType);

        var finalUrl = path;
        var finalBody;

        if (method != 'GET'){
            if (contentType === 'application/json'){
                finalBody = JSON.stringify(body);
            }
            else{
                finalBody = body;
            }
            
        }
        else if (body != null)
            finalUrl += '?' + this.getQueryString(body);

        
        try{
            var response = await fetch(finalUrl, {
                headers: headers,
                method: method,
                body : finalBody
            }); 
        }
        catch(ex){
            console.log(ex);

            return {statusCode : 503, //service not available                   
                    ok : false,
                    url : path}
        }
 
        console.log(method, finalUrl, body);
        

        // Utilities.showAlert({ message : 'error, server returnded:' + response.status, type : 'success' });
        // Utilities.showAlert({ message : 'error, server returnded:' + response.status, type : 'danger' });

        var data = { 
            statusCode : response.status,
            ok : response.ok,
            url : path
         };

        if (response.ok)
        {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) 
            {
                data.data = await response.json();
                console.log(response.status, data.data);
            }
            else
            {
                data.data = await response.text();
                console.log(response.status, data.data);
            }
        }
        else
        {
            if (response.status == 401 && trySilentAuth && this.TokensAvailable()  ){
                
                var refreshTokenResponse = await this.Fetch("Post", this.RefreshUrl, { RefreshToken : this.GetRefreshToken(), AccessToken : this.GetAccessToken() }, 'application/json', false);
       
                if (refreshTokenResponse.ok){
                    this.StoreAccessToken(refreshTokenResponse.data.accessToken);
                    this.StoreRefreshToken(refreshTokenResponse.data.refreshToken);
                    return await this.Fetch(method, path, body, contentType, false);
                }
                else
                {
                    return data;
                }
            }
            else{
              //  Utilities.showError('error, server returnded:' + response.status );
               
    
                if (response.headers){
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) 
                    {
                        data.data = await response.json();
                        console.log(response.status, data.data);
                    }
                    else
                    {
                        data.data = await response.text();
                        console.log(response.status, data.data);
                    }
                }
                else
                {
                    data.data = null;
                }                

            }

        }
        return data;

    
    }

    TokensAvailable(){
        if (this.GetRefreshToken() !== undefined && this.GetAccessToken() !== undefined){
            return true;
        }
        return false;
    }

    RemoveTokens(){
        Cookies.remove("AuthBearerToken");
        Cookies.remove("RefreshToken");
    }

    StoreAccessToken(cookieValue){
        Cookies.set("AuthBearerToken", cookieValue);
    }
    StoreRefreshToken(token){
        Cookies.set("RefreshToken", token);
    }
    GetRefreshToken(){
        var sessionCookie = Cookies.get("RefreshToken");
		return sessionCookie;
    }

    GetAccessToken(){
        var sessionCookie = Cookies.get("AuthBearerToken");
		return sessionCookie;
     //   var usrMngrToken = await authService.getAccessToken();;
      //  console.log("usrMngrToken", usrMngrToken);

     //   
        //console.log('cookie: ', sessionCookie );

     //   return usrMngrToken; //
        //return 
    }



    constructor(){
        this.Paths = Paths;
    }
  
    GetPaths = () => {
        return this.Paths;
    }

    getQueryString(params) {
        var esc = encodeURIComponent;
        return Object.keys(params)
          .map(k => esc(k) + '=' + esc(params[k]))
          .join('&');
    }
 }

 

 export default new Request();

