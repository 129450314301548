import React, { Component, useEffect } from 'react';
import { Intro } from './public/Intro'
import { Intro2 } from './public/Intro2'
import { Plans } from './public/Plans'
import { Tutorial } from './public/Tutorial'
import { Samples } from './public/Samples'

import {useVerticalScrollParallax} from './common/VerticalScrollParallax';
import ReactGA from 'react-ga';

export function Home(props) {

  	const p1 = useVerticalScrollParallax  ();
   
	const p2 = useVerticalScrollParallax  ();
	  
	const p3 = useVerticalScrollParallax  ();


	useEffect(() => {
		ReactGA.initialize('UA-165567255-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);



	return (
		<div className="Home">
			<Intro {...props} ></Intro>
			<Intro2 {...props}  ref={p1.ref}></Intro2>
			<Samples {...props} ref={p2.ref}></Samples>
			<Plans {...props}   ref={p3.ref}></Plans>
			<Tutorial {...props} onHome ></Tutorial>
			
		</div>
	);
  
}
