import React, { Component, Fragment, useState } from 'react';
import './public.css';
import Request from '../common/Request';
import Utilities from '../common/Utilities';
import { Validation } from '../common/Constants';
import { Redirect } from 'react-router-dom';
import OverlayContainer from '../common/OverlayContainer';
import { useForm } from "react-hook-form";

export function Login (props){
  var auth = props.auth;
	const { handleSubmit, register, getValues, errors, setError, clearError, formState, triggerValidation } = useForm();
  const login = async (username, password) => {
    clearError("credentialsInvalid")
	  var result = await Request.Post("api/user/login", { Email: username, Password: password, RememberMe :  true  });
    if (result.ok){
      Request.StoreAccessToken(result.data.accessToken);
      Request.StoreRefreshToken(result.data.refreshToken);
      auth.populateState();
      props.overlayStates.hide();
      props.history.push("/dashboard");
    }
    else{
      setError("credentialsInvalid");
    }
  }

  const onSubmit = async (values) => {
	  login(values.email, values.password);
  };
  
  const close = async () => {
    if (props.overlayStates){
      props.overlayStates.hide();
    }
    else{
      return Redirect("/dashboard");
    }
  }
  
  var emailVal = Validation.Form.Email;
  var passwordVal = Validation.Form.Password;

  return (
    <OverlayContainer className="upgrade withSkip "  abort={close}>
      <Fragment>


          <h1>Log in</h1>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label className={((errors.email)  ? " is-invalid" : "" )}>Email</label>
              <input name="email" className={"form-control" +  ((errors.email)  ? " is-invalid" : "" )}  ref={register(emailVal)} />
              {errors.email  && <div className="invalid-feedback">{props.t('emailInvalid')} </div> }
            </div>

            <div className="form-group mb-0">
              <label className={((errors.password || errors.credentialsInvalid)  ? " is-invalid" : "" )}>Password</label>
              <input name="password" className={"form-control" +  ((errors.password)  ? " is-invalid" : "" )}  type="password" ref={register(passwordVal)} />
              {errors.password  && <div className="invalid-feedback"> {props.t('passwordInvalid')} </div> }
              {errors.credentialsInvalid  && <div className="invalid-feedback"> {props.t('credentialsInvalid')} </div> }
            </div>

            <div className="form-group btn_margin">
              <p>
                <a className="btn btn-link pl-0 gray r14 underline" id="forgot-password" onClick={ () => props.overlayStates.showOverlay(props.overlayStates.forgotPasswordOverlayState)}>Forgot your password?</a>
              </p>
            </div>

            <button  className="btn btn-ts mb-2" type="submit">Sign in</button>

            <a className="btn btn-link pl-0 pr-0 gray w-100 text-center underline"  onClick={ () => props.overlayStates.showOverlay(props.overlayStates.registerOverlayState) }>Sign up</a>

  
          </form>

      </Fragment>

    </OverlayContainer>);
  
 
  
}
