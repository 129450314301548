import React, { Component, Fragment } from 'react';
import Request from '../common/Request';
import Utilities from '../common/Utilities';



import { Redirect } from 'react-router';

import  {PlansData, FreePlanId } from '../common/Constants';

import  User  from '../common/User';

import  Interweave  from 'interweave';

import './ChangePlan.css'

import { StrypeWrapper, PaymentStatus } from './PaymentMethod';
import { Translation } from 'react-i18next';

import { HashLink as HashLink } from 'react-router-hash-link';


import SignalRClient from '../common/SignalRClient';
import Loading from '../common/Loading';

import  OverlayContainer from '../common/OverlayContainer';

// import {CardNumberElement, Elements, StripeProvider} from 'react-stripe-elements';

class Plan extends Component {
    constructor(props) {
        super(props);

        this.selectPlan = this.selectPlan.bind(this);
        this.state = { 
            navigate : false ,
            paymentStatusShown : false,
            showItsFineOverlay : false
        };

        this.ref = React.createRef();
        this.renderPaymentButton = this.renderPaymentButton.bind(this);
        this.closeItsFineOverlay = this.closeItsFineOverlay.bind(this);
    }
    async componentDidMount() {
     
    }

    selectPlan (){
        if (this.buttonAction){
            this.buttonAction();
        }
    }


    renderPayment(){

        var plan = this.props.plan;

        
    }


    renderInfo(){
        return <div>
            <div> Enter new payment method:</div>

            <div> Demo card numbers:</div>
            <div> 4242424242424242	Succeeds and immediately creates an active subscription.</div>
            <div> 4000002500003155	Requires authentication. confirmCardPayment will trigger a modal asking for the customer to authenticate. Once the user confirms, the subscription will become active.</div>
            <div> 4000008260003178	Always fails with a decline code of insufficient_funds.</div>
        </div>
    }

    renderPaymentButton(){
        var buttonName = "Purchase";
        var paymentStatus = this.props.paymentStatus;
        var plan = this.props.plan;

        var isYourPlan = paymentStatus != null && paymentStatus.currentPlan == plan.id;

        var isDowngrade = paymentStatus != null && plan.links < paymentStatus.totalAvailableLinks ;//paymentStatus.currentPlan == plan.id;

         

        if (plan.isEnterprise)
        {
            buttonName = "Contact us";
            this.buttonAction = () => {}; //nothing todo here

            return <a href="mailto:support@web-ar.space"><div className="row actionRow">
                    <div className= { "action noSelect"  + ( isYourPlan ? " current" : "")  }  >
                        {buttonName}
                    </div>
                </div></a>

        }
        else if(!this.props.isDetailPage){

            if (isYourPlan)
                buttonName = "Current Plan";
            else if (isDowngrade)
                buttonName = "Downgrade";
            else
                buttonName = "Purchase";

            if (!isYourPlan){
                return <div className="row actionRow">
                    <HashLink className= { "action noSelect"  + ( isYourPlan ? " current" : "") } to={"/ChangePlan?id=" + plan.id + "&" + (this.props.perYear === "true"  ? "perYear=true" : "perYear=false") + "#" + plan.id  }  >
                        {buttonName}
                    </HashLink>
                </div>

                // this.buttonAction = () => {
                //     this.setState({ navigate : true });
                //     this.props.onSelect(this.props.plan);
                // };
            }
            else{
                this.buttonAction = () => { };
            }
        }
        
        else if (paymentStatus != null)
        {
            if (paymentStatus.orderedPlan == null && plan.id == FreePlanId){
                this.buttonAction = () => {
                    this.setState( {  showItsFineOverlay : true});
                };
               
            }
            else if (paymentStatus.currentPlan == plan.id)
            {
                buttonName = "Current Plan";
                this.buttonAction = () => {

                }; //nothing todo here
            }
      
            else if ( paymentStatus.card == null || paymentStatus.lastInvoiceStatus == "requires_payment_method" ) //|| paymentStatus.lastInvoiceStatus == "succeeded"
            {
                buttonName = "Pay";
                this.buttonAction = () => this.ref.current.startPaymentSession(true); //payment and purchase plan
            }
            else if (paymentStatus.lastInvoiceStatus == "requires_action")
            {
				 buttonName = "Authorize Payment"; /* 3D Secure */
                this.buttonAction = () => this.ref.current.additionalAction();
            }
            else
            {
                this.buttonAction = () =>  this.props.switchToPlan(plan.id);
            }
        }
        else
        {
            buttonName = "loading...";
        }

        return <div className="row actionRow">
                    <div className= { "action noSelect"  + ( isYourPlan ? " current" : "")  } onClick={this.selectPlan}  >
                        {buttonName}
                    </div>
                </div>
        
    }
    
    closeItsFineOverlay () {
        this.setState( { showItsFineOverlay : false});
    }

    render() {

        var plan = this.props.plan;

         var showOverlay = this.state.showItsFineOverlay;

        return (
            <Fragment>

                { showOverlay && <OverlayContainer className="qrOverlay" abort={ this.closeItsFineOverlay} > 
                    <h1>No worries!</h1>

                    <div className="text">You have a free 5 Links plan for 12 months. Upgrade your account to publish more than 5 Ar-Link. <br/> 
                    <HashLink to="/ChangePlan" className="btn btn-link underline p-0 abort " style={{"textTransform" : "none"}}>See our plans.</HashLink>
                    </div>
                    <HashLink to="/ChangePlan?perYear=false#20_links_pm" className="btn btn-ts abort" >Upgrade</HashLink> 
                    <button onClick={ this.closeItsFineOverlay } className="btn btn-link pl-0 pr-0 gray w-100 text-center abort skip" >Skip</button>

                </OverlayContainer>	
                }

                <div id={plan.id} className="change_plan_entry_container"> 
                    <div className="change_plan_entry mikesFunnyBorder">
                        <div>

                            <div className="row header twoCol">
                                <h2> {plan.title} </h2>
                                <h3 className="l30">  { plan.id != 'free' && !plan.isEnterprise ? '€ ' + plan.price : '' }  {plan.subtitle} </h3>
                            </div>

                            <div className="separator"></div>
                            
                            <div className="row content twoCol">
                                <div>Includes</div>
            
                                <div><Interweave content={plan.p1} /></div>
                            </div>

                            <div className="separator"></div>

                            <div className="row content twoCol">
                                <div>Billing</div>
            
                                <div><Interweave content={plan.p2} /></div>
                            </div>

                            <div className="separator mb-5"></div>  

                            {this.props.isDetailPage && this.props.paymentStatus && <Fragment>
                                <div className={"row oneCol"}><h4>{this.props.t("Payment")}</h4></div>
                                <div className="separator"></div>
                                <StrypeWrapper stripeApiKey = {this.props.paymentStatus.stripeApiKey}> 
                                    <PaymentStatus paymentStatus={this.props.paymentStatus} ref={this.ref} t={this.props.t} clientReferenceId={ plan.id } metadata={ { planId : plan.id }} >

            
                                    </PaymentStatus>
                                </StrypeWrapper> 
                                <div className="separator mb-5"></div> 
                            </Fragment>  }
                                
                            
                        
                            

                            {this.renderPaymentButton()}
                        </div>
        
                    </div>
                </div>
            </Fragment>
        );
    }
}


export class ChangePlan extends Component {



    constructor(props) {
        super(props);

   

        this.finish = this.finish.bind(this);



     //   console.log("param", planParam);
        this.state =  { 
            plans : PlansData,
            serverPlans : [],
            isBusy : false,
            selectedPlanIdNavigate : null
        };
        this.selectPlanButtonClicked = this.selectPlanButtonClicked.bind(this);
        this.cancelSubscriptions = this.cancelSubscriptions.bind(this);

        this.switchToPlan = this.switchToPlan.bind(this);
       


    }

    finish(result){

        
        console.log(result, this.props.stripe);
    }


    async componentDidMount() {
        
     
        var result = await Request.Get(Request.GetPaths().Plans)

        this.setState( { serverPlans : result.data } );
       

        var paymentStatusResult = await User.GetPaymentStatus(); 
        this.setState( { paymentStatus : paymentStatusResult });

        await SignalRClient.EnsureConnectionIsOpen();
        SignalRClient.onSubscriptionUpdated( async (subscriptionData) => {
            await User.SetPaymentStatus(subscriptionData);
            this.setState( { paymentStatus : subscriptionData });

        });

        //  var planParam =  Request.ParseQueryString(this.props.location.search).id;
        //  console.log("param", planParam);

        // console.log(result.data);

    }
 
    async selectPlanButtonClicked(ev){
        ev.preventDefault();

        var planId = ev.target.id;
        console.log(planId);

        
        this.switchToPlan(planId);

    }

    async switchToPlan (planId){

        this.setState({ isBusy : true });

        var result = await Request.Post( Request.GetPaths().SubscribeTo, planId);

        if (result.ok){
            
            await User.SetPaymentStatus(result.data);
            console.log("payment status ",  result.data );
            this.setState( { paymentStatus : result.data, redirectToOrderStatus : true });
        }

        Utilities.showResponse(result, this.props.t);

        this.setState({ isBusy : false });
    }

        
    async cancelSubscriptions(ev){
        ev.preventDefault();

        console.log(ev.target.id);

        var result = await Request.Post( Request.GetPaths().CancelSubscriptions, ev.target.id);

        console.log(result);
        Utilities.showResponse(result, this.props.t);

    }      

    /* <StripeProvider apiKey="sk_test_MaHJ4BV5cIgdIbnjRB7a3VEO00RjKzlKeA" >
        <Elements>
            <CardNumberElement />
        </Elements>
   

    </StripeScriptLoader>    */



    render () {

        if ( this.state.selectedPlanIdNavigate ){
            this.setState( {selectedPlanIdNavigate : null});
            return <Redirect push  to={{
                pathname: "/ChangePlan",
                search: "?id=" + this.state.selectedPlanId ,
                state: { selectedPlanId : this.state.selectedPlanId }}}>
            </Redirect>
        }


        if (this.state.redirectToOrderStatus){
            return <Redirect push  to={{
                pathname: "/OrderStatus",
                state: { paymentStatus : this.state.paymentStatus }}}></Redirect>
        }

        var selectedPlanId =  Request.ParseQueryString(this.props.location.search).id; // 
        
    
        var perYearStr = Request.ParseQueryString(this.props.location.search).perYear;
        
        var perYear = true;
        if (perYearStr === undefined || perYearStr === "true" )
            perYear = true;
        else
            perYear = false;

        var plans =  PlansData.filter( plan => plan.id == selectedPlanId ||  (selectedPlanId == undefined &&  ( (perYear && plan.perYear) || (!perYear && plan.perMonth))  ));
        return  (<div className= { "changePlan container pageContainer " +  ((selectedPlanId ) ? " singlePlanSelected" : "")  } >
               
                { this.state.isBusy && <Loading fullScreen></Loading>}

               
                { plans.length > 1 &&
                       <div className="plan_header_container">
                        <div className="container">
                            <div className="plan_header" >
                       

                                
                                <div className="year_filter_bar_container noSelect">
                                    <div className="year_filter_bar">
                                        <div className={"selector " + ((!perYear) ? 'alt' : '')}> </div> 

                                        <div className="textcontainer"> 
                                            <HashLink className= {"txt subscribe " +  (perYear ? 'active' : '')} to={ "/ChangePlan?perYear=true" }  >
                                                Anually • save 20% 
                                            </HashLink>
                                            <HashLink className={"txt subscribe "+  (perYear ? '' : 'active')}  to={ "/ChangePlan?perYear=false" } >
                                                Monthly
                                            </HashLink>
                                        </div>
                                    </div>
                                </div>

 
                       
                    
                                </div>
                            </div>
                       </div> }
         
                     
                        <div className="plan_container" >
                            {
                                plans.map((val, index) => 
                                    <Plan  t={this.props.t} key={val.id} plan={val } switchToPlan={this.switchToPlan}  paymentStatus={this.state.paymentStatus} perYear={perYear} isDetailPage={plans.length == 1 }></Plan>
                                )
                            }
                        </div>

                        {/* {  this.state.serverPlans.map( (val, index) => 
                                <input onClick={this.selectPlanButtonClicked} type="button" id={val.id} key={val.id} value={val.nickname} /> 
                            ) 
                        } */}

                        {/* <input onClick={this.cancelSubscriptions} type="button" value="Cancel" />  */}
         
             </div>);
    }


    // onSubmit={this.submitClicked}
}

