import React, { Component } from 'react';

import  AssetLink  from './AssetLink';
import  UploadAsset  from './UploadAsset';
import Request from '../common/Request';
import { Redirect } from 'react-router';
import User from '../common/User';

import './AssetLinks.css';



export default class AssetLinks extends Component {

    async componentDidMount() 
    {

        await this.fetchAssetLinks();


    }

    async fetchAssetLinks(){

        var result = (await Request.Get(Request.Paths.AssetLinks)).data;

        if (result != null)
        {
            this.setState({ assetLinks : result });
        }
            
    }



	constructor(props) {
		super(props);

		this.state = {
            assetLinks : null,
            addingNewAssetLink : false,
            redirectToDetailsView : 0,
            currentDirectory : '',
            
		};

        this.addNewAssetLink = this.addNewAssetLink.bind(this);

        this.uploadSucceeded = this.uploadSucceeded.bind(this);

        this.actionFinished = this.actionFinished.bind(this);
    }

    async actionFinished (args){
        console.log(args);
        if (args.actionType == 'delete'){
            console.log(args.item.assetLinkId);

            var filteredArray = this.state.assetLinks.filter(item => item.assetLinkId !== args.item.assetLinkId)
            this.setState({assetLinks: filteredArray});
        }
        else if (args.actionType == 'duplicate'){
            await this.fetchAssetLinks();
        }
      
    }

    async addNewAssetLink(ev) {
   
        this.setState({ addingNewAssetLink : !this.state.addingNewAssetLink });
    }

    async uploadSucceeded(ev) {
       
        
        console.log(ev);
        if (ev.success){
            this.setState({addingNewAssetLink : false,  redirectToDetailsView : ev.id});
        }
        else{
            this.setState({ addingNewAssetLink : false });
            await this.fetchAssetLinks();
        }
        
    }
    render() {
    
        
        if (this.state.redirectToDetailsView > 0){
            return  <Redirect push to={'/asset/AssetLinkDetails?id=' + this.state.redirectToDetailsView } />
        }

        return ( 
            <div className="container pageContainer">
                {
                    this.state.addingNewAssetLink &&
                    <UploadAsset {...this.props} onFinished={this.uploadSucceeded} closeOnBgClick={false} assetLinks={this.state.assetLinks}> </UploadAsset>
                }
            <div className="assetlinksContainer ">
              
     

                <div className="row addAssetLink" >
                    <div></div>
                    <button onClick={this.addNewAssetLink}></button>
                    <div onClick={this.addNewAssetLink}><span>{this.props.t("addNewAssetLink")}</span></div>
                    <div></div>
                </div>

                <div className="row assetlinks">
                    {
                        this.state.assetLinks != null && 
                        this.state.assetLinks.map( (item) => 
                                    
                            <div key={item.assetLinkId}>

                                <AssetLink t={this.props.t} onActionFinished={this.actionFinished} assetLink={item}  ></AssetLink>
                            </div>
                        )
                    }

                    <div className="flex-placeholder"> </div>
                    <div className="flex-placeholder"> </div>
                    <div className="flex-placeholder"> </div>
                    <div className="flex-placeholder"> </div>
                    <div className="flex-placeholder"> </div>
                </div>

                {
                    // var grpd = [] 
                    // if(this.state.assetLinks != null){
                    //     grpd = this.state.assetLinks.reduce((rows, link, index) => (index % 3 == 0 ? rows.push([link]) : rows[rows.length-1].push(link)) && rows, []);
                    // }
                        
                    //     console.log(grpd);
                    // grpd.map((grp, index) => 
                    //     <div className="row" key={index}>
                    //         {
                    //             grp.map( (item) => 
                           
                    //                 <div key={item.assetLinkId} className="col al">
                      
                    //                     <AssetLink  assetLink={item} ></AssetLink>
                    //                 </div>
                    //             )

                    //         }
              
                    //     </div> 
                    // )
                }
       
                </div></div>
          );
	}   
}