
import update from 'immutability-helper';
import { HubConnectionBuilder  } from '@microsoft/signalr';
import Utilities from '../common/Utilities';
import Request from '../common/Request';

class SignalRClient{
 
    constructor(){
        this.isInit = false;
    }

    async EnsureConnectionIsOpen() 
    {
        if (!this.isInit ||  this.hubConnection.state == 'Disconnected')
        {
            console.log("signalR - initializing");
            var token = await Request.GetAccessToken();



//             None = 0	
// Specifies no transport preference.

// WebSockets = 1	
// Specifies the WebSockets transport.

// ServerSentEvents = 2	
// Specifies the Server-Sent Events transport.

// LongPolling = 4	
// Specifies the Long Polling transport.
            this.hubConnection = new HubConnectionBuilder().withUrl("/assetHub", {
                accessTokenFactory: () => token,
                transport : 4
              }).withAutomaticReconnect().build();
    
              //https://docs.microsoft.com/en-us/javascript/api/%40microsoft/signalr/hubconnection?view=signalr-js-latest

            this.hubConnection.on("assetTransformationUpdated", data => {

                console.log('signalR assetTransformationUpdated', data);
                if (this.onAssetTransformationUpdatedEv)
                    this.onAssetTransformationUpdatedEv(data);
            });

            this.hubConnection.on("subscriptionUpdated", data => {

                console.log('signalR subscriptionUpdated', data);
                if (this.onSubscriptionUpdatedEv)
                    this.onSubscriptionUpdatedEv(data);
            });


            

            this.hubConnection.onclose( (error) => {
                //Utilities.showError('Hub connection closed!');
                console.error("SignalR - hubConnection closed!");
            });

            this.hubConnection.start()
                .then(() => {
                    console.log('Connection started!');
                    
                })
                .catch(err => {
                    console.error("SignalR - hubConnection.start() failed!", err); //Utilities.showError('Hub connection error, please refresh your page!');
                });


    

            this.isInit = true;
        }
        else{
            console.log("signalR ready");
        }
        return this;
    }

    
    onAssetTransformationUpdated(callback){
        this.onAssetTransformationUpdatedEv = callback;
    }

    onSubscriptionUpdated (callback){
        this.onSubscriptionUpdatedEv = callback;
    }
}

export default new SignalRClient();

   