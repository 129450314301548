import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import Utilities from './Utilities';

export default class AlertContainer extends Component {


	constructor(props) {
		super(props);
  
		this.state = {
            alerts : [],
            cnt : 0
		};
    
        this.timer = this.timer.bind(this);
        this.AlertCreated = this.AlertCreated.bind(this);
        Utilities.subscribeForAlerts(this.AlertCreated);
    }
    componentDidMount () 
    {
        this.countdown = setInterval(this.timer, 100);

   
    }
    timer(){
        this.setState( { cnt : this.state.cnt + 1 });
        var filteredAlerts = this.state.alerts.filter( alert => ( this.state.cnt - alert.cnt < 100 && !alert.deleted ) );
        this.setState( {alerts : filteredAlerts });
    }
    AlertCreated(alert){

        alert.cnt = this.state.cnt;
        alert.deleted = false;
        let alerts = [...this.state.alerts];

        alert.toggle = () => {  
            alert.deleted = true; 
        };

        alerts.push(alert);
        this.setState( { 
            alerts : alerts,
            test : 'test'
        } );
    }
    

    render() {
        return <div className="alertContainer">

            {
                this.state.alerts.map((alert, index) => {
                
                    return <div className="alertEntryContainer" key={index} onClick={() => alert.toggle()}> 
                        <div className="alertEntry">

                            {/* <Alert  isOpen={ this.state.cnt - alert.cnt < 350} toggle={}  >
                               
                            </Alert> */}


                        <div className= {"alert alert-"+ alert.type +" alert-dismissible fade show"} role="alert">
                            <button type="button" className="close" aria-label="Close">
                                    <svg width="10px" height="10px" viewBox="0 0 9 9" version="1.1" >
                            
                                        <title>Path</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="—-DESKTOP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g className="buttonX" transform="translate(-970.000000, -96.000000)" fill="#FFFFFF">
                                                <g id="Group" transform="translate(432.000000, 80.000000)">
                                                    <g id="Group-5" transform="translate(19.000000, 0.000000)">
                                                        <polygon id="Path" points="523.5 19.0655022 526.55625 16 528 17.4344978 524.94375 20.5 528 23.5655022 526.55625 25 523.5 21.9344978 520.44375 25 519 23.5655022 522.05625 20.5 519 17.4344978 520.44375 16"></polygon>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    </button>
                                 { alert.message } </div>
                        </div>
                    </div> })
                

            }

{/* <div class="alertEntryContainer"><div class="alertEntry"><div class="alertEntryContainer"> <div class="alertEntry"><div class="alert alert-success alert-dismissible fade show" role="alert"><button type="button" class="close" aria-label="Close"><svg width="9px" height="9px" viewBox="0 0 9 9" version="1.1"><title>Path</title><desc>Created with Sketch.</desc><g id="—-DESKTOP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Artboard_12" transform="translate(-970.000000, -96.000000)" fill="#FFFFFF"><g id="Group" transform="translate(432.000000, 80.000000)"><g id="Group-5" transform="translate(19.000000, 0.000000)"><polygon id="Path" points="523.5 19.0655022 526.55625 16 528 17.4344978 524.94375 20.5 528 23.5655022 526.55625 25 523.5 21.9344978 520.44375 25 519 23.5655022 522.05625 20.5 519 17.4344978 520.44375 16"></polygon></g></g></g></g></svg>   </button>ChangesSavedSuccessfully </div></div></div></div></div>
             
        <div class="alertEntryContainer"> <div class="alertEntry"><div class="alert alert-danger alert-dismissible fade show" role="alert"><button type="button" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>this is a very long error asdf </div></div></div>  */}
           { /* <div class="alertEntryContainer"> <div class="alertEntry"><div class="alert alert-danger alert-dismissible fade show" role="alert"><button type="button" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>this is a very long error messaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaage</div></div></div>

            <div class="alertEntryContainer"> <div class="alertEntry"><div class="alert alert-success alert-dismissible fade show" role="alert"><button type="button" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eu purus nec lorem placerat tincidunt. Etiam mauris lacus, viverra id massa sit amet, vulputate feugiat sem. Quisque congue fringilla urna eu interdum. Nulla tempor posuere suscipit. Integer sed lorem neque. Cras id felis et libero congue vestibulum sit amet tristique neque. Etiam et faucibus elit. Mauris lectus elit, efficitur eget rutrum eu, convallis non massa. Ut condimentum vehicula metus quis consectetur. Vivamus egestas risus nisi. Nulla facilisi. Etiam vitae lectus hendrerit, suscipit leo in, ultrices magna. Praesent rhoncus metus luctus semper posuere.</div></div></div>

            <div class="alertEntryContainer"> <div class="alertEntry"><div class="alert alert-success alert-dismissible fade show" role="alert"><button type="button" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>Lorem ipsum dolor sit amet, ultrices magna. Praesent rhoncus metus luctus semper posuere.</div></div></div> */}


         </div>
    }   
    
}