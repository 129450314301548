


import React, { Fragment } from 'react';
// import './TermsAndPrivacy.css';
import './TermsAndPrivacyOverride.css';

export function TermsAndPrivacy(props){

    return <div id="Terms" className="pageContainer container">

        <div class="c16">
            <p class="c2"><span class="c21">Terms &amp; Privacy</span></p>
            <p class="c15 title" id="h.ma6m3l8cwd4t"><span class="c17">I. Web-Ar.space Terms of Use</span></p>
            <p class="c15 title" id="h.fofn19rklq4k"><span class="c1">Last update: March 31, 2020</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">THESE TERMS OF USE (&ldquo;TOU&rdquo;), INCLUDING ALL DIRECTIVES, OR LEGAL NOTICES THAT WE CAN RELEASE FROM TIME TO TIME, REGULATE YOUR USE OF WEB-AR.SPACE, BOTH FOR THE FREE, OR THE PAID SERVICES OF WEB-. ALSO YOUR RELATIONSHIP WITH WEB-AR.SPACE. PLEASE READ THIS TOU CAREFULLY, AS IT WILL INFLUENCE YOUR LEGAL RIGHTS AND LIABILITIES. IF YOU DO NOT AGREE TO THIS TOU, PLEASE DO NOT REGISTER AT WEB-AR.SPACE. IF YOU HAVE QUESTIONS ABOUT THE TERMS OF USE, PLEASE CONTACT SUPPORT@WEB-AR.SPACE.</span></p>
            <h1 class="c6" id="h.50a7ys7b8947"><span class="c0">1. NATURE OF SERVICES </span></h1>
            <p class="c2"><span class="c1">Web-Ar.space is a web augmented reality (Web-AR) platform. This Web-AR platform offers a number of services and functions for publishing (web) augmented reality experiences via a web browser. Contents (e.g. 3D models, sound, animations, pictures) can be uploaded and modified by different functions. This content is published in the form of an augmented reality link.</span></p>
            <h1 class="c6" id="h.5qzn3578r7j8"><span class="c0">2. WHAT DOES WHAT MEAN?</span></h1>
            <p class="c2"><span class="c1">In these terms of use, some of the terms used have a special meaning. </span></p>
            <p class="c2"><span class="c1">These terms are listed here:</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Account owner&quot;</span></p>
            <p class="c2"><span class="c1">means the person, company or organization in whose name an account is registered with Web-Ar.space. If an account is created with a business or organizational email address (e.g., 123@company.com), the account holder is the company or organization. If it is not clear who the intended account holder is, the final decision, including the information provided, will be made by Web-Ar.space at its sole discretion.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Authorized User&quot;</span></p>
            <p class="c2"><span class="c1">referred to in relation to commercial subscriptions, contractors, or other business related persons of the account holder (also referred to as &quot;authorized users&quot;).</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&ldquo;Subscriptions for commercial purposes&rdquo;</span></p>
            <p class="c2"><span class="c1">means each purchased subscription that is used for commercial purposes.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Commercial users&quot;</span></p>
            <p class="c2"><span class="c1">means a person who uses a subscription for commercial purposes.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;AR trigger&quot;</span></p>
            <p class="c2"><span class="c1">AR triggers are Web AR links or QR codes, both of which are linked to the desired content and triggers the desired content.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Permitted Purposes&quot;</span></p>
            <p class="c2"><span class="c1">means (i) for professional users, any lawful commercial, commercial or professional use within the guidelines published by us; (ii) for private users, only for your personal entertainment / purposes and not for commercial, commercial or professional purposes.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Private users&quot;</span></p>
            <p class="c2"><span class="c1">&nbsp;means a person who uses the services of Web-Ar.space for personal purposes.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Services, Web-Ar.space services, Web-Ar.space services&quot;</span></p>
            <p class="c2"><span class="c1">are the services and functions of the WebAR platform, which are available at https://web-ar.space/, together with the associated databases, features, functions, plug-ins, software, tools, documentation and websites, including any changes or updates.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Upload function&quot;</span></p>
            <p class="c2"><span class="c1">describes the function of Web-Ar.space, which allows content to be loaded into Web-Ar.space in order to be able to edit it and subsequently publish it.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;User&quot;</span></p>
            <p class="c2"><span class="c1">means the account holder or a person who access the services of Web-Ar.space, regardless of whether they are account holders or not.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;User Agreement&quot;</span></p>
            <p class="c2"><span class="c1">means the legal contract between Web-Ar.space and the account holder for the provision of the services, which contains these terms of use and is subject to them.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Content, User Content&quot;</span></p>
            <p class="c2"><span class="c1">means all 3D models, animations, images or audio files uploaded, imported, or transmitted by users.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Virus&quot;</span></p>
            <p class="c2"><span class="c1">means any thing or device (including software, code, files or programs) that: (i) prevents, interferes with, or otherwise adversely affects the operation of devices, computer software, hardware or networks, telecommunications services; (ii) prevent, interfere with, or otherwise interfere with access to or operation of programs or data, including the reliability of programs or data (whether by completely or partially relocating, changing or deleting programs or data) ; or (iii) that adversely affects the user experience, including worms, Trojans, viruses and other similar things or devices.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;You&quot;</span></p>
            <p class="c2"><span class="c1">means that you, the natural person who visits the Web-Ar.space website, uses the Web-Ar.space services or registers as a user of the Web-Ar.space services.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Web AR Links&quot;</span></p>
            <p class="c2"><span class="c1">means the web links that are linked to user content by the services of Web-Ar-space and serve to publish and distribute user content. Web AR links are created in the Web-Ar.space account by users.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&ldquo;Web AR-QR Codes&rdquo;</span></p>
            <p class="c2"><span class="c1">means the QR codes that are linked to Web AR links including their user content by the services of Web-Ar-space and are used to view up Web AR links using a smartphone camera or other QR code readers in order to publish and distribute user content. Web AR-QR codes are created in the Web-Ar.space account by users.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Web-Ar.space&quot; or &quot;We&quot;</span></p>
            <p class="c2"><span class="c1">means twospot GmbH, a limited liability company registered in Austria, which is registered under the commercial register number FN 479858 z.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Web-Ar.space account, user account, account&quot;</span></p>
            <p class="c2"><span class="c1">means the Web-Ar.space account that has been registered for the use of the Web-Ar.space services with the necessary login information.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">&quot;Web-Ar.space website&quot;</span></p>
            <p class="c2"><span class="c1">&nbsp;refers to our website for the WebAR platform, which can be found at https://web-ar.space/</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">In these TOU:</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">The words &quot;contains&quot; and &quot;including&quot; are in no way limited and mean &quot;contains or including without limitation&quot;.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">The word &quot;person&quot; includes individuals, companies, corporations, partnerships, limited partnerships, cooperatives, associations and other natural and legal persons.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">The section and paragraph headings are only for clarity and have no influence on their interpretation. Unless otherwise stated, references refer to a section of this TOU.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">If there is a conflict, ambiguity, or difference in the interpretation, wording or meaning, term, or term in any of our published or shipped policy documents, the term or term in the most current policy document applies.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">These terms of use were originally written in German. To the extent that a translated version of these Terms of Use conflicts with the English version, the English version is controlled.</span></p>
            <h1 class="c6" id="h.mt7jpd4lfy3m"><span class="c0">3. ACCEPTANCE OF THE CONDITIONS</span></h1>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">The use of the Web-Ar.space services is subject to the following terms of use. The creation / registration of a Web-Ar.space account and / or the use of the Web-Ar.space services is considered confirmation and agreement to be legally bound to the provisions of the terms of use. If you do not agree to the terms of use, you may not use the services of Web-Ar.space.</span></p>
            <h1 class="c6" id="h.oduet14aoy3o"><span class="c0">4. CHANGE OF THE TERMS</span></h1>
            <p class="c2"><span class="c1">We may, at our discretion, revise, change or replace these Terms of Use at any time with or without notifying you by updating the publication on the Web-Ar.space website. You should visit the Web-Ar.space website from time to time to check the latest version of the TOU, as these are legally binding for you. If you do not want to accept the new terms of use, you should no longer use the services of Web-Ar.space. If you continue to use the Services after the effective date of changes, your use of the Services is deemed to be legally bound by the new Terms of Use.</span></p>
            <h1 class="c6" id="h.hc628a4hqpl2"><span class="c0">5. REGISTER AN ACCOUNT WITH WEB-AR.SPACE</span></h1>
            <h3 class="c3" id="h.jp19xqsikuma"><span class="c0">5.1 Who can use the services?</span></h3>
            <p class="c2"><span class="c1">You may only use the services if you meet and assure the following criteria and guarantee that: (a) you are currently not partially or wholly excluded from the services of Web-Ar.space; (b) register only one account for private use; (c) comply with these Terms of Use at all times; and (d) agree to provide, at your expense, all the equipment, resources, software and internet access required to use the Services.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">The person who opens a WebAr.space account on behalf of a Commercial User guarantees and warrants that: (a) they have full authority to enter and accept these Terms of Use on behalf of the Commercial User; (b) the commercial user is a legal person and is able to enter into contractual relationships; and (c) the use of the services does not violate other agreements in which the business user is involved.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">If you do not meet or no longer meet all the conditions of participation listed in these terms of use, you may not use the services of Web-Ar.space. Web-Ar.space also reserves the right, at its own discretion, to refuse to accept requests to open a Web-Ar.space account for the Web-Ar.space services.</span></p>
            <h3 class="c3" id="h.t837sto445q9"><span class="c0">5.2 Registration</span></h3>
            <p class="c2"><span class="c1">In order to be able to use the services, every user must register with us by creating a Web-Ar.space account. You have to make sure that the data you provided during registration are correct and complete. You must inform us immediately of any changes to the information you provided during registration by updating your personal information to enable Web-Ar.space to communicate with you. If you provide information that is not true, inaccurate, not up-to-date or incomplete, or if we have reason to believe that this information is incorrect, inaccurate, out-of-date or incomplete, we can block your Web-Ar.space account and / or to use the right to block or terminate the Services, with or without notice.</span></p>
            <h3 class="c3" id="h.42077d891lvw"><span class="c0">5.3 Password and security</span></h3>
            <p class="c2"><span class="c1">The account holder alone is responsible for the activities that take place on his Web-Ar.space account and for the security of his account. The Account Owner therefore undertakes: (i) to keep the chosen password and other login information secure and confidential (to ensure that authorized users keep them safe); (ii) prohibit others from using their web space unless they are authorized users; (iii) be responsible for all activities that take place on his Web-Ar.space account; (iv) not to sell, trade, or otherwise transfer your WebAr.space account to another party unless permitted by the User Agreement; and (v) not bill anyone for access to the Web-Ar.space account in whole or in part.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If you know or suspect that someone else knows your password or has received unauthorized access to your Web-Ar.space account, you should notify us immediately by contacting support@web-ar.space. If Web-Ar.space has reason to believe that there is a possible security breach or misuse of Web-Ar.space services, we can ask you to change your username and / or password, we also reserve the right To block your WebAr.space account. WEB-AR.SPACE MAKES NO LIABILITY FOR LOSSES WHICH A USER MAKES BY USING YOUR WEB-AR.SPACE ACCOUNT, BY ANY OTHER PERSON, WHETHER AUTHORIZED OR UNAUTHORIZED.</span></p>
            <h3 class="c3" id="h.3jkrsdt7442l"><span class="c0">5.4 Inactivity</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space reserves the right to remove and / or delete inactive Web-Ar.space accounts as part of system clean ups. This administration can lead to the inactive Web-Ar.space account being temporarily or permanently removed or deactivated and thereby all content and data associated with it deleted or deactivated. For these purposes, the following is considered inactive: Web-Ar.space accounts that have not been used for 12 months or longer AND have no paid subscription;</span></p>
            <h1 class="c6" id="h.5d0itcg6aird"><span class="c0">6. RIGHT TO USE THE SERVICES</span></h1>
            <h3 class="c3" id="h.c56b8a3m33hc"><span class="c0">6.1 Conditions of access</span></h3>
            <p class="c2"><span class="c1">In compliance with this TOU, including all of our current or future published instructions or guidelines, Web-Ar.space grants the user a personal, worldwide, non-exclusive, non-transferable and limited right, unless the Web-Ar.space account, and until this is not terminated in accordance with Section 15 (&quot;Termination of the User Agreement&quot;), access to the Web-Ar.space account and use of the Web-Ar.space services for the development, publication and distribution of your user content.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">FREE USE. The free subscription to Web-Ar.space offers users the option of using 2 Web AR links (with a watermark from Web-Ar.space), including the associated Web AR-QR codes, and a total of 200 views per month. You are not able to extend your monthly views with your free subscription.</span></p>
            <h3 class="c3" id="h.s0m8l37kfauc"><span class="c0">6.2 Limitations</span></h3>
            <p class="c2"><span class="c1">At no time (directly or indirectly) may the user:</span></p>
            <p class="c2"><span class="c1">(a) use the Services for activities or purposes that are illegal, immoral or inappropriate, whether in Austria or elsewhere in the world;</span></p>
            <p class="c2"><span class="c1">(b) Modifying, adapting, translating, or creating derivative works or services based on the services of Web-Ar.space;</span></p>
            <p class="c2"><span class="c1">(c) the reverse engineering, decryption, decompilation, disassembly of source code of services from Web-Ar.space, for the same or similar services.</span></p>
            <p class="c2"><span class="c1">(d) copy the services of Web-Ar.space (in whole or in part);</span></p>
            <p class="c2"><span class="c1">(e) charge people for access to Web-Ar.space, or use of the Services;</span></p>
            <p class="c2"><span class="c1">(f) renting, leasing, lending, distributing, reselling, sub-licensing or transferring the services of Web-Ar.space; or</span></p>
            <p class="c2"><span class="c1">(g) access to all or part of the services to create a product or service that competes with the services of Web-Ar.space.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">It should be noted that these restrictions do not prohibit commercial users from using the Web-Ar.space services in connection with the development, creation, marketing, distribution and sale of their own products and services, provided that these terms of use and our fair Use guidelines are followed (see section 13 below).</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">The user may not use AR triggers belonging to another user for their own commercial purposes without their express consent, and the user may not use AR triggers in media or on materials (physical or digital) that do not belong to them unless he is authorized to do so by the owner.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Commercial users may not, without the prior written consent of Web-Ar.space, represent Web-Ar.space as a manufacturer or supporter of other products or services, or the names or logos of Web-Ar.space on or in connection with products or use services.</span></p>
            <h3 class="c3" id="h.df0q1kw3ht8f"><span class="c0">6.3 Notes of ownership</span></h3>
            <p class="c2"><span class="c1">The user may not remove, change or cover any notices contained in the services of Web-Ar.space regarding copyrights, brands or other property rights.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c3" id="h.bincejh7frql"><span class="c0">6.4 Reservation of rights</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space reserves all rights, titles and interests in and for the services, as well as all associated data and intellectual property, with the exception of the &ldquo;rights to use the services&rdquo;, which are noted in section 6 of this TOU. All content (including software) that we provide to you as part of the Services is licensed for your use during the term of the User Agreement under these Terms of Use, and is not sold to you.</span></p>
            <h1 class="c6" id="h.g687sjgsxc9o"><span class="c0">7. FEES, PAYMENT AND REFUND</span></h1>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">You must pay all applicable fees for your use of the Web-Ar.space services in accordance with the subscription prices. In addition, costs per view (CPV) are charged for the use. Every subscription includes a certain number of views per month, if you exceed those, we will inform you in advance. If reach 100% of your views, you will be charged per additional view. The rate of the additional view depends on your subscription. Plans All amounts charged for the use of the Services include the Austrian VAT. Fees are payable in the currency specified in the relevant invoice or settlement agreement.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">For fees that are not paid in full on the due date, interest accrues on these unpaid amounts at an annual rate of 5%, starting on the due date until full payment. We can also block your Web-Ar.space account and the right to use the services until all overdue payments have been brought up to date.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Web-Ar.space reserves the right to change the terms of payment and prices for the services at your own discretion by notifying you. The changed price and / or payment terms apply (unless otherwise stated) from the date of this notification and apply to all fees that you incur after the effective date of this price change.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">All payments made on the Web-Ar.space website are processed on our behalf by third parties. You accept that Web-Ar.space is not responsible for these third party providers and their operation or availability. By agreeing to these terms of use, you authorize Web-Ar.space or our designated payment provider to carry out all appropriate and necessary surveys to validate your Web-Ar.space account and your financial information.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Web-Ar.space reserves the right to monitor your (including authorized user) use of the Web-Ar.space services to ensure that you use the services in accordance with the user agreement and the correct fees for your use of the Pay services.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Web-Ar.space maintains a &quot;NO RETURN&quot; policy for all of our plans. All fees charged for using the Services (including VAT and other taxes levied on the fees) are NON-REFUNDABLE unless required by law. In the case of private users, this policy does not affect your statutory rights as a consumer.</span></p>
            <h1 class="c6" id="h.7jrgsfp4gms9"><span class="c0">8. USER WARRANTIES AND INDEMNITY</span></h1>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">You acknowledge, guarantee and undertake that:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_rm05l8rz51zg-0 start">
                <li class="c2 c5"><span class="c1">A WebAr.space account created by or on your behalf is intended for your personal or business use. The account must not be created with the intent to pretend to be another person, to misrepresent them, or to create incorrect associations.</span></li>
            </ul>
            <ul class="c13 lst-kix_2e4nzy59iprt-0 start">
                <li class="c2 c5"><span class="c1">Use of the Services must be in strict accordance with these Terms of Use, including all relevant and applicable laws and regulations, including local laws or regulations in your country, state, city, or other governmental area.</span></li>
                <li class="c2 c5"><span class="c1">You abide by the rules and regulations of your mobile operator, internet service provider and other third party providers.</span></li>
                <li class="c2 c5"><span class="c1">If you are a business user, you must adhere to prudent practices, methods, specifications, and safety and performance standards that are commonly used in activities that are similar to the standards of your company or profession, including advertising standards, professional standards, or codes of conduct; and</span></li>
                <li class="c2 c5"><span class="c1">All information you provide to Web-Ar.space is true and correct throughout your use of the Services and is accurate and complete. You will immediately inform Web-Ar.space of any significant changes to this information.</span></li>
            </ul>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">INDEMNIFICATION</span></p>
            <p class="c2"><span class="c1">You acknowledge that any violation of these Terms of Use may cause Web-Ar.space to cause damage or loss, and you agree to Web-Ar.space and its employees, directors and shareholders of all liabilities, claims, actions indemnify and hold harmless. Procedures, losses, damages, costs and expenses (including court costs and attorney&#39;s fees), whether direct, indirect or as a result, including economic losses or other losses of profits, business or goodwill, the Web-Ar.space or one of the Web-Ar.space compensated parties arise or may arise as a result of any of the following: (a) your use of the Services, including content triggers; (b) your user content; and (c) violations by you or others who act on your behalf against these Terms of Use.</span></p>
            <h1 class="c6" id="h.oxgvatjais9k"><span class="c0">9. USER CONTENT: GENERAL RULES</span></h1>
            <p class="c2"><span class="c1">Strict compliance with the following provisions of sections 9, 10 and 11 are a basic condition of this user agreement. If you use the upload function, you are responsible for managing your legal relationship with end users and for setting up all necessary terms of use and privacy policies. You ensure that people who upload content to your AR triggers using the upload feature do so in accordance with sections 9, 10 and 11 of these Terms of Use.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Users confirm that the services are designed to be accessible over the Internet, and Web-Ar.space does not guarantee confidentiality or privacy with regard to user content. Any user content you publish will be visible to anyone who holds the associated AR triggers. Due to the nature of the Internet and in particular the search engines, user content can remain on devices or websites that are not operated or controlled by Web-Ar.space, even after this user content has been removed or deleted from our systems. You acknowledge and agree that all user content published in the Services is openly available on the Internet.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">You acknowledge and confirm that you and not Web-Ar.space are solely responsible for all user content that you make available via the services, in particular all content that is uploaded via the upload function. User content is not checked or edited by Web-Ar.space in advance, and Web-Ar.space does not conduct any editorial or content-related review of user content.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">Regardless, Web-Ar.space reserves the right, at its sole discretion (but is not required), to deny, suspend, or remove access to user content available through the Services at any time. We do not need to exercise this right (or notify you in advance), but we would normally only deny, suspend, or remove content if: (a) we believe it is illegal or violates these Terms of Use; or (b) we receive a complaint about your user content; or (c) we receive a court order requesting removal from our systems; or (d) the content violates our terms of use with our server provider. Removed user content can still be saved by us in order to comply with legal obligations.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">While Web-Ar.space makes reasonable efforts to ensure that AR triggers correctly linked to the user content that users have assigned to them, you acknowledge that it is your sole responsibility to test the correct functionality of all AR triggers and check before you commit. WEB-AR.SPACE WILL NOT BE LIABLE FOR LOSSES RESULTING FROM AN ERRORLY CONFIGURED AR TRIGGERS.</span></p>
            <h1 class="c6" id="h.z7jslmq8x5fv"><span class="c0">10. USER CONTENT: OWNERSHIP</span></h1>
            <p class="c2"><span class="c1">Subject to the provisions of this Section 10, you, or your licensor, retain ownership of all rights (including intellectual property rights) that you currently have under the applicable law to user content. You or your end users reserve ownership of all rights (including intellectual property rights) that you currently have under applicable law to the user content uploaded to your AR trigger through the upload function.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">So that we can provide you with the services and distribute your user content via the AR triggers you have selected, you grant Web-Ar.space an irrevocable, worldwide, royalty-free and non-exclusive license to save, reproduce, adapt and change (e.g. For technical reasons, so that the content works on our platform), for translating, publishing, displaying, executing, transferring, distributing and any other use of your user content via the services of Web-Ar.space, exclusively for Web-Ar.space to give the opportunity to operate, maintain, make available and distribute the services in accordance with the user agreement. In addition (if applicable), you enable our content delivery platform to (a) complete the final, published compilation of your user content and (b) allow those who activate your AR triggers to access your user content. Your license includes the right for Web-Ar.space to provide and sub-license your user content for use by our service providers who are used in connection with the Web-Ar.space services (e.g. Microsoft Azure) insofar as this is necessary to provide the services. The license granted to Web-Ar.space remains in effect throughout your use of the services and until you remove user content from our systems. This is just a license - your ownership of your user content is not affected. You warrant and warrant to Web-Ar.space that you have (and will continue to have, all rights, privileges, powers, and authority to grant the licenses contained in this paragraph) while using the Services.</span></p>
            <h1 class="c6" id="h.v2z8klldqu69"><span class="c0">11. USER CONTENT: PROHIBITED CONTENT</span></h1>
            <p class="c2"><span class="c1">Under no circumstances may you create, enter, import, upload, save, link, share, publish, make public, distribute content while using the Web-Ar.space services that:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_b9lxj8popl1l-0 start">
                <li class="c2 c5"><span class="c1">contain or are a virus;</span></li>
                <li class="c2 c5"><span class="c1">unlawful, harmful, offensive, abusive, threatening, defamatory, defamatory, vulgar, obscene, offensive, threatening, harassing or racially or ethnically abusive, or otherwise objectionable or otherwise in breach of the law;</span></li>
                <li class="c2 c5"><span class="c1">Facilitate or promote behaviors that constitute a crime or otherwise violate any applicable law, regulation or code of conduct;</span></li>
                <li class="c2 c5"><span class="c1">show indecent, pornographic or sexually explicit images;</span></li>
                <li class="c2 c5"><span class="c1">endanger, harm or improperly treat the rights of children and minors;</span></li>
                <li class="c2 c5"><span class="c1">serve to incite, encourage or encourage violent, exploitative, racist, discriminatory, socially unacceptable or irresponsible behavior;</span></li>
                <li class="c2 c5"><span class="c1">are discriminatory based on a person&#39;s age, race, gender, gender reassignment, skin color, religious belief, sexual orientation, disability or any other characteristic to be protected;</span></li>
                <li class="c2 c5"><span class="c1">disclose confidential or sensitive information unless you are authorized to do so;</span></li>
                <li class="c2 c5"><span class="c1">impersonate any person, misrepresented their affiliation with someone, or contains fake or manipulated headers designed to obscure the origin of the content;</span></li>
                <li class="c2 c5"><span class="c1">violates a person&#39;s data protection rights, or collects data about others without their knowledge and consent;</span></li>
                <li class="c2 c5"><span class="c1">constitutes a violation of copyrights, patents, trademark rights, trade secrets, database rights, or another intellectual property right of a person or a company, unless you have the permission of the data controller or the owner of these rights;</span></li>
                <li class="c2 c5"><span class="c1">Allow streams, distributions, links to / from, or access to materials that you know about or that you should reasonably know should not be legally streamed, distributed, linked, or accessed;</span></li>
                <li class="c2 c5"><span class="c1">Cause damage or injury to people or property;</span></li>
                <li class="c2 c5"><span class="c1">disrupt or affect the use or experience of another person&#39;s services.</span></li>
            </ul>
            <h1 class="c6" id="h.c7g59jqka3ev"><span class="c0">12. DATA PROTECTION AND PRIVACY</span></h1>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">In this Section 12, the terms &quot;Personal Data&quot;, &quot;Controller&quot;, &quot;Processor&quot; and &quot;Process Processing&quot; or &quot;Processing&quot; have the same meaning as in Article 4 of the GDPR.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Before you decide to become a Web-Ar.space user, you should read our Privacy Notice for Web-Ar.space carefully. The Privacy Notice describes what information, including personal data, we could collect from you in connection with the services and how we use this information. You can find our Privacy Notice in the section Web-Ar.space data protection. Your use of the services may also result in cookies and similar technologies being stored on your computer or mobile device.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Unless expressly stated otherwise in our Privacy Notice, the user is responsible for the collection, storage, disclosure and use, as well as for access to personal data of persons that can be attributed to the use of the services by the user, including all information that are contained in the user&#39;s content. In addition, it is assumed that the above Privacy Notice does not apply to personal data that the user collects from AR triggers of his end users in his function as data controller. The user must ensure that he informs his end users of what personal data end users collect and how the user uses the personal data. The use of the upload function assumes that the user assumes responsibility for compliance with all applicable data protection and data protection laws.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If Web-Ar.space processes personal data on behalf of a user when providing the services, the parties note their intention that the user is the data controller and Web-Ar.space acts solely as a processor.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">In this case, the following general principles apply:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_apw34412w8ae-0 start">
                <li class="c2 c5"><span class="c1">The user confirms that the personal data may be transferred or stored outside the EEA or outside the country in which the user is located in order to fulfill the services and other obligations of Web-Ar.space in relation to these terms of use .</span></li>
                <li class="c2 c5"><span class="c1">The user ensures that he is entitled to transfer the relevant personal data to Web-Ar.space so that Web-Ar.space can legally use, process and transfer the personal data in accordance with these terms of use on behalf of the user.</span></li>
                <li class="c2 c5"><span class="c1">The user ensures that relevant third parties (including the end users of the user&#39;s AR triggers) have been informed of the use, processing and transfer of the data and have given their consent (if required by law);</span></li>
                <li class="c2 c5"><span class="c1">Web-Ar.space processes the personal data only in accordance with these terms of use and</span></li>
                <li class="c2 c5"><span class="c1">Each party takes appropriate technical and organizational measures against the unauthorized or unlawful processing of personal data, or their accidental loss, destruction or damage.</span></li>
            </ul>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If applicable, the Web-Ar.space Data Processing Addendum (DPA) applies to the personal data processed by Web-Ar.space on behalf of the user.</span></p>
            <h1 class="c6" id="h.ygx4bu5f3h9a"><span class="c0">13. FAIR USE POLICY</span></h1>
            <h3 class="c3" id="h.tltr04t6j91x"><span class="c0">13.1 General statement</span></h3>
            <p class="c2"><span class="c1">There is a guideline for fair and acceptable use for the services of Web-Ar.space. This fair use guideline applies to everyone who uses the services. Under our fair use policy, you may not do any of the following:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_mjwvwpe0iw1x-0 start">
                <li class="c2 c5"><span class="c1">using the Services in a manner that violates Sections 9 through 12 (inclusive);</span></li>
                <li class="c2 c5"><span class="c1">the use of the Services in a manner that interferes or affects, or could affect or interfere with, the Services;</span></li>
                <li class="c2 c5"><span class="c1">Use of the Services in a manner that is excessive or abnormal, or places a significant burden on our platform, website, online services or network, and so such use is detrimental to other users.</span></li>
                <li class="c2 c5"><span class="c1">using the Services in a manner that violates or misuses a person&#39;s intellectual property or data protection rights;</span></li>
                <li class="c2 c5"><span class="c1">removing, or extracting content, data, code, scripts, or images of the Services;</span></li>
                <li class="c2 c5"><span class="c1">Uploading or implementing viruses into the services;</span></li>
                <li class="c2 c5"><span class="c1">Linking, or attempting to link, viruses or content or materials to an AR trigger owned by another user.</span></li>
            </ul>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">You must comply with all instructions and specifications that we provide regarding the media files to be uploaded (e.g. format, type, size and length)</span></p>
            <h3 class="c3" id="h.mmnvelxq37s1"><span class="c0">13.2 Specific restrictions for the use of the service</span></h3>
            <p class="c2"><span class="c1">THE FOLLOWING SPECIFIC RESTRICTIONS APPLY TO OUR FAIR USE POLICY:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_y684lseteebj-0 start">
                <li class="c2 c5"><span class="c1">WE HAVE DETERMINED A CAP FOR THE AR TRIGGER IN SUBSCRIPTIONS. THIS LIMIT IS CURRENTLY ONE HUNDRED (100). WE RESERVE THE RIGHT TO CHANGE THIS LIMIT (UP OR DOWN). USERS WHO WANT TO USE MORE THAN A HUNDRED (100) AR TRIGGER MAY REQUEST AN INCREASE IN THE LIMIT. THE EXTENSION INCLUDES ADDITIONAL CHARGES.</span></li>
                <li class="c2 c5"><span class="c1">WE HAVE IN THE SUBSCRIPTIONS A LIMIT FOR THE CALLS OF THE AR TRIGGER. THIS LIMIT IS CURRENTLY BETWEEN FIVE HUNDRED (500) AND HUNDRED THOUSAND (100,000). WE RESERVE THE RIGHT TO CHANGE THIS LIMIT (UP OR DOWN). USERS EXCEEDING MORE THAN THE CALLS SPECIFIED IN THE SUBSCRIPTION MAY REQUEST AN INCREASE IN THE LIMIT. THE EXTENSION INCLUDES ADDITIONAL CHARGES.</span></li>
            </ul>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">IN ORDER TO LEARN DETAILED INFORMATION ABOUT THE EXCEEDINGS, CONTACT US: SUPPORT@WEB-AR.SPACE</span></p>
            <h3 class="c3" id="h.nau9hx269mfu"><span class="c0">13.3 Consequences of a violation</span></h3>
            <p class="c2"><span class="c1">If you violate our Fair Use Policy, or we believe that you are using the Services in an unfair, excessive or disruptive manner, or otherwise in our sole and absolute discretion, we may (in addition to the other rights set forth in these Terms of Use ) Set restrictions or restrictions for service functions. For example, we can &nbsp;limit the number of AR triggers assigned to, or limit the number of calls for your AR triggers.</span></p>
            <p class="c2"><span class="c1">If you violate our fair use policy, you will normally be asked to change your behavior before we take action. However, we reserve the right not to notify you of willful, serious or persistent breaches or abuse of the Services. You acknowledge that a violation of the Fair Use Policy may result in immediate termination of your User Agreement and the right to use the Services.</span></p>
            <h1 class="c6" id="h.y0tdnytfm4z3"><span class="c0">14. DELIVERY, AVAILABILITY AND CHANGE OF SERVICES </span></h1>
            <p class="c2"><span class="c1">Web-Ar.space provides the services to the account holder and the authorized users at the time of an activated Web-Ar.space account. If an account holder decides to subscribe to the free services plan, Web-Ar.space only offers the framework of services that are available free of charge. If the account holder starts with the paid subscription of the services, Web-Ar.space provides the framework for the services that is required for the selected subscription at the time of payment.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Web-Ar.space guarantees the account holder that (i) we will provide the services with reasonable care and expertise and (if applicable) within a reasonable time; and (ii) that the services essentially correspond to the descriptions published at https://web-ar.space/. We make no promise that the services will meet your needs; We also do not guarantee range that is generated by using the AR trigger. e.g. B. the number of calls, the AR triggers that you receive. We do not guarantee that the services are error-free or offer constant, uninterrupted access. If the services fail, you should report them to support@web-ar.space. We will try to fix the error as soon as possible.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">To allow repairs, maintenance, updates or the introduction of new functions or services, your access to the services may occasionally be suspended or restricted. In this case we will try to restore the service as soon as possible.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">The services may change over time and we require acceptance of the updated services. We also reserve the right to change, update or discontinue (temporarily or permanently) the Services (or any aspect thereof), to limit certain aspects of the Services. This right can be exercised at any time and with or without notification to users.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <h1 class="c6" id="h.nkzlepji2b6c"><span class="c0">15. TERMINATION OF THE USER AGREEMENT</span></h1>
            <p class="c2"><span class="c1">WebAr.space subscriptions remain in effect until canceled in accordance with the terms of these TOU. All Web-Ar.space subscriptions are automatically renewed at the end of the billing period, unless the account holder ends / unsubscribes before the subscription is renewed.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Web-Ar.space may, at its sole discretion, immediately suspend or terminate the User Agreement and your access to and use of all or part of the Services, with or without notice, for any reason, including (a) if you violate any of the terms violate these terms of use; (b) if Web-Ar.space is required to do so by law or by order of a competent authority; or (c) Web-Ar.space permanently stops the services. The suspension of services does not affect your obligations under these terms of use. Any suspected fraudulent, abusive or illegal activity may be grounds for terminating the user agreement.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">The account holder can terminate the user agreement and his Web-Ar.space account at any time, for any reason, by written notice. This cancellation will take effect on the day we receive your notice at the address below. If you cancel your Web-Ar.space account, your subscription will be charged for the rest of the billing cycle (monthly or yearly) in which you are currently. Your WebAr.space account will be deactivated at the end of the current billing cycle.</span></p>
            <h1 class="c6" id="h.8cv5l1ksjx0t"><span class="c0">16. WHAT HAPPENS WHEN THE USER AGREEMENT ENDS?</span></h1>
            <p class="c2"><span class="c1">In the event of termination of the services or termination of the user agreement by Web-Ar.space or the account holder, the following happens regardless of the reason:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_4u8rmnmzb62-0 start">
                <li class="c2 c5"><span class="c1">Your right to use the services and the rights granted to you in Section 6.1 end immediately and you confirm and agree that Web-Ar.space will delete your Web-Ar.space account and remove or archive all content and information (including user content) ;</span></li>
                <li class="c2 c5"><span class="c1">Web-Ar.space can deny you further access to Web-Ar.space and the use of the services. (This may include applying technical measures to prevent you from accessing the services.)</span></li>
                <li class="c2 c5"><span class="c1">Web-Ar.space is entitled, but not obliged, to deactivate all existing AR triggers.</span></li>
                <li class="c2 c5"><span class="c1">Web-Ar.space is not liable for claims or damages against third parties resulting from the cancellation or termination, nor for the resulting loss of content or information.</span></li>
                <li class="c2 c5"><span class="c1">Web-Ar.space is under no obligation to reimburse you for fees or other payments unless applicable law provides otherwise, regardless of the remaining term of your subscription to the Services. and</span></li>
                <li class="c2 c5"><span class="c1">All provisions of these terms of use, which should continue to exist after the termination, on the basis of their purpose or because it is expressly provided for in this way, remain in effect.</span></li>
            </ul>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">The cancellation or termination of the User Agreement shall be without prejudice to the provisions of this Section 16 and to any rights either of us may have accrued by, at or up to the date of such cancellation or termination.</span></p>
            <h1 class="c6" id="h.vm1jpqlyta5u"><span class="c0">17. OWNERSHIP OF THE SERVICES AND TRADEMARKS</span></h1>
            <p class="c2"><span class="c1">The user agrees that Web-Ar.space has all property, property and intellectual property rights to the services of Web-Ar.space. Unless expressly stated otherwise, these terms of use do not grant the user any rights in or relating to patents, copyrights, database rights, trade secrets, trade names, brands (whether registered or not registered) or other rights or licenses.</span></p>
            <p class="c2"><span class="c1">Web-Ar.space is the sole and exclusive owner of the Web-Ar.space logo, the Web-Ar.space brands, and the general &ldquo;look and feel&rdquo; of the services, including all registrations and requests for registration of the Web- Ar.space brands. You may not display, reproduce or use any of the Web-Ar.space brands without our prior written consent. Any permitted use of Web-Ar.space brands by you benefits Web-Ar.space and you do not acquire any right or title to any of the Web-Ar.space brands. All other brands, advertising messages, graphics and logos that are used on or in connection with the services are not registered trademarks or service marks of Web-Ar.space.</span></p>
            <h1 class="c6" id="h.qlyuy0lxgyyp"><span class="c0">18. THIRD PARTY SERVICES</span></h1>
            <p class="c2"><span class="c1">For ease of use, the Services may include links to other resources, online services, or materials (&quot;Third Party Service&quot;) that are beyond our control. Web-Ar.space is not responsible for the content, functions, correctness, legality, adequacy or other aspects of such third party services. The inclusion of a link to a third-party service does not mean approval by Web-Ar.space or a connection with its operators.</span></p>
            <h1 class="c6" id="h.5qoyg8tob6pz"><span class="c0">19. ADVERTISING AND SPONSORSHIP</span></h1>
            <p class="c2"><span class="c1">Parts of the services may contain or display advertising, sales promotion and sponsorship from third parties. Advertisers, organizers and sponsors are responsible for ensuring that material and promotions submitted for inclusion in the services comply with applicable laws and codes of conduct. Web-Ar.space is not responsible for errors or inaccuracies in advertising or sponsorship material or for the implementation of promotions.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Your communication and dealings with advertisers, organizers and sponsors through whom services were found, including payment and delivery of related goods or services, other terms, guarantees or representations related to such transactions, are solely between you and the third party concerned. Web-Ar.space assumes no responsibility or liability in relation to this.</span></p>
            <h1 class="c6" id="h.esek4i30djqp"><span class="c0">20. DISCLAIMER</span></h1>
            <p class="c2"><span class="c1">EXCEPT IF EXPRESSLY AND SPECIFICALLY IN THESE TERMS OF USE: (A) ONLY THE USER TAKES RESPONSIBILITY FOR THE RESULTS AND FOR THE CONCLUSIONS OF THE RESULTS FROM THE USE OF THE SERVICES BY THE USER. WEB-AR.SPACE MAKES NO LIABILITY FOR DAMAGES CAUSED BY ERRORS OR LOST IN / FROM CONTENT, INFORMATION, INSTRUCTIONS OR OTHER MATERIALS (B) ALL WARRANTIES, REPRESENTATIONS, CONDITIONS AND ANY CONDITIONS, OR ANY CONDITIONS, OR ANY CONDITION EXCLUDING COMMON LAW ARE EXCLUDED IN THE COMPLETE RELATIONSHIP UNDER THE APPLICABLE LAW; AND (C) THE SERVICES ARE PROVIDED TO THE USER ON THE BASIS &quot;AS AVAILABLE&quot;.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">WEB-AR.SPACE TAKESNO RESPONSIBILITY OR LIABILITY FOR ANY IMPOSSIBILITY TO ACCESS OR USE THE SERVICES, OR FOR ANY DEPENDENCY OF YOUR COMPANY ON THE SERVICES. WEB-AR.SPACE DOES NOT CONTROL HOW YOU GENERATE CALLS FROM YOUR AR TRIGGER. WE ARE NOT RESPONSIBLE FOR FRAUDULENT ACTIVITIES IN OR WITH YOUR AR TRIGGERS, OR FOR OTHER POTENTIALLY INTERRUPTING ACTIVITIES THAT MAY AFFECT YOUR BUSINESS.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">WEB-AR.SPACE TAKES NO OBLIGATION TO VERIFY THE IDENTITY OF THE PEOPLE WHO USE THE SERVICES. THEREFORE, WE DISCLAIM ANY LIABILITY FOR IDENTITY THEFT OR ANY OTHER ABUSE OF IDENTITY, INFORMATION OR USER CONTENT.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">IN THE EVENT OF A LOSS OR DAMAGING OF DATA IN USER CONTENT (USER DATA), WEB-AR.SPACE TRIES TO RECOVER LOST OR DAMAGED DATA. WEB-AR.SPACE DOES NOT TAKE RESPONSIBILITY FOR LOSS, DESTRUCTION, MODIFICATION OR DISCLOSURE OF USER DATA CAUSED BY THIRD PARTIES. WEB-AR.SPACE SHOULD NOT BE USED AS A BACKUP FOR YOUR FILES, AND YOU MUST SAVE YOUR FILES BEFORE UPLOADING THEM TO THE SERVICES.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">THE SERVICES AND AR-RELEASES PROVIDED BY USERS FOR USE MAY INCLUDE TECHNOLOGIES THAT ARE NOT TROUBLESHOOTING AND NOT INTENDED FOR USE IN DANGEROUS ENVIRONMENTS, OR AREA DETERMINED FOR THE ENVIRONMENT , COMMUNICATION OR FLIGHT CONTROL, LIFE SUPPORTING MACHINES, SECURITY SYSTEMS ETC.) THE USER REMOVES WEB-AR.SPACE ANY LIABILITY FOR THE USE OF WEB-AR.SPACE INCL. AR TRIGGER, FOR THESE OR SIMILAR SERVICES, LISTED IN THIS SECTION.</span></p>
            <h1 class="c6" id="h.pf1a20jeehby"><span class="c0">21. EXCLUSION AND LIMITATION OF LIABILITY </span></h1>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">21.1. USER ATTENTION IS IN PARTICULAR APPLIED TO THE PROVISIONS OF THIS SECTION 21, IN WHICH WEB-AR.SPACE&#39;S ENTIRE LIABILITY (INCLUDING ANY LIABILITY FOR THE MEASURES OR EXCLUSIONS OF ITS DIRECTOR, EMPLOYEE IMPROVEMENT); (B) ANY USE BY THE USER OF THE SERVICES, OR ANY ASPECT BY YOU; AND (C) ANY REPRESENTATION, DECLARATION OR SERIOUS FACT OR SUBJECT (INCLUDING NEGLIGENCE) WHICH ORIGINATES UNDER OR IN CONNECTION WITH THE USER AGREEMENT, THESE TERMS, CONDITIONS, OR SERVICES.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">21.2 NOTHING IN THESE TERMS AND CONDITIONS EXCLUDES WEB-AR.SPACE&#39;S LIABILITY FOR THE FOLLOWING AFFAIRS: (A) DEATH OR PERSONAL INJURY ARISING FROM OUR NEGLIGENCE; (B) FRAUD OR FRAUDULENT MISREPRESENTATION; OR (C) ANY OTHER LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY APPLICABLE LAW, INCLUDING YOUR LEGAL RIGHTS AS A CONSUMER (IF APPLICABLE).</span></p>
            <p class="c2"><span class="c1">21.3 SUBJECT TO SECTION 21.2, WEB-AR.SPACE SHALL NOT BE LIABLE TO THE USER WHETHER IN TORT (INCLUDING FOR NEGLIGENCE OR BREACH OF STATUTORY DUTY), CONTRACT, MISREPRESENTATION, RESTITUTION OR OTHERWISE FOR ANY OF THE FOLLOWING: (A) ANY LOSS OF REVENUE OR PROFITS, LOSS OF BUSINESS, LOSS OF OPPORTUNITY, OR LOSS OF ANTICIPATED SAVINGS; (B) DEPLETION OF GOODWILL OR INJURY TO REPUTATION; (C) LOSS OR CORRUPTION OF DATA OR INFORMATION; (D) LOSSES SUFFERED BY THIRD PARTIES; (E) ANY SPECIAL, INDIRECT OR CONSEQUENTIAL LOSS, COSTS, DAMAGES, CHARGES OR EXPENSES (INCLUDING EXEMPLARY, MULTIPLE OR PUNITIVE DAMAGES OF ANY KIND); HOWSOEVER ARISING UNDER OR IN CONNECTION WITH THE USER AGREEMENT AND/OR THE SERVICES AND REGARDLESS OF WHETHER OR NOT SUCH LOSSES WERE FORESEEABLE OR IN THE CONTEMPLATION OF THE PARTIES AT THE START OF THE USER AGREEMENT OR WEB-AR.SPACE WAS ADVISED OF THE POSSIBILITY OF THE USER SUFFERING THE SAME.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">23.4 SUBJECT TO SECTION 21.2, WEB-AR.SPACE&#39;S TOTAL AGGREGATE LIABILITY TO THE USER IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR BREACH OF STATUTORY DUTY), MISREPRESENTATION, RESTITUTION OR OTHERWISE ARISING UNDER OR IN CONNECTION WITH THE USER AGREEMENT SHALL BE LIMITED TO WHICHEVER IS THE GREATER OF (1) THE TOTAL FEES ACTUALLY PAID BY THE USER FOR USE OF THE SERVICES DURING THE 12 MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH THE CLAIM AROSE AND (2) THE SUM OF &pound;100 GBP. THE LIABILITY LIMIT IN THIS SECTION SHALL INCLUDE ALL CLAIMS FOR INTEREST WHETHER STATUTORY OR OTHERWISE.</span></p>
            <h1 class="c6" id="h.a49kxxka7mt5"><span class="c0">22. INTERNATIONAL USE</span></h1>
            <p class="c2"><span class="c1">Although the services are available worldwide, Web-Ar.space does not promise that the services are suitable or available for the use outside of Austria. Access to the services of places where their content is illegal or unlawful is prohibited. If you access and use the services from outside Austria, you do so on your own initiative and are responsible for compliance with local laws. Any offer of services and / or information related to the services are void unless prohibited.</span></p>
            <h1 class="c6" id="h.x260y5wfucsy"><span class="c0">23. GENERAL PROVISIONS</span></h1>
            <p class="c2"><span class="c11">Amendments.</span><span class="c1">&nbsp;Unless expressly stated otherwise, the user agreement can only be changed or changed by a written document signed on behalf of the account holder and an authorized representative of Web-Ar.space.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c11">Assignment.</span><span class="c1">&nbsp;The user agreement is personal to the parties. The account holder may not assign, transfer, subcontract or otherwise transfer the benefits and / or the burden of the user agreement to third parties without the prior written consent of Web-Ar.space. We may transfer all or some of our rights under the User Agreement to another legal entity or company if we believe that your rights will not be affected.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c11">Entire Agreement.</span><span class="c1">&nbsp;You agree that the user agreement, these terms of use and the other documents mentioned therein represent the overall contract between you and Web-Ar.space in relation to the services and their use. All previous agreements, arrangements, notifications and suggestions (oral, written or electronic) are superseded in relation to the subject of the usage agreement. Each of the parties confirms and agrees that the conclusion of the user agreement is not based on a promise, promise, assurance, declaration, guarantee or understanding (whether in writing or orally) of a person. Regarding the contractual object of the user agreement, other than as not expressly specified in these terms of use. This provision does not exclude liability for fraud or fraudulent misrepresentation.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c11">Force majeure.</span><span class="c1">&nbsp;Web-Ar.space is not responsible for violations of the user agreement caused by circumstances beyond our reasonable control, including force majeure, terrorism, failures or delays in transport or communication, failure of your internet or your mobile connectivity, failure the world wide web, denial of service attacks, strikes, work stoppages or acts.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c11">No waiver.</span><span class="c1">&nbsp;If you violate the user agreement and Web-Ar.space ignores this, we are still entitled to use our rights and remedies at a later time or in another situation in which you violate the user agreement.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c11">Relationships between us.</span><span class="c1">&nbsp;No agency, partnership, joint venture or employment relationship is created through the user agreement. Neither party has the authority to act on behalf or as a representative or otherwise to represent or bind the other party in any way.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c11">Severability clause.</span><span class="c1">&nbsp;Should a provision of the user agreement be invalid or unenforceable, all other provisions remain valid and enforceable insofar as they can take effect without the invalid parts.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c11">Third party rights. </span><span class="c1">The usage agreement does not transfer any rights to any person or party other than Web-Ar.space and the account holder and, if applicable, their successors and legal successors.</span></p>
            <h1 class="c6" id="h.eyukdurrf3d4"><span class="c0">24. NOTICE</span></h1>
            <p class="c2"><span class="c1">Any notification that Web-Ar.space must or may make in accordance with these terms of use can either be sent to you by email or published on the Web-Ar.space website. You can send us communications by email or post using our addresses listed in Section 26: &ldquo;Our Contact Information&rdquo;. An email is deemed to have been received on the day of the successful submission, and any message sent to us by post is deemed to have been received when we have actually received it in our offices.</span></p>
            <h1 class="c6" id="h.1mn9rey0mzo0"><span class="c0">25. APPLICABLE LAW AND JURISDICTION</span></h1>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Regardless of any conflict of laws, these terms of use, the user agreement and all other aspects of the relationship between you and Web-Ar.space (including non-contractual disputes or claims) are subject to Austrian law, regardless of your country of origin. We will try to resolve any disagreements or claims related to these Terms of Use, the User Agreement or the Services quickly and efficiently. If you are not satisfied with the way we handle disputes (including non-contractual disputes or claims) and you want to take legal action or legal process in relation to the dispute, you must do so only in Austria. Notwithstanding this section, you agree that Web-Ar.space may continue to seek injunctive relief (or an equivalent type of urgent legal action) in any jurisdiction that you reside in or own.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">NO CLASS ACTION. Users can only resolve disputes with Web-Ar.space on an individual basis and make no claim in a class, consolidated or representative lawsuit. Class arbitration, class actions, general lawsuits by private lawyers and consolidation with other arbitration proceedings are not permitted.</span></p>
            <h1 class="c6" id="h.bwkhafvfqri5"><span class="c0">26. OUR CONTACT DETAILS</span></h1>
            <p class="c2"><span class="c1">The services are the property of Web-Ar.space (twospot GmbH.), A limited liability company registered in Austria with the commercial register number FN 479858z. The company&#39;s headquarters are located in Mariahilferstrasse 118/33, 1070 Vienna, Austria. The company is registered for sales tax in Austria and our VAT number is ATU72696736.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If you have any questions or complaints about the services, you can be contacted at the following addresses:</span></p>
            <p class="c2"><span class="c1">Postal address: twospot GmbH. Mariahilferstrasse 118/33, 1070 Vienna, Austria</span></p>
            <p class="c2"><span class="c1">Email address: support@web-ar.space</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c15 title" id="h.cfrtmf6lg3el"><span class="c17">II. Web-Ar.space Privacy Policy</span></p>
            <p class="c2"><span class="c1">Last update: March 31, 2020</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h1 class="c6" id="h.xjtz2x5qmu0x"><span class="c0">PURPOSE OF THIS NOTICE</span></h1>
            <p class="c2"><span class="c1">We at Web-ar.space/twospot GmbH take the data protection of our users seriously and are committed to ensuring that personal data are processed in accordance with the applicable data protection and data protection laws, such as the European General Data Protection Regulation (&quot;GDPR&quot;).</span></p>
            <h1 class="c6" id="h.61u2dv6u2ckv"><span class="c0">APPLICABILITY</span></h1>
            <p class="c2"><span class="c1">This Privacy Notice applies between users of Web-Ar.space and twospot GmbH. The Privacy Notice deals with our use of information that we have collected in connection with your use of Web-Ar.space.</span></p>
            <p class="c2"><span class="c1">In general, this Privacy Notice does not apply to &quot;user content&quot;.</span></p>
            <p class="c2"><span>The provisions for the processing of user content in connection with the service of Web-Ar.space are regulated in the point </span><span class="c11">Data Processing Addendum</span><span class="c1">&nbsp;(Web-Ar.space as data processor).</span></p>
            <p class="c2"><span class="c1">There can be an exception if you use a web space privately and web space is considered the data controller with regard to &quot;content&quot; (as defined in this Privacy Notice). We have therefore included some guidelines for your content in the section of this privacy policy entitled &quot;Information We May Collect&quot;.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">Please also note that this Privacy Notice only applies to your use of Web-Ar.space. This Privacy Notice does not extend to websites or services of third parties that can be accessed via Web-Ar.space.</span></p>
            <h1 class="c6" id="h.lc21ufcko9az"><span class="c0">WHAT DOES WHAT MEAN? </span></h1>
            <p class="c2"><span class="c1">In this Privacy Notice, some of the terms used have a special meaning. These terms are listed here:</span></p>
            <p class="c2"><span class="c1">&nbsp;&quot;Anonymous information&quot;</span></p>
            <p class="c2"><span class="c1">means information that cannot identify a particular person and cannot be used for identification. If anonymous information is linked to personal data, Web-Ar.space treats this anonymous information as personal data.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;AR trigger&quot;</span></p>
            <p class="c2"><span class="c1">AR triggers are Web AR links or Web AR QR codes, both of which are linked to the desired content and call up the desired content.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Information&quot;</span></p>
            <p class="c2"><span class="c1">(depending on the context) means some or all of the information that you or your device send to Web-Ar.space via the services of Web-Ar.space.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;IP address&quot;</span></p>
            <p class="c2"><span class="c1">means a number that is automatically assigned to your device when you use the Internet. It can vary from session to session.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Personal data&quot;</span></p>
            <p class="c2"><span class="c1">means all personal information that we have about you that could be used to infer your personal identity.</span></p>
            <p class="c2"><span class="c1">e.g. Your name, email address, IP address and possibly other information such as identification numbers.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;You&quot;</span></p>
            <p class="c2"><span class="c1">means that you, the natural person who visits the Web-Ar.space website, uses the Web-Ar.space services or registers as a user of the Web-Ar.space services.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Content, User Content&quot;</span></p>
            <p class="c2"><span class="c1">means all 3D models, animations, images or audio files that you have uploaded, imported or transmitted.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Web-Ar.space&quot; or &quot;We&quot;</span></p>
            <p class="c2"><span class="c1">means twospot GmbH, a limited liability company registered in Austria, which is registered under the commercial register number FN 479858 z.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&quot;Web-Ar.space account, user account, account&quot;</span></p>
            <p class="c2"><span class="c1">means the Web-Ar.space account that has been registered for the use of the Web-Ar.space services with the necessary login information.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">&ldquo;Services, Web-Ar.space services, Web-Ar.space services&rdquo;</span></p>
            <p class="c2"><span class="c1">are the services and functions of the WebAR platform, which are available at https://web-ar.space/, together with the associated databases, features, functions, plug-ins, software, tools, documentation and websites, including any changes or updates.</span></p>
            <h1 class="c6" id="h.sizn84j381vm"><span class="c0">IDENTITY OF THE DATA CONTROLLER</span></h1>
            <p class="c2"><span class="c1">The data controller is twospot GmbH, a limited liability company registered in Austria (company number FN 479858 z). You can find our contact details below in the section &ldquo;Contacting us&rdquo;.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">Web-Ar.space only acts as data controller for Austrian and EU data protection law if the purposes and types of use of personal data determined by us are observed. For certain personal data (e.g. all personal data contained in your content), you and not Web-Ar.space determine the purposes and the manner in which the personal data are used. In this case, you act as the data controller and we act as the data processor. In these cases, the Data Processing Addendum (Web-Ar.space as data processor) applies instead of this Privacy Notice.</span></p>
            <h1 class="c6" id="h.g1sey33y40xz"><span class="c0">YOUR RIGHTS</span></h1>
            <p class="c2"><span class="c1">As a data subject, you have the following rights in accordance with the data protection laws of Austria and the EU (including the GDPR), which we will always work to comply with:</span></p>
            <ul class="c13 lst-kix_b25d3gwca8pf-0 start">
                <li class="c2 c5"><span class="c1">The right to be informed about the collection and use of personal data;</span></li>
                <li class="c2 c5"><span class="c1">The right to access the personal data that we hold about you (see &quot;How can you access your personal data?&quot;);</span></li>
                <li class="c2 c5"><span class="c1">The right to correction if personal data that we have stored about you is inaccurate or incomplete (please contact us using the data provided under &quot;Contacting Us&quot;).</span></li>
                <li class="c2 c5"><span class="c1">The right to be forgotten - i.e. The right to ask us to delete all personal data that we have stored about you;</span></li>
                <li class="c2 c5"><span class="c1">The right to restrict (or prevent) the processing of your personal data in certain circumstances;</span></li>
                <li class="c2 c5"><span class="c1">The right to object to the use of your personal data for a specific purpose or purposes;</span></li>
            </ul>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">When making inquiries, note that the requested removal of certain information may mean that we can no longer provide you with all or certain parts of the services.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If you have a complaint about the use of your personal data, please contact us using the information in the &quot;Contact Us&quot; section. We will do our best to solve the problem for you.</span></p>
            <h1 class="c6" id="h.ryf5ub7zdo74"><span class="c0">INFORMATION WE CAN COLLECT</span></h1>
            <p class="c2"><span class="c1">When you visit, browse, or use the Services, we may collect certain information from you. Some of this information may be personal information and some of the information may be anonymous information. We use your data in accordance with the provisions of this Privacy Notice.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Your WebAr.space account and your transaction data</span></p>
            <p class="c2"><span class="c1">If you create a Web-Ar.space account or use the services, others, including Web-Ar.space, can identify you. In order to open a Web-Ar.space account or use the services, you may have to provide us with some or all of the following personal data: first and last name, billing address, email address and password.</span></p>
            <p class="c2"><span class="c1">We collect and store the transaction data in relation to your web space account,</span></p>
            <p class="c2"><span class="c1">e.g. Registration information, registration attempts and payment status.</span></p>
            <p class="c2"><span class="c1">We do not save:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_d6rm3cg9qq8g-0 start">
                <li class="c2 c5"><span class="c1">User passwords (we only store PBKDF2 hashes of passwords for comparison when trying to log in);</span></li>
                <li class="c2 c5"><span class="c1">Full payment card numbers (these are saved by our payment service provider &ldquo;Stripe&rdquo;).</span></li>
            </ul>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If you choose to send us an email or letter in any other way, we may also collect information about you from the content of your letter, email, or transmission.</span></p>
            <h1 class="c6" id="h.2u8kqbl60mwa"><span class="c0">Your content (private use)</span></h1>
            <p class="c2"><span class="c1">As soon as your access to the services has been activated, you can upload content (e.g. music files, images, 3D models) to your WebAr.space account and publish content and make it available to the public (e.g. publication of the AR- Links on your website, issue of Web A -QR codes). Once entered or uploaded, your content is stored on our secure servers to make your content available as soon as you want to publish it.</span></p>
            <p class="c2"><span class="c1">Before you enter, upload or publish content in the services, please note the following important points:</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">Due to the nature of service (the purpose of use), your content can be available to all users who hold the AR trigger (Web AR Link or the Web AR QR code) in their hands, regardless of whether they are known to you or you have been specifically selected to receive this content.</span></p>
            <p class="c2"><span class="c1">It is not possible to share your content privately, i.e. only to make it accessible to certain selected people. However, you can choose who to share your AR triggers with. Please be careful when posting content that contains or presents personal information about yourself or others, e.g. audio or photos of other people / friends.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">We strongly recommend that you selectively choose which personal information to include in your content. Under no circumstances should you provide the following personal information to Web-Ar.space: telephone numbers, addresses, full names, location information or other sensitive personal information.</span></p>
            <p class="c2"><span class="c1">Please also make sure that your content uploads comply with our Code of Conduct (see Section 11 of the Terms of Service).</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">If you enter or upload information about / from someone other than yourself (e.g., a photo of a friend or colleague), you must first obtain their permission.</span></p>
            <p class="c2"><span class="c1">Even if you remove content that you have published via Web-Ar.space, copies may remain cached or stored on another person&#39;s device (e.g. cache of the browser) and can therefore still be displayed.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">It is also important to note that you and not Web-Ar.space are responsible for the uploaded content, and that you and not Web-Ar.space are responsible for reviewing your uploaded content. If your content contains personal information, Web-Ar.space only acts as a data processor for the purpose of the European General Data Protection Regulation (GDPR) and not as a data controller.</span></p>
            <p class="c2"><span class="c1">Information from external sources</span></p>
            <p class="c2"><span class="c1">From time to time, we may use the services of other companies to carry out certain tasks in connection with the services of Web-Ar.space. This can include, for example, payment processing, storage and provision of content, advertising and marketing, as well as analyzes. These companies may send us certain personal information that concerns you. We may also collect information about you from publicly available sources, such as the Internet and social networks, including through public or licensed APIs. If we collect such information from third parties, we will only use this information in accordance with this privacy policy. These companies can also collect personal data directly from you. In this case, this company is the data controller of the information provided by you, and not web address space (e.g. your credit card details). You may want to review their privacy policies to find out how they use your information.</span></p>
            <h1 class="c6" id="h.bw2pjxpnvq8o"><span class="c0">Payment processing</span></h1>
            <p class="c2"><span class="c1">Web-Ar.space uses the payment solution provided by Stripe (https://stripe.com/) to be able to accept payments for the use of Web-Ar.space. The payment solution from Stripe is integrated in our system and works as follows: Upon your payment request, you will be forwarded to a website operated by Stripe, where you can leave your required payment details. If you submit the form on the Stripe website, the payment details and other data will be sent directly to Stripe. This method prevents your confidential data from ever being on our servers. Only the card provider and the last 4 digits of the card number are visible to Web-Ar.space.</span></p>
            <h1 class="c6" id="h.egleb6m8jv5w"><span class="c0">LEGAL BASIS FOR THE PROCESSING OF PERSONAL DATA</span></h1>
            <p class="c2"><span class="c1">The legal basis for the collection and use of your personal data depends on the personal data concerned and the specific context in which we collect them. However, we normally only collect personal data from you if (a) we need the personal data to conclude a contract with you; (b) the processing is in our legitimate interest and does not displace any of your rights; (c) we are legally obliged to process your personal data; or (d) we have your consent to this (e.g. you have agreed (opt-in) to receive our newsletter).</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">The &ldquo;fulfillment of the contract&rdquo; of the Web-Ar.space terms of use serves as the legal basis for the processing of your personal data if we provide you with the Web-Ar.space service or provide customer support.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">We process your personal data on the basis of our &quot;legitimate interests&quot;, e.g. B. if we provide you with the services, keep the services safe for technical purposes, analyze the user behavior in the services, provide customer support, and improve and further develop the service of Web-Ar.space.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">We are &ldquo;legally obliged&rdquo; to process your personal data if we comply with a legal obligation, e.g. B. Prevention of crime or fraud. Our obligation of the European General Data Protection Regulation (GDPR) to protect your personal data may also be subject to this.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If you have any questions about the legal basis for the collection and use of your personal data, please contact us using the contact information in the &quot;Contact Us&quot; section.</span></p>
            <h1 class="c6" id="h.gga55kwukg8z"><span class="c0">HOW DOES WEB-AR.SPACE USE YOUR DATA?</span></h1>
            <p class="c2 c8"><span class="c1"></span></p>
            <a id="t.0ed52c6a82222183fdf43b74f92d47bffa19a7b3"></a>
            <a id="t.0"></a>
            <table class="c20">
                <tbody>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c7">Purpose</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c2"><span class="c7">Legal basis for the processing of personal data</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">To provide you with the full functionality of the Web-Ar.space service.</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Fulfillment of contract - If you do not provide the requested personal data, we cannot identify you as an authorized user.</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">To upload, enter, change, edit and publish your content.</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Fulfillment of contract - If you do not provide the requested personal data, we cannot identify you as an authorized user and carry out the requested actions.</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Provision of personalized content and communications that you would like to receive from Web-Ar.space in relation to the services of Web-Ar.space</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Legitimate interests - marketing; Provision of the services of Web-Ar.space and execution of the actions requested by you.</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Providing promotional emails, newsletters and other messages relating to the services of Web-Ar.space, including communication by third parties.</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Legitimate interests - marketing; Provision of the services of Web-Ar.space and execution of the actions requested by you.</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Distribution of advertising and promotional materials from Web-Ar.space to users of Web-Ar.space.</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Legitimate interests - marketing and advertising for the services of Web-Ar.space.</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">To provide customer service related to your use of the service, e.g. service updates, responding to service requests.</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Fulfillment of contract or legitimate interests - If no contract is in force, the &quot;legitimate interest&quot; offers customer support and product support</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Generally to manage, support, analyze, improve, and develop the services of Web-Ar.space</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Legitimate interests - provision of the services, operation and improvement of the services of Web-Ar.space.</span></p>
                            <p class="c2 c8"><span class="c1"></span></p>
                            <p class="c2"><span class="c1">To understand how users behave in our system and derive improvement strategies from them.</span></p>
                            <p class="c2 c8"><span class="c1"></span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Detect and prevent fraud</span></p>
                            <p class="c2"><span class="c1">in case of security or technical problems.</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Legal obligation, e.g. Compliance with the data protection law.</span></p>
                            <p class="c4 c8"><span class="c1"></span></p>
                            <p class="c4"><span class="c1">Our legitimate interest in keeping our platform secure.</span></p>
                            <p class="c4 c8"><span class="c1"></span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">For the enforcement of the terms of use of Web-Ar.space and for handling complaints, legal claims or legal proceedings in connection with the services of Web-Ar.space.</span></p>
                        </td>
                        <td class="c14" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">To exercise, implement, or defend our rights, or to protect your material interests, or those of other people.</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="c2 c8"><span class="c1"></span></p>
            <h1 class="c6" id="h.rrxgu8o6mxp1"><span class="c0">MARKETING COMMUNICATION</span></h1>
            <p class="c2"><span class="c1">We would like to send you information about Web-Ar.space services that may be of interest to you. This may include marketing communications on social media platforms you use (including Facebook). If at any time you decide that you no longer wish to be contacted for this purpose, you can unsubscribe by either unsubscribing from the receipt of information in the message we have received or by telling us and we will promptly contact you Remove mailing list. Please note that it is not possible to deactivate emails necessary for the Web-Ar.space service.</span></p>
            <h1 class="c6" id="h.1edkb0bn21k1"><span class="c0">WHERE IS YOUR DATA PROCESSED?</span></h1>
            <p class="c2"><span class="c1">Your personal data can be transferred to and processed in countries other than the country in which you live. These countries may have data protection laws that are different from the laws of your country.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">We use Microsoft Azure to store and process the data on our behalf in connection with our services. We use Microsoft Azure Server in France to store and process your information. However, Microsoft Azure Server uses different servers around the world to cache data locally, thereby speeding up access to content.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">We can commission other companies to process data on our behalf. For example, we may hire third parties to provide payment services, host the services, manage email on our behalf, or provide analytics services. We continue to act as the data controller with regard to the personal data that are transferred to such third party providers and remain responsible for the processing they undertake.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Below is a list of companies that are currently processing data related to the services on our behalf:</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <a id="t.4228211df7dcce9b61076403c9a45da0a19604c0"></a>
            <a id="t.1"></a>
            <table class="c18">
                <tbody>
                    <tr class="c10">
                        <td class="c19" colspan="1" rowspan="1">
                            <p class="c2"><span class="c7">Name</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c7">Processing purpose(s)</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c2"><span class="c7">Data Storage Location</span></p>
                        </td>
                    </tr>
                    <tr class="c12">
                        <td class="c19" colspan="1" rowspan="1">
                            <p class="c2"><span class="c1">Microsoft Azure</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Backup and distribution and content;</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">The primary location is France, but content can be cached locally to improve content delivery performance</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c19" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Stripe</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Payments for Web-Ar.space;<br/>Subscription management for Web-Ar.space</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">May store data within the EU and in other countries worldwide.</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c19" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Sendgrid</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Customer support and newsletter</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">worldwide</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c19" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">World4You</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Customer support requests, customer support manual sending</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Austria</span></p>
                        </td>
                    </tr>
                    <tr class="c10">
                        <td class="c19" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Google</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">Analytics services</span></p>
                        </td>
                        <td class="c9" colspan="1" rowspan="1">
                            <p class="c4"><span class="c1">worldwide</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h1 class="c6" id="h.qwqj7du72qiu"><span class="c0">TRANSFERS OUTSIDE OF EUROPE</span></h1>
            <p class="c2"><span class="c1">If you use the Web-Ar.space services outside the European Economic Area (EEA), your personal data may possibly be transferred to countries outside the EEA.</span></p>
            <p class="c2"><span class="c1">This could happen in the following circumstances:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If one of our third party providers is located outside the EEA (see table above).</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If you use the Web-Arr.space services. The Web-Ar.space services can be used internationally. This means that all personal data contained in your content (e.g. a photo) that you publish on Web-Ar.space (e.g. publication of the AR links on your website, output of the Web AR QR codes) by our servers can be automatically transferred to a smartphone, a server or other computer devices that are used in a country outside the EEA.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Countries outside the EEA may not have similar data protection laws to the European General Data Protection Regulation (&quot;GDPR&quot;) and the Austrian Data Protection Act. If we transfer your personal data outside the EEA (e.g. to a data processor outside the EEA), we will take all reasonable steps to ensure that your personal data is adequately protected by: (a) using only processors, that meet the requirements of EU data protection laws; (b) use only processors who use the European Commission&#39;s standard contractual clauses; and (c) (in the case of transfers to the United States) only use processors that are certified under the EU-US Privacy Shield.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">By using the Web-Ar.space services, you expressly agree to the international broadcasts mentioned in this section.</span></p>
            <h1 class="c6" id="h.9aj2uyv5m5qu"><span class="c0">WHEN COULD WEB-AR.SPACE SHARE YOUR INFORMATION WITH OTHERS?</span></h1>
            <p class="c2"><span class="c1">We can share your information with the following types of recipients:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_z098l3aw21cj-0 start">
                <li class="c2 c5"><span class="c1">Users to whom you provide Web AR-Links Web AR-QR Codes.</span></li>
                <li class="c2 c5"><span class="c1">Social media services with which you share your information;</span></li>
                <li class="c2 c5"><span class="c1">Third parties whose processing is necessary for the provision of the Web-Ar.space services, including but not limited to our payment service provider and our server provider;</span></li>
                <li class="c2 c5"><span class="c1">other companies that we have commissioned to provide services on our behalf, e.g. Provider of analysis and customer relationship management tools;</span></li>
            </ul>
            <ul class="c13 lst-kix_rauqs7zaj6jk-0 start">
                <li class="c2 c5"><span class="c1">Third parties where we believe disclosure is necessary to protect rights, property, users and the public. This can include fraud protection and spam prevention;</span></li>
                <li class="c2 c5"><span class="c1">any person where we believe disclosure is necessary so that we can enforce our rights under this privacy policy or the terms of use of Web-Ar.space services or defend legal claims in relation to Web-Ar.space;</span></li>
                <li class="c2 c5"><span class="c1">potential buyers of Web-Ar.space, its parent company or the services (see below); and</span></li>
                <li class="c2 c5"><span class="c1">other external parties (e.g. judicial, government or regulatory agencies) to comply with the law and our legal obligations.</span></li>
            </ul>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Under no circumstances we sell, trade or rent your personal data to any third parties.</span></p>
            <h1 class="c6" id="h.h9uwxt4k84fa"><span class="c0">CHANGES OF BUSINESS OWNERS AND CHANGES OF CONTROL </span></h1>
            <p class="c2"><span class="c1">Web-Ar.space may choose to expand or reduce our business, and this may include the sale and / or transfer of management through Web-Ar.space or the services in whole or in part. Information provided by users, insofar as it is relevant to part of our business so transferred, is transferred together with that part as one of the transferred assets. The new owner or party may use your information in accordance with the terms of this privacy notice for the purposes for which it was originally provided to us.</span></p>
            <h1 class="c6" id="h.owor67cbzzat"><span class="c0">LINK TO NON-WEB-AR.SPACE SERVICES</span></h1>
            <p class="c2"><span class="c1">The services may contain links to resources, websites, microsites and other online services that are operated by third parties, i.e. companies other than Web-Ar.space. We do not control the services of third parties and are in no way responsible for their content or information collection practices. This Privacy Notice does NOT extend to your use of third party services.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <h1 class="c6" id="h.ergzer1uu7mh"><span class="c0">HOW DO WE PROTECT YOUR PERSONAL INFORMATION?</span></h1>
            <p class="c2"><span class="c1">Data security is of great importance for Web-Ar.space. We have taken economically reasonable physical, electronic and administrative security measures to protect your personal data from access by unauthorized persons and from unlawful processing, accidental loss, destruction and damage. In particular, we use the following measures:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <ul class="c13 lst-kix_r4klug2zejlp-0 start">
                <li class="c2 c5"><span class="c1">The information you send us is protected by SSL / TLS end-to-end encryption when it is transferred from your device to our servers.</span></li>
                <li class="c2 c5"><span class="c1">Information stored on our servers is protected by access controls, key authentication and firewalls. We use encryption as an additional security measure if we consider it appropriate. Currently, content uploaded to the services for editing and publishing is not encrypted when idle.</span></li>
                <li class="c2 c5"><span class="c1">Your data is only accessed by authorized employees. These employees are obliged to maintain the confidentiality of all information that they become aware of, unless the disclosure is required by law or required to fulfill obligations.</span></li>
                <li class="c2 c5"><span class="c1">Your WebAr.space account is protected by access authentication. We use cookies to check whether the person accessing an account is authorized to do so. Passwords are saved using the hashing technique.</span></li>
            </ul>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Despite these measures, you should be aware that we cannot completely eliminate security risks related to the online transfer of personal data. No method of transmission over the Internet or electronic storage is 100% secure. Therefore we cannot guarantee absolute security.</span></p>
            <h1 class="c6" id="h.s1aka0908l9a"><span class="c0">HOW LONG DO WE STORE YOUR PERSONAL DATA? </span></h1>
            <p class="c2"><span class="c1">We will keep your personal data for the duration of your use of the services of Web-Ar.space and for a reasonable period thereafter for backup, archiving and / or verification purposes or for as long as the law stipulates otherwise. In particular, we are obliged under EU law to keep your IP address and billing address for 10 years in order to confirm compliance with the VAT rules. If we archive your content after deactivating your Web-Ar.space account, we reserve the right to delete this content at any time afterwards.</span></p>
            <h1 class="c6" id="h.9envl4bci7qg"><span class="c0">HOW CAN YOU ACCESS YOUR PERSONAL DATA?</span></h1>
            <p class="c2"><span class="c1">You have the right to request a copy of the personal data we have stored about you (provided that this data is actually stored by us and we can identify who you are). Usually we will provide you with copies free of charge upon request. However, we reserve the right to charge a reasonable fee for requests that are obviously unfounded or excessive, particularly if they are repeated or if additional copies of the same information are concerned.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">We will ask you to provide adequate proof of your identity before we disclose any information to you.</span></p>
            <h1 class="c6" id="h.yvtoqw658h1z"><span class="c0">COOKIES, ANALYTICS AND SIMILAR TECHNOLOGIES</span></h1>
            <p class="c2"><span class="c1">On our separate page on cookies you will find information on how the services use cookies, analysis and similar technologies.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If our use of cookies includes the processing of personal data, the legal basis for this is in our legitimate interest, for example to help us to offer you a good experience when using the services (including their security) and to improve the services, by understanding how to use them.</span></p>
            <h1 class="c6" id="h.9orab71jtyoj"><span class="c0">CHANGES TO OUR PRIVACY POLICY </span></h1>
            <p class="c2"><span class="c1">Our services, the collection and use of personal data and other information, as well as the applicable laws and regulations will change over time. We reserve the right to make changes to this privacy policy. If we update the Privacy Notice, we will publish the new version on the Web-Ar.space website and change the date of the last update. If such changes to the privacy policy are material, we can send you a separate notification. We encourage you to visit this page from time to time for the latest information.</span></p>
            <p class="c2"><span class="c1">Contact to Web-Ar.space</span></p>
            <p class="c2"><span class="c1">If you have any questions about the services or this Privacy Notice from Web-Ar.space, please contact us by email or post. You can find the contact details below. Please ensure that your request is clear, especially if it is a request for information about the personal data that we have stored about you.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Postal address: data protection, twospot GmbH. Mariahilferstrasse 118/33, 1070 Vienna, Austria</span></p>
            <p class="c2"><span class="c1">Email: support@web-ar.space</span></p>
            <h1 class="c6" id="h.9dyycsmtc3qa"><span class="c0">APPLICABLE LAW</span></h1>
            <p class="c2"><span class="c1">Regardless of the country in which you live or submit information from the law applicable to this Privacy Notice, Austrian law applies.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c15 title" id="h.8bttj3ekti7e"><span class="c17">III. Web-Ar.space Data Processing Addendum</span></p>
            <p class="c2"><span class="c1">Last updated: March 32, 2020</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">This addendum to the Data Processing Addendum(&ldquo;DPA&rdquo;) supplements and is part of the Web-Ar.space user agreement for users from business and education. Capitalized terms that are used in this privacy policy but are not defined are defined in the user agreement.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h1 class="c6" id="h.m2y7cbd76q13"><span class="c0">PART 1: INTRODUCTION</span></h1>
            <h3 class="c3" id="h.h57kkarjnr8d"><span class="c0">Purpose</span></h3>
            <p class="c2"><span class="c1">The DPAstipulates the conditions that apply if personal data is processed by Web-Ar.space on behalf of an account holder as part of the services, if the account holder acts as a data controller within the meaning of the data protection laws or regulations of Austria or the EU, including the European General Data Protection Regulation (GDPR) (collectively the &quot;Data Protection Laws&quot;). It applies only to the extent required by law and entered into force on May 25, 2018.</span></p>
            <h3 class="c3" id="h.llrrney5zk6t"><span class="c0">Effect of the DPA</span></h3>
            <p class="c2"><span class="c1">If a provision of the DPA contradicts a provision of the user agreement, this DPA has priority. The user agreement remains in full force and unchanged, unless it is changed by this DPA. This DPA ends automatically when the user agreement expires or ends.</span></p>
            <h3 class="c3" id="h.kl1duhp0xew7"><span class="c0">Acceptance</span></h3>
            <p class="c2"><span class="c1">If the user is a contracting party to an effective user agreement, this Data Processing Addendumautomatically becomes effective between the parties. At the request of the account holder, the parties can also conclude a printed version of this Privacy Notice, which is physically signed by or on behalf of both parties. The parties can also enter into an individually negotiated data processing agreement by mutual agreement, provided that this agreement meets the minimum requirements set out in data protection laws.</span></p>
            <h3 class="c3" id="h.8qvibiqjy3m9"><span class="c0">Defined terms</span></h3>
            <p class="c2"><span class="c1">regarding this order processing contract:</span></p>
            <ul class="c13 lst-kix_hpve8kbgufb6-0 start">
                <li class="c2 c5"><span class="c1">The terms &quot;controller&quot;, &quot;data subject&quot;, &quot;processor&quot;, &quot;personal data breach&quot; and &quot;processing&quot; have the same meaning as in Article 4 of the GDPR. and</span></li>
            </ul>
            <p class="c2 c8"><span class="c1"></span></p>
            <ul class="c13 lst-kix_a1agas81uyq7-0 start">
                <li class="c2 c5"><span class="c1">&quot;Personal data&quot; is all user content of the account holder that is or contains information that relates to an identified or identifiable natural person, provided that this information is protected as personal data in accordance with data protection laws. This does not include personal data for which Web-Ar.space is the sole data controller within the meaning of data protection laws.</span></li>
            </ul>
            <h1 class="c6" id="h.bt13s21mcsmq"><span class="c0">PART 2: DETAILS OF PROCESSING</span></h1>
            <p class="c2"><span class="c1">Details of the role of Web-Ar.space as a processor of personal data are:</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c7">Subject of the processing of personal data:</span></p>
            <p class="c2"><span class="c1">The provision of the services by Web-Ar.space for the account holder and his authorized users.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c7">Duration of processing personal data:</span></p>
            <p class="c2"><span class="c1">During the term of the user agreement and the time between the expiry of the user agreement and the deletion of all personal data contained in user content by Web-Ar.space.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c7">Type and purpose of processing personal data:</span></p>
            <p class="c2"><span class="c1">So that the account holder and authorized users of Web-Ar.space can receive and provide the services of Web-Ar.space, including the publication, hosting and provision of user content on the entire technology platform of Web-Ar.space.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c7">Categories of personal data:</span></p>
            <p class="c2"><span class="c1">In general, this can consist of identifying information and organizational data of the customers and end users of the account holder as well as personal data of the data subjects contained in user content, such as images, videos, voices; including other categories agreed by the parties and recorded in writing. It is confirmed that Web-Ar.space does not in any way allow the services of Web-Ar.space to be used to process one of the &quot;special categories of personal data&quot; mentioned in Article 9 (1) of the GDPR.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c7">Categories of data subjects:</span></p>
            <p class="c2"><span class="c1">If user content contains personal data, it may affect end users, customers, employees, business contacts, members of the public and other persons of the account holder, they must have agreed that their personal data, which data is contained in the user content of the account holder. </span></p>
            <h1 class="c6" id="h.5mrlee7gd5d0"><span class="c0">PART 3: DATA PROCESSING CONDITIONS </span></h1>
            <h3 class="c3" id="h.cq69ktajtwoo"><span class="c0">1. The roles of the parties</span></h3>
            <p class="c2"><span class="c1">If Web-Ar.space processes personal data as part of the provision of the services, this is only done as a processor who acts on behalf of the account holder (as data controller) and in accordance with the requirements of this DPA.</span></p>
            <h3 class="c3" id="h.ww8gkku7tqj9"><span class="c0">2. Scope of processing</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space undertakes to process the personal data only on the documented instructions of the account holder, also with regard to the transfer of personal data to a third country or an international organization, unless Web-Ar.space is according to EU or obliged to do so under national law; In this case, Web-Ar.space informs the account holder of these legal requirements before processing, unless this law prohibits such information for important reasons of public interest. For the purposes of this clause, the services requested by the account holder or his authorized users and the resulting processing are accepted as documented instructions to Web-Ar.space.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">Web-Ar.space will immediately inform the account holder if, in the opinion of Web-Ar.space, instructions from the account holder or on behalf of the account holder would violate the GDPR or other data protection regulations of the European Union or the member states.</span></p>
            <h3 class="c3" id="h.y6cr6rsxn2w1"><span class="c0">3. Obligations of the account holder</span></h3>
            <p class="c2"><span class="c1">The account holder as the data controller is responsible for:</span></p>
            <p class="c2"><span class="c1">(a) has complied with and continues to comply with all applicable data protection and privacy laws, including data protection laws; and (b) he also has the right to transfer or access the personal data to Web-Ar.space for processing in accordance with the provisions of the user agreement and this DPA.</span></p>
            <p class="c2"><span class="c1">The account holder bears sole responsibility for the correctness, quality and legality of the personal data, as well as for the way in which the account holder acquired the personal data.</span></p>
            <h3 class="c3" id="h.bo1ic3uqdbnu"><span class="c0">4. Confidentiality</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space ensures that persons who have been authorized by Web-Ar.space to process personal data have committed themselves to confidentiality or are subject to an appropriate legal obligation to maintain confidentiality.</span></p>
            <h3 class="c3" id="h.sfmdycj3bt4l"><span class="c0">5. Security</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space must take and maintain suitable technical and organizational measures to protect personal data during the entire term of this DPAin accordance with the requirements of Article 32 of the GDPR. </span></p>
            <h3 class="c3" id="h.5fi9z7qfj57l"><span class="c0">6. Rights of the data subjects</span></h3>
            <p class="c2"><span class="c1">If possible, Web-Ar.space declares that the account holder, in order to fulfill the obligations of the account holder to answer inquiries from data subjects and to exercise their rights set out in Chapter III of the GDPR, adequate support through suitable technical and organizational measures to be granted insofar as the costs are borne by the account holder. If an inquiry is sent directly to Web-Ar.space, Web-Ar.space must inform the account holder immediately.</span></p>
            <h3 class="c3" id="h.opr96yn1rkk2"><span class="c0">7. Other support</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space will notify the account holder immediately after Web-Ar.space has become aware of a personal data breach if it concerns personal data that are the subject of this DPA.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">Web-Ar.space provides the account holder with adequate support in complying with the obligations under Articles 32 to 36 of the GDPR, taking into account the processing of the information available to Web-Ar.space. The account holder bears the costs.</span></p>
            <h3 class="c3" id="h.m5r1voro6ji8"><span class="c0">8. Deletion / return of personal data</span></h3>
            <p class="c2"><span class="c1">After the expiration or termination of the user agreement, the account holder has the choice that Web-Ar.space returns or deletes all relevant personal data and also deletes all existing copies of personal data, unless Web-Ar.space is legally obliged to do so some or to save all personal data.</span></p>
            <h3 class="c3" id="h.v4rie12h6xic"><span class="c0">9. Examination rights</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space will provide the account holder with all information that is reasonable to demonstrate compliance with the obligations set out in Article 28 of the GDPR. While the parties typically intend to rely on the provision of documents confirming Web-Ar.space&#39;s compliance with this DPA, Web-Ar.space allows the account holder (or his appointed external auditor who is not a competitor of Web-Ar .space), an examination of the processing of personal data by Web-Ar.space, in compliance with the user agreement, including a subsequent personal data breach by Web-Ar.space, or on the instruction of a data protection authority. The account holder must inform Web-Ar.space in good time of such an intention to check, this check must take place during normal business hours and take all reasonable measures to avoid unnecessary malfunctions of Web-Ar.space. Such a check is subject to the security and confidentiality provisions and guidelines of Web-Ar.space. The account holder compensates Web-Ar.space for any costs incurred for such a check, at the fees offered by Web-Ar.space. These can be made available to account holders on request. The exam fees take into account the resources (or expenditures) used by Web-Ar.space and are agreed by the parties, if possible, before the start of the exam. If Web-Ar.space refuses to follow the instructions regarding checking requested by the account holder, the account holder is entitled to terminate these DPAand the user agreement.</span></p>
            <h3 class="c3" id="h.5retvdq6kn8b"><span class="c0">10. Use of sub-processors</span></h3>
            <p class="c2"><span class="c1">The account holder agrees that Web-Ar.space commissions sub-processors from third parties (collectively &ldquo;sub-processors&rdquo;) to process the personal data on behalf of the account holder. Web-Ar.space imposes obligations on each sub-processor to protect the personal data in accordance with the same or essentially similar standard that is provided in this GCU, and continues to be liable for violations of the GCU by a sub-processor caused. WebAr.space may add or change the sub-processors through appropriate notice. If the account holder objects to a proposed change for reasonable reasons (e.g. if the provision of personal data for the subcontractor violates applicable data protection laws or weakens the protection of personal data), he must submit WebAr.space within 14 calendar days , notify after the date of notification by Web-Ar.space. This notice must provide a reasonable opportunity to object. Upon receipt of the account holder&#39;s notification, the parties will do their best to find an alternative solution. If the parties are unable to find a mutually acceptable alternative solution within a reasonable period of 30 calendar days or less and Web-Ar.space is unable to continue providing the services to the account holder without using the contested solution , the parties must be able to terminate the user agreement at any time by giving written notice to the other party.</span></p>
            <h3 class="c3" id="h.dj6h05k23nni"><span class="c0">11. Amendment</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space can update or change this DPA at any time, in whole or in part, by publishing a new version on Web-Ar.space, provided the updated DPA continues to meet the minimum requirements set out in data protection laws. If Web-Ar.space makes changes, the date of the last update will be replaced by the date of the latest version.</span></p>
            <h3 class="c3" id="h.rpqqi0dee4ap"><span class="c0">12. Applicable law</span></h3>
            <p class="c2"><span class="c1">This DPA is subject to the laws of Austria and is interpreted in every respect in accordance with them.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c15 title" id="h.wmuizarghoo7"><span class="c17">IV. WebAr.space User Agreement</span></p>
            <p class="c2"><span class="c1">Last update: March 31, 2020</span></p>
            <h1 class="c6" id="h.789zji9lah6g"><span class="c0">SUBSCRIPTIONS - PRIVATE USE</span></h1>
            <p class="c2"><span class="c1">Provided that you meet our conditions of participation, register an account with us, use the free plan, or pay a subscription, Web-Ar.space/twospot GmbH grants you (&quot;Web-Ar.space&quot;, &quot;we&quot; or &quot;us&quot; ) a personal, non-exclusive, non-transferable and limited right to access and use Web-Ar.space during the subscription period, in accordance with the terms of this user agreement and our general terms of use for Web-Ar.space (the &ldquo;Terms of Use&rdquo; ) contained conditions.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Please also take the time to read the following all important points of the overall document that relate to the use of Web-Ar.space.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">They are referred to below as &quot;Web Ar.space guidelines&quot;.</span></p>
            <p class="c2"><span class="c1">&nbsp; </span></p>
            <p class="c2"><span class="c1">PLEASE READ THIS USER AGREEMENT AND THE WEB-AR.SPACE GUIDELINES CAREFULLY BEFORE REGISTERING, ACCESSING AND / OR USING WEB-AR.SPACE.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">WEB-AR.SPACE AND ALL ITS ELEMENTS ARE PROTECTED BY COPYRIGHTS, BRAND RIGHTS AND OTHER INTELLECTUAL PROPERTY RIGHTS. ANY SOFTWARE PROVIDED WITH WEB-AR.SPACE IS LICENSED TO YOU ONLY FOR USE UNDER THIS USER AGREEMENT DURING THE SUBSCRIPTION TERMS. NOT SOLD TO YOU.</span></p>
            <p class="c2"><span class="c1">BY REGISTERING WITH WEB-AR.SPACE, ACCESSING OR OTHERWISE USING WEB-AR.SPACE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS USER AGREEMENT. IF YOU DO NOT AGREE TO THIS USER AGREEMENT, YOU MAY NOT USE WEB-AR.SPACE. THIS USER AGREEMENT PROVIDES A LEGAL AGREEMENT BETWEEN YOU AND WEB-AR.SPACE (twospot GmbH.) FOR THE PROVISION OF WEB-AR.SPACE DAR. THIS USER AGREEMENT REPLACES AND REPLACES ANY PREVIOUS, AGREEMENTS OR RELATIONSHIPS THAT YOU MAY HAVE WITH WEB-AR.SPACE (twospot GmbH.) IN RELATION TO WEB-AR.SPACE.</span></p>
            <h3 class="c3" id="h.yzpweme4ipth"><span class="c0">Summary of Terms of use </span></h3>
            <p class="c2"><span class="c1">Some of the most important terms of use that apply to your use of Web-Ar.space are summarized below. The summary does not purport to be exhaustive and does not replace reading the full version of the terms of use and the Web-Ar.space guidelines.</span></p>
            <h3 class="c3" id="h.qephl2lrugbx"><span class="c0">1. Defined terms</span></h3>
            <p class="c2"><span class="c1">All terms used in this user agreement have the meaning given in the terms of use, unless otherwise defined here.</span></p>
            <h3 class="c3" id="h.bcm51ulp0yij"><span class="c0">2. Data protection and cookies</span></h3>
            <p class="c2"><span class="c1">If you register to use Web-Ar.space, we may collect personal data from you, e.g. Name, email address and payment details. This information is used to set up your user account, to manage your subscription and to grant you access to the features and functions of Web-Ar.space. Web-Ar.space also uses cookies and similar technologies so that the services function properly and can be analyzed. Further information on how Web-Ar.space uses your personal data and cookies can be found in the Privacy Notice and the cookie notice published on the Web-Ar.space website.</span></p>
            <h3 class="c3" id="h.awvwf6i18art"><span class="c0">3. Restrictions on use and user content</span></h3>
            <p class="c2"><span class="c1">Your use of Web-Ar.space must be within the framework of the access conditions set out in section 6.1 of the terms of use and subject to the restrictions set out in section 6.3 of the terms of use. Your use of Web-Ar.space is also subject to a fair use policy, which you can find in section 13 of the terms of use. Private users may not use Web-Ar.space for commercial, commercial or professional purposes.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">With Web-Ar.space, users can link their content with AR triggers (Web AR links or Web AR QR codes). This means that the content associated with an AR trigger is publicly available for viewing by all users who have the AR trigger. We therefore request all users of Web-Ar.space to comply with the rules for the submission of content set out in sections 9, 10 and 11 of the terms of use.</span></p>
            <h3 class="c3" id="h.c1lx4otvr3wm"><span class="c0">4. Duration</span></h3>
            <p class="c2"><span class="c1">Your WebAr.space subscription for private use will continue until one of us terminates your subscription prematurely, as provided in section 15 of the terms of use. However, please note that AR triggers that are not used for more than 12 months may be disabled.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Private users can cancel their subscription at any time. Please note that your AR triggers will be deactivated if you cancel your subscription. In accordance with our Refund Policy, home users who cancel their plan will not receive a refund of the fees paid. This does not affect your statutory rights as a consumer (see below).</span></p>
            <h3 class="c3" id="h.tbcv39x9cc31"><span class="c0">5. Payment</span></h3>
            <p class="c2"><span class="c1">You agree to pay us the applicable fees for your use of Web-Ar.space, including the cost per view (CPV) and that we debit your credit, debit or other means of payment when payment is due. (Each subscription includes a certain number of calls per month, you will be informed if you exceed this.).</span></p>
            <h3 class="c3" id="h.iv1siwfa09dz"><span class="c0">6. DISCLAIMER, DISCLAIMER AND LIMITATION</span></h3>
            <p class="c2"><span class="c1">YOUR USE OF WEB-AR.SPACE IS SUBJECT TO CERTAIN SERVICE DISCLAIMERS, INCLUDED IN SECTION 21 OF THE TERMS OF USE.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">THE TERMS OF USE ALSO CONTAIN PROVISIONS THAT EXCLUDE OUR LIABILITY FOR CERTAIN TYPES OF ECONOMIC OR RESULTING LOSSES, AND LIMIT OUR OVERALL LIABILITY FOR USERS OF WEB-AR.SPACE SERVICES. THESE EXCLUSIONS AND LIMITATIONS OF LIABILITY ARE INCLUDED IN SECTION 23 OF THE TERMS OF USE.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">YOUR LEGAL RIGHTS AS A CONSUMER ARE NOT AFFECTED BY THE ABOVE AND MAKE CARE OF THE FOLLOWING NOTES:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">ONLINE SERVICES</span></p>
            <p class="c2"><span class="c1">You can ask us to repeat or improve Web-Ar.space services if they have not been carried out with reasonable care and expertise, or to get money back if we cannot repeat or improve them. </span></p>
            <p class="c2"><span class="c1">If no price has been agreed in advance, we have to offer a reasonable price.</span></p>
            <p class="c2"><span class="c1">If a time has not been agreed in advance, the service must be carried out within a reasonable time. </span></p>
            <h3 class="c3" id="h.vso42bl4qsdb"><span class="c0">7. Applicable law and choice of jurisdiction</span></h3>
            <p class="c2"><span class="c1">Section 25 of the Terms of Use contains information on the law and case law governing your use of Web-Ar.space.</span></p>
            <h3 class="c3" id="h.7lr0zer7d5rm"><span class="c0">8. Contact details and VAT registration</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space is owned and operated by twospot GmbH, a limited liability company registered in Austria (company number FN 479858 z). You can find our contact details below in the section &ldquo;Contacting us&rdquo;. If you have any questions or complaints about Web-Ar.space, you can also contact us at the following addresses:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Postal address: twospot GmbH. Mariahilferstrasse 118/33, 1070 Vienna, Austria</span></p>
            <p class="c2"><span class="c1">Email: Support@web-ar.space</span></p>
            <h1 class="c6" id="h.guz21afiden7"><span class="c0">SUBSCRIPTION - COMMERCIAL USE</span></h1>
            <p class="c2"><span class="c1">This user agreement applies to natural and legal persons who use Web-Ar.space commercially.</span></p>
            <p class="c2"><span class="c1">Provided that you meet our conditions of participation, register an account with us, use the free plan, or pay a subscription, Web-Ar.space/twospot GmbH grants you (&quot;Web-Ar.space&quot;, &quot;we&quot; or &quot;us&quot; ) a personal, non-exclusive, non-transferable and limited right of access and use of Web-Ar.space, by you or your authorized users, during the subscription period, in accordance with this User Agreement, or the General Terms of Use for Web-Ar .space, the terms contained therein (the &ldquo;Terms of Use&rdquo;) and the Data Processing Addendum (DPA) which have been incorporated into this user agreement and are part of this user agreement.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Please also take the time to read the following all important points of the overall document that relate to the use of Web-Ar.space.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">They are referred to below as &quot;Web Ar.space guidelines&quot;.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">PLEASE READ THIS USER AGREEMENT AND THE WEB-AR.SPACE GUIDELINES CAREFULLY BEFORE REGISTERING, ACCESSING AND / OR USING WEB-AR.SPACE.</span></p>
            <p class="c2"><span class="c1">WEB-AR.SPACE AND ALL ITS ELEMENTS ARE PROTECTED BY COPYRIGHTS, BRAND RIGHTS AND OTHER INTELLECTUAL PROPERTY RIGHTS. ANY SOFTWARE PROVIDED WITH WEB-AR.SPACE IS LICENSED TO YOU ONLY FOR USE UNDER THIS USER AGREEMENT DURING THE SUBSCRIPTION TERMS. NOT SOLD TO YOU.</span></p>
            <p class="c2"><span class="c1">BY REGISTERING WITH WEB-AR.SPACE, ACCESSING OR OTHERWISE USING WEB-AR.SPACE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS USER AGREEMENT. IF YOU DO NOT AGREE TO THIS USER AGREEMENT, YOU MAY NOT USE WEB-AR.SPACE. THIS USER AGREEMENT PROVIDES A LEGAL AGREEMENT BETWEEN YOU AND WEB-AR.SPACE (twospot GmbH.) FOR THE PROVISION OF WEB-AR.SPACE DAR. THIS USER AGREEMENT REPLACES AND REPLACES ANY PREVIOUS, AGREEMENTS OR RELATIONSHIPS THAT YOU MAY HAVE WITH WEB-AR.SPACE (twospot GmbH.) IN RELATION TO WEB-AR.SPACE.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <h3 class="c3" id="h.mm2rj1dqv8se"><span class="c0">Summary of terms of use</span></h3>
            <p class="c2"><span class="c1">Some of the most important terms of use that apply to your use of Web-Ar.space are summarized below. The summary does not purport to be exhaustive and does not replace reading the full version of the terms of use and the Web-Ar.space guidelines.</span></p>
            <h3 class="c3" id="h.imdrly6r25d7"><span class="c0">1. Defined terms</span></h3>
            <p class="c2"><span class="c1">All terms used in this user agreement have the meaning given in the terms of use, unless otherwise defined here.</span></p>
            <h3 class="c3" id="h.qrj8rbt87phi"><span class="c0">2. Data protection and cookies</span></h3>
            <p class="c2"><span class="c1">If you register to use Web-Ar.space, we may collect personal data from you, e.g. Name, email address and payment details. This information is used to set up your user account, to manage your subscription and to grant you access to the features and functions of Web-Ar.space. Web-Ar.space also uses cookies and similar technologies so that the services function properly and can be analyzed. Further information on how Web-Ar.space uses your personal data and cookies can be found in the Privacy Notice and the cookie notice published on the Web-Ar.space website.</span></p>
            <h3 class="c3" id="h.j2kjx77zqpsz"><span class="c0">3. Registrations and authorized users</span></h3>
            <p class="c2"><span class="c1">Each subscription for commercial purposes purchased in your Web-Ar.space account allows a person of your choice (authorized user) to access the Web-Ar.space services.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">With regard to you and your authorized users, you agree to:</span></p>
            <ul class="c13 lst-kix_yjmwuiea5d2m-0 start">
                <li class="c2 c5"><span class="c1">Your authorized users will comply with the terms of use at all times during the subscription period.</span></li>
                <li class="c2 c5"><span class="c1">You must not allow a login to be used by more than one authorized user unless it has been fully assigned to another authorized user. In this case, the previous authorized user no longer has the right to access or use Web-Ar.space with this login;</span></li>
                <li class="c2 c5"><span class="c1">Each authorized user will keep a secure password for using Web-Ar.space, and each authorized user will keep their password confidential. and</span></li>
                <li class="c2 c5"><span class="c1">You authorize Web-Ar.space to review and monitor all usage of Web-Ar.space through your Web-Ar.space account to determine compliance with this User Agreement, and you agree to pay us the applicable fees for your use of Web-Ar.space, including the cost per view (CPV) and that we charge your credit, debit or other means of payment when payment is due. (Each subscription includes a certain number of calls per month, if you exceed this, we will inform you.)</span></li>
            </ul>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">You will make every reasonable effort to prevent unauthorized access to or use of Web-Ar.space through your Web-Ar.space account. If you become aware of such unauthorized access or use, you must notify us immediately.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">In the case of corporations, the rights provided for in this user agreement are only granted to the registered company and do not apply to subsidiaries, holding companies or affiliated companies of the registered registered company.</span></p>
            <h3 class="c3" id="h.96wr3dvkqady"><span class="c0">4. Restrictions on use and user content</span></h3>
            <p class="c2"><span class="c1">Your use of Web-Ar.space must be within the framework of the access conditions set out in section 6.1 of the terms of use and subject to the restrictions set out in section 6.3 of the terms of use. Your use of Web-Ar.space is also subject to a fair use policy, which you can find in section 13 of the terms of use. COMMERCIAL USERS, THEIR CALLS FROM AR-LINKS OR Web AR-QR-CODES THAT EXCEED CALLS INCLUDED IN THE SUBSCRIPTION, HAVE THE POSSIBILITY TO CONTACT US TO INCREASE THE CALLS.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">With Web-Ar.space, users can link their content with AR triggers (Web AR links or QR codes). This means that the content associated with an AR trigger is publicly available for viewing by all users who have the AR trigger. We therefore request all users of Web-Ar.space to comply with the rules for the submission of content set out in sections 9, 10 and 11 of the terms of use.</span></p>
            <h3 class="c3" id="h.bpfvkklvxpo0"><span class="c0">5. Subscription term</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space subscriptions remain in effect unless you or Web-Ar.space terminate the subscription as provided in Section 15 of the Terms of Use. All WebAr.space subscriptions are automatically renewed at the end of the billing period, unless the account holder ends / unsubscribes before the subscription is renewed.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Notwithstanding the above, AR triggers that are not used for more than 12 months can be disabled.</span></p>
            <h3 class="c3" id="h.wthz52hjzvyl"><span class="c0">6. Payment</span></h3>
            <p class="c2"><span class="c1">You agree to pay us the applicable fees for your use of Web-Ar.space, including the cost per view (CPV) and that we debit your credit, debit or other means of payment when payment is due. (Each subscription includes a certain number of calls per month, you will be informed if you exceed this.).</span></p>
            <h3 class="c3" id="h.jq6jf5ebgs9x"><span class="c0">7. DISCLAIMER, DISCLAIMER AND LIMITATION OF LIABILITY</span></h3>
            <p class="c2"><span class="c1">YOUR USE OF WEB-AR.SPACE IS SUBJECT TO CERTAIN SERVICE DISCLAIMERS, INCLUDED IN SECTION 21 OF THE TERMS OF USE.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">THE TERMS OF USE ALSO CONTAIN PROVISIONS THAT EXCLUDE OUR LIABILITY FOR CERTAIN TYPES OF ECONOMIC OR RESULTING LOSSES, AND LIMIT OUR OVERALL LIABILITY FOR USERS OF WEB-AR.SPACE SERVICES. THESE EXCLUSIONS AND LIMITATIONS OF LIABILITY ARE INCLUDED IN SECTION 23 OF THE TERMS OF USE.</span></p>
            <h3 class="c3" id="h.qfmzau57p2d5"><span class="c0">8. Applicable law and choice of jurisdiction</span></h3>
            <p class="c2"><span class="c1">Section 25 of the Terms of Use contains information on the law and case law governing your use of Web-Ar.space.</span></p>
            <h3 class="c3" id="h.owhgtah0aha3"><span class="c0">9. Contact details and VAT registration</span></h3>
            <p class="c2"><span class="c1">Web-Ar.space is owned and operated by twospot GmbH, a limited liability company registered in Austria (company number FN 479858 z). You can find our contact details below in the section &ldquo;Contacting us&rdquo;. If you have any questions or complaints about Web-Ar.space, you can also contact us at the following addresses:</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Postal address: twospot GmbH. Mariahilferstrasse 118/33, 1070 Vienna, Austria</span></p>
            <p class="c2"><span class="c1">Email: Support@web-ar.space</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c15 title" id="h.xy7tz0y8f3jx"><span class="c17">V. WebAr.space Content Policy</span></p>
            <p class="c2"><span class="c1">Last update: March 31, 2020</span></p>
            <h1 class="c6" id="h.9rl7vpqu0srm"><span class="c0">INTRODUCTION</span></h1>
            <p class="c2"><span class="c1">Web-Ar.space is a web augmented reality (Web-AR) platform. This Web-AR platform offers a number of services and functions for publishing (web) augmented reality experiences via a web browser. Contents (e.g. 3D models, sound, animations, pictures) can be uploaded and modified by different functions. This content is published in the form of an augmented reality link.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
            <p class="c2"><span class="c1">For twospot GmbH (&quot;Web-Ar.space&quot;, &quot;we&quot; or &quot;us&quot;) it is important that Web-Ar.space is used safely and responsibly. We have therefore published these provisions on user content in order to explain how we deal with allegations that data or content that is published or distributed by our users via Web-Ar.space (&quot;user content&quot;) violates the rights of third parties. This content policy is relevant for Web-Ar.space users as well as for rights holders and other third parties who may be affected by the use of Web-Arr.space by our users.</span></p>
            <h1 class="c6" id="h.79zkk245pabt"><span class="c0">WEB-AR.SPACE&rsquo;S RELATIONSHIP WITH RIGHT OWNERS </span></h1>
            <p class="c2"><span class="c1">We would like to draw your attention to the following properties of Web-Ar.space:</span></p>
            <p class="c2"><span class="c1">Web-Ar.space is not the author, publisher, poster or publisher of user content.</span></p>
            <p class="c2"><span class="c1">When offering the service, Web-Ar.space merely provides the underlying technology and infrastructure that users can use to create, publish, and make their own user content available by connecting them to AR triggers.</span></p>
            <p class="c2"><span class="c1">Web-Ar.space does not participate in any way in the first publication of user content that is made available via Web-Ar.space, and we do not carry out any editorial, content-related control or review of the user content.</span></p>
            <p class="c2"><span class="c1">The fact that a certain element of user content is available on our content delivery platform does not mean that Web-Ar.space approves that user content.</span></p>
            <p class="c2"><span class="c1">Web-Ar.space therefore always acts passively as an intermediary and we assume no responsibility for user content that is made available via Web-Ar.space.</span></p>
            <p class="c2"><span class="c1">Due to the characteristics of online services, it is possible for people to abuse WebAr.space by providing user content that violates rights. As part of our goal of promoting the safe and responsible use of Web-Ar.space, Web-Ar.space asks rights holders and other affected third parties to inform us of any alleged illegal use of Web-Ar.space, especially data or content that belongs to you.</span></p>
            <h1 class="c6" id="h.q6f17n95ysf9"><span class="c0">HOW CAN YOU REPORT ILLEGAL CONTENT?</span></h1>
            <p class="c2"><span class="c1">Web-Ar.space can only process your complaint if it relates to certain alleged illegal data or content that has been published by a user of Web-Ar.space and is linked to an AR trigger provided via Web-Ar.space .</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Before submitting a complaint under this User Content Policy, we recommend that you take steps to identify and contact the publisher of the alleged illegal data or content to request that it be discontinued.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If you cannot identify the publisher or if the publisher refuses to remove the user content, you can send us your complaint by email to the following address: support@web-ar.space or you can write to us under the following conditions</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Postal address: twospot GmbH. Mariahilferstrasse 118/33, 1070 Vienna, Austria</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">Please make sure to attach or attach the information below. It is the responsibility of the person submitting the complaint to ensure that the complaint is submitted clearly, correctly, completely and for good reason.</span></p>
            <h1 class="c6" id="h.br10p7h4rw6a"><span class="c0">WHAT INFORMATION SHOULD YOU INCLUDE IN YOUR COMPLAINT?</span></h1>
            <p class="c2"><span class="c1">In order for us to respond as quickly as possible, your complaint should contain at least the following information:</span></p>
            <ul class="c13 lst-kix_ppktwkksx4rr-0 start">
                <li class="c2 c5"><span class="c1">Your contact details and (if applicable) whether you are acting on behalf of another person;</span></li>
                <li class="c2 c5"><span class="c1">a brief description of the alleged unlawful user content and an explanation of why you claim that the user content is unlawful, illegal or otherwise harmful to your privacy, intellectual property or other rights. If you are accused of defamation, you should explain the statement in question and explain why the statement defamed you.</span></li>
                <li class="c2 c5"><span class="c1">(If known) the name of the person who published the user content, the AR trigger with which the user content was linked, or sufficient other information so that Web-Ar.space can find the user content in question;</span></li>
                <li class="c2 c5"><span class="c1">(if available) copies of any correspondence you have had with the publisher of the user content;</span></li>
                <li class="c2 c5"><span class="c1">If the complaint concerns an alleged violation of an intellectual property right, such as copyright, or trademark, you should provide evidence that you are the legal owner or owner of these rights, or that you are authorized to act on behalf of the above party.</span></li>
                <li class="c2 c5"><span class="c1">Any other information that you find helpful.</span></li>
            </ul>
            <h1 class="c6" id="h.5sq9ue1lzkib"><span class="c0">HOW DO WE DEAL WITH COMPLAINTS?</span></h1>
            <p class="c2"><span class="c1">As soon as you have made all of the information above available to Web-Ar.space, we will investigate your complaint. We will assess complaints based on applicable laws, regulations and codes, as well as our terms of use for Web-Ar.space. We will process complaints as soon as possible, but reserve the right to ask you for additional information or to seek legal advice.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If a complaint appears to be an infringement or other form of unlawfulness, we will have access to the user content</span></p>
            <p class="c2"><span class="c1">deactivate and / or remove from our systems. We will also remove user content if we have received a court order pursuant to section 297 of the Defamation Criminal Code. User content that has been removed can be saved by us in order to comply with legal obligations.</span></p>
            <p class="c2"><span class="c1">&nbsp;</span></p>
            <p class="c2"><span class="c1">If a WebAr.space user objects to the actions we take on their user content, they must notify us within seven (7) days. Typically, we will not re-enable access to content that is the subject of a complaint unless the user (publisher) can provide us with a certified and signed license agreement, settlement agreement, or a sealed order from a competent court, in such cases we reserve the right to refuse to return user content to our content delivery platform. We do not have to inform the Web-Ar.space user of any reason for such a rejection.</span></p>
            <h1 class="c6" id="h.hh8kziu4q5ax"><span class="c0">FINAL PROVISIONS</span></h1>
            <p class="c2"><span class="c1">Web-Ar.space evaluates complaints under these provisions regarding user content in accordance with Austrian law. We reserve the right to review and change these user content terms at any time. Depending on the circumstances of the individual case, we may deviate from certain aspects of the provisions on user content at any time. New versions of the provisions on user content are made available at https://web-ar.space/. If a version of the content policy contradicts this version, the new version takes precedence.</span></p>
            <p class="c2 c8"><span class="c1"></span></p>
        </div>

        
    </div>

}