import React, { Component, Fragment } from 'react';
import '@google/model-viewer';
import Utilities from '../common/Utilities';

import { TransformationStatus, SettingType } from '../common/Constants'

import  Loading  from '../common/Loading' 

export default function ArModelViewer (props) {

    var link = props.link;
    var draft = props.link.assetLinkSettings.find(p => p.assetLinkSettingType == SettingType.draft);
    var activeTransformation = draft.activeAssetTransformation;
    var iosSrc = Utilities.AzureStorageUrl + "/transformation/" + link.applicationUserId + "/" + draft.activeAssetTransformationId + "/"+ activeTransformation.tranformedFileiOs;
    var androidSrc = Utilities.AzureStorageUrl + "/transformation/" +  link.applicationUserId + "/" + draft.activeAssetTransformationId + "/"+ activeTransformation.tranformedFile;

    androidSrc = window.location.origin + "/unpublished/" + props.token + "/" +  link.applicationUserId + "/" + draft.activeAssetTransformationId + "/" + activeTransformation.tranformedFile;
    

    var draftStatus = draft.activeAssetTransformation.lastAssetTranformationStatus.status;

    var attributesToAdd = {};

    if (draft.bgImage && draft.bgImage.length > 0){

        attributesToAdd = { "background-image" : Utilities.AzureStorageUrl + "/" + draft.bgImage } ;
    }


    return <div className="col-sm" >
                <div className="modelViewerContainer" style={{background: draft.bgColor}}>

                  
                    <button className="previewButton" >
                            { props.t("Preview") } 
                    </button>

    {  ( props.isBusy || 
        draftStatus == TransformationStatus.beingProcessed || 
        draftStatus == TransformationStatus.downloadForProcessing ||
        draftStatus == TransformationStatus.waitingToBeProcessed ||
        draftStatus == TransformationStatus.inUpload ) && <Loading></Loading> }
    
    { draftStatus == TransformationStatus.processedWithErrors && <div> {draftStatus.message} </div> }

    { draftStatus == TransformationStatus.processedWithErrors && <div> {draftStatus.message} </div> }

    { (draftStatus == TransformationStatus.processedSuccessfully || draftStatus == TransformationStatus.processedWithWarnings ) && <Fragment> 
                    <model-viewer ar
                        autoplay
                        src={androidSrc}
                        ios-src={iosSrc}

                        {... attributesToAdd}
                        
                        camera-controls
                        background-color={draft.bgColor}
                        alt="&quot;test&quot; powered by weholo.at">
                    
                        {!props.isMobile && <button  className="switchToArButton" style={{"background" : draft.fgColor, "color" : draft.textColor }}  >
                            { props.t("ActivateAR") } 
                        </button>  }


                        <button slot="ar-button" className="switchToArButton" style={{"background" : draft.fgColor, "color" : draft.textColor }}  >
                            { props.t("ActivateAR") } 
                        </button>  
                    </model-viewer>
                 </Fragment>
}

                </div>
            </div>

}