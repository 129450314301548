import Request from './Request';

import { Translation } from 'react-i18next';
import Utilities from './Utilities';

 class AuthTokenProvider 
 {
    constructor(){
        this.token = null;
        this.promise = null;

        this.task = null;
    }

    async  getAuthToken (){

        // if (this.token != null){
        //     console.log(Date.now(),this.token.generatedAt, Date.parse(this.token.generatedAt), ( Date.now() - Date.parse(this.token.generatedAt)));
        // }
        if (this.token == null ||  ( Date.now() - Date.parse(this.token.generatedAt)) >  (1000 * 60 * 5) ) {
            
            if (this.task == null){
                this.task = this.getAndSetToken();
            }

            await this.task;
        }

        return this.token;
    }

    async getAndSetToken()
    {
        var response =  await Request.Post(Request.GetPaths().GetAuthToken);


        if (response.ok)
        {
            this.token = response.data;
        }
        this.task = null;
    }



}

export default new AuthTokenProvider();