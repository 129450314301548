import React, { Component, Fragment, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';

import './NavMenu.css';
import { HashLink as HashLink } from 'react-router-hash-link';

import { useAuthentication } from './common/Authentication';
import {useScrollHeightGt} from './common/Scrolling';
import { Login } from './public/Login';

import Request from './common/Request';
import { PlansData } from './common/Constants';
import { usePaymentStatus, useUserSettings } from './common/Hooks';

export function NavMenu (props) {


  const [collapsed, setCollapsed] = useState(true); 

  const auth = props.auth;

  const isMobile = props.isMobile;
 

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  const scrollHeightGt = useScrollHeightGt(10);

  const renderBrand = () => {
    return    <HashLink className="navbar-brand" to="/#Intro"> </HashLink>  /*  <NavbarBrand tag={Link} to="/"></NavbarBrand> */ 
  }

  const navItemClicked = () => {
    setCollapsed(true);
  }

  const showLogin = () => {
    props.overlayStates.loginOverlayState.setIsShown (true);
  }

  const showRegister = () => {
    props.overlayStates.registerOverlayState.setIsShown (true);
  }


  const secondNavPart = () => {

     // var redirectUrl = `${ApplicationPaths.Register}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href + "dashboard")}`;

      //var redirectUrl =  `${ApplicationPaths.Register}?${QueryParameterNames.ReturnUrl}=${encodeURI("/dashboard")}`;
      if (!auth.isAuthenticated) {
        return (<Fragment>
          <NavItem onClick={showRegister}>
            {/* <Redirect className="text-dark navRegister" to={redirectUrl}></Redirect> */}
              <a tag={Link}  className="text-dark navRegister nav-link" >{props.t("navRegister")}</a>
            { /*   <NavLink tag={Link}  to={auth.registerPath} >{props.t("navRegister")}</NavLink> */}
          </NavItem>
          <NavItem onClick={showLogin}>
              <a tag={Link}  className="text-dark navLogin nav-link" >{props.t("navLogin")}</a> {/* {auth.loginPath} */}
          </NavItem>
      </Fragment>);
      } else {
        return (<Fragment>
          
          {/* <NavItem>
              <NavLink tag={Link} className="text-dark" to={auth.profilePath}>{props.t("navMyProfile")}</NavLink>
          </NavItem> */}
          <NavItem onClick={navItemClicked}>
              <NavLink tag={Link} className="text-dark navLogout" to="/logout">{props.t("navLogout")}</NavLink>
          </NavItem>

          
          { !isMobile && <NavItem onClick={navItemClicked}>
              <NavLink tag={Link} className="text-dark navDashboard" to="/dashboard">{ props.user && props.user.name[0] }</NavLink>
            </NavItem>
          }
       

      </Fragment>);
      }
  }



  const firstNavPart = (isBackNav, isStartpage) => {
    if( auth.isAuthenticated )   
    {
      return <Fragment>
         <NavItem onClick={navItemClicked}>
              <NavLink tag={Link} className="text-dark leftNavButton" to="/dashboard">{props.t("navLinks")}{!props.isMobile ? ", " : ""}</NavLink>
            </NavItem> 
 
          <NavItem onClick={navItemClicked}>
              <NavLink tag={Link} className="text-dark leftNavButton" to="/tutorial">{props.t("navTutorial")}{!props.isMobile ? ", " : ""}</NavLink>
          </NavItem>
          <NavItem onClick={navItemClicked}>
              <NavLink tag={Link} className="text-dark leftNavButton" to="/settings">{props.t("navSettings")}</NavLink>
          </NavItem>
      </Fragment>
    }
    else
    {
      return <Fragment>

{ isMobile && auth.isAuthenticated && <NavItem onClick={navItemClicked} >
              <NavLink tag={Link} className="text-dark navDashboard" to="/dashboard">{props.t("navLinks")}</NavLink>
            </NavItem> }

                <NavItem onClick={navItemClicked}>
                  <HashLink className="text-dark nav-link leftNavButton" to="#Samples">{props.t("navExamples")}{!props.isMobile ? ", " : ""} </HashLink>  {/*  */}
                </NavItem>
                <NavItem onClick={navItemClicked}>
              <HashLink className="text-dark nav-link leftNavButton" to="#Plans">{props.t("navPlans")}{!props.isMobile ? ", " : ""} </HashLink>
                </NavItem>
                <NavItem onClick={navItemClicked}>
              <HashLink className="text-dark nav-link leftNavButton" to="#Tutorial">{props.t("navTutorial")}</HashLink>
                </NavItem>
              </Fragment>
    }
  }

  const renderBackBtn =  () => {
    if (false){ //backnav trough browser history
        return <div className="d-flex touchable pt-2" onClick={props.history.goBack}>
          {/* <div className="text-dark backBtn" > </div> */}
          <div className="lnkText pr-2 backBtn">← </div>
          <div className="lnkText"> {props.t(props.history.location.pathname.substring(1))} </div>
      </div>
    }
    else{
      var path = "/";
      var lnkName = props.t(props.history.location.pathname.substring(1));
      if (auth.isAuthenticated){
        path = "/dashboard";
      }

      if (props.history.location.pathname == "/ChangePlan"){
        
       // console.log(Request.ParseQueryString(props.history.location.search).id );
        var parsedQueryString = Request.ParseQueryString(props.history.location.search);
        var planId = parsedQueryString.id;
        var perYear = parsedQueryString.perYear;
        if ( planId !== 'undefined' && planId)
        {
          var lnkData = PlansData.filter(p => p.id == planId);
          if (lnkData.length > 0){
            lnkName = lnkData[0].title;
          }

          path = "/ChangePlan" + (perYear === "true" ? "?perYear=true" : "?perYear=false") + props.history.location.hash;

          
        }
        else{
          path = "/settings";
        }
       
      }
      
      if (props.history.location.pathname == "/OrderStatus"){
        path = "/ChangePlan";
      }
      
      return <HashLink className="d-flex touchable pt-2" to={path}>
          {/* <div className="text-dark backBtn" > </div> */}
          <div className="lnkText pr-2 backBtn">← </div>
          <div className="lnkText"> {lnkName} </div>
          </HashLink>
    }

  }

  const isStartPage = props.location.pathname == "/"
  const isBackNav = props.location.pathname != "/dashboard" && !isStartPage ;

  return (
      <header className={scrollHeightGt ? "active" : ""}>


          {props.isMobile  && <Fragment> 
                <Navbar className="navbar-expand-sm navbar-toggleable-sm" light>
                      
                      <div  className="headerBar"> 
                        { !isBackNav && <NavbarToggler onClick={toggleNavbar} className="mr-2" /> }
                        { isBackNav && renderBackBtn() }
                        { renderBrand() }
                        <div></div>
                      </div>

                      { !isBackNav && <Collapse className="d-sm-inline-flex " isOpen={!collapsed} navbar> {/* flex-sm-row-reverse */}
                        <ul className="navbar-nav flex-grow">

                          { firstNavPart(isBackNav, isStartPage) }
                          { secondNavPart ()}
                        </ul>
                        </Collapse> }
                  </Navbar> 

                

              </Fragment>
          }

          

          { !props.isMobile && <div className="headerBar"> 
                {!isBackNav &&
                  <Navbar className="navbar-expand-sm navbar-toggleable-sm  " light>
                      <ul className="navbar-nav flex-grow">
                        { firstNavPart(isBackNav, isStartPage) }
                      </ul>
                  </Navbar>}
                
                { isBackNav && <Navbar className="navbar-expand-sm " light> {renderBackBtn()}  </Navbar> }
                
                { renderBrand() }

                <Navbar className="navbar-expand-sm navbar-toggleable-sm " light>
                    <ul className="navbar-nav flex-grow">
                      { secondNavPart ()}
                    </ul>
                </Navbar>
              </div>
          }
        {/* </div> */}
          
    </header> );
}
