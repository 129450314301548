import React, { Component } from 'react';
import Plan from './Plan'

import { PlansData } from '../common/Constants';

export class Plans extends Component {


constructor(props) {
    super(props);


    this.switchPerYearFilter = this.switchPerYearFilter.bind(this);
    this.switchToMonthly = this.switchToMonthly.bind(this);
    this.state = { 
        perYear : true, 
        plans : PlansData
    }
}
  async componentDidMount() 
  {
    
    
  }

  switchToMonthly()
  {
    if (this.state != null)
    {
        this.setState({ perYear : false});
    }
  }
  
  switchPerYearFilter()
  {
    if (this.state != null)
    {
        this.setState({ perYear : true});
    }
  }


  render () {

    // if (this.state == null)
    //  return <div>woot</div>
   // console.log(this.state.perYear);

    return (
        <div id="Plans" className="parallexContainer">
            <div   className="public Plans">
                <div className="plan_header" >
                    <h2>Plans</h2>
            

                    

                    <div className="year_filter_bar_container noSelect">
                        <div className="year_filter_bar">

                            <div className={"selector " + (!this.state.perYear ? 'alt' : '')}> </div> 

                            <div className="textcontainer"> 
                                <div className={ "txt " }  onClick={this.switchPerYearFilter}> Anually • save 20% </div>
                                <div  className={ "txt "} onClick={this.switchToMonthly}> Monthly </div>
                            </div>
                        </div>
                    </div>
            
                    <h2></h2>
                </div>

                <div className="plan_container" >
                    {
                        this.state.plans.filter(p => (p.perYear && this.state.perYear) || (p.perMonth && !this.state.perYear) ).map((val, index) => 
                            <Plan key={index} plan={val }></Plan>
                        )
                    }
                </div>
            </div>
            <div></div>
        </div>
      );
  }
}
