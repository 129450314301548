import React, { Component, useState } from 'react';
import './public.css';


export default function Support (props)
{
  return (
      <div id="Support" className="public">

          <h1>Support</h1>


      </div>
    );
}
