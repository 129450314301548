import Request from './Request';

import { Translation } from 'react-i18next';
import Utilities from './Utilities';

 class User{
    

    constructor(){
        this.setting = null;
        this.user = null;
        this.currentAccessToken = null;
        this.paymentStatus = null;
    }

    async  accessTokenChanged (){
        var token = await Request.GetAccessToken(); 
        if (this.currentAccessToken == token){
            return false;
        }
        else{
            this.setting = null;
            this.user = null;
            this.currentAccessToken = token;
            return true;
        }
    }

    async GetUserSettings()
    {

        var tokenChanged = await this.accessTokenChanged();

        if (this.setting == null || tokenChanged)
        {
            this.setting = (await Request.Get(Request.Paths.UserSetting)).data;
        }
        return this.setting;
        
    }

    async SetUser (user){
        this.user = user;
    }

    async Get()
    {

        var tokenChanged = await this.accessTokenChanged();

        if (this.user == null || tokenChanged)
        {
            this.user  = (await Request.Get(Request.Paths.User)).data;
        }
        return this.user;

    }

    async SetPaymentStatus(paymentStatus){
        this.paymentStatus = paymentStatus;
    }

    async GetPaymentStatus()
    {
        var tokenChanged = await this.accessTokenChanged();

        if (this.paymentStatus == null || tokenChanged)
        {
            this.paymentStatus  = ( await Request.Get(Request.GetPaths().GetPaymentStatus)).data;
        }
        return this.paymentStatus;
    }


}

export default new User();