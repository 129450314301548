import React, { Component } from 'react';

export class LinkNotPublished extends Component {

  async componentDidMount() 
  {

  }



  render () {

    return (<div  className="public linkNotPublished pageContainer container">
     
            <div className="l40" style={{ textAlign : "center" }}>Link not published!</div>
        </div>
    );
  }
}
