

export const SampleUrls =
{
    intro : {
        webArLink : "/view/webar/intro",
        modelUrl : "/embed/webar/intro/super.gltf",
        modelUrliOs : "/embed/webar/intro/nice.usdz" 
    },
    sample1 : 
    {
        webArLink : "/view/webar/superimpose",
    },
    sample2 : 
    {
        webArLink : "/view/webar/rams_speaker",
    },
    sample3 : 
    {
        webArLink : "/view/webar/mannequin",
    }
}

export const Paths = {

    UserSetting  :'api/UserSetting' ,
    AssetLink  :'api/Asset/GetAssetLink' ,
    AssetLinks  :'api/Asset/GetAssetLinks' ,
    DeleteAssetLink  :'api/Asset/DeleteLink' ,
    DuplicateAssetLink  :'api/Asset/DuplicateLink' ,
    PostAssetLink  :'api/Asset/Upload' ,
    MoveAssetLink  :'api/Asset/MoveAssetLink' ,
    Asset  :'api/Asset' ,
    AssetTransformation  :'api/AssetTransformation' ,
    SetAssetLinkBackground  :'api/Asset/SetBackground' ,
    RemoveAssetLinkBackground  :'api/Asset/RemoveBackground' ,

    Publish  :'api/Asset/PublishDraft' ,
    Unpublish  :'api/Asset/Unpublish' ,
    Restore  :'api/Asset/Restore' ,

    DeleteAccount  :'api/User/DeleteAccount',
    ChangeName  :'api/User/ChangeName' ,
    ChangeEmail  :'api/User/ChangeEmail' ,
    ChangePassword  :'api/User/ChangePassword' ,
    ChangeLink  :'api/User/ChangeLink' ,
    User  :'api/User' ,
    GetAuthToken : 'api/User/GetToken',
    ForgotPassword : 'api/User/ForgotPassword',
    ResetPassword : 'api/User/ResetPassword',
    ConfirmEmail : 'api/User/ConfirmEmail',
    
    NewPaymentMethod  :'api/Payment/NewPaymentMethod' ,
    Plans : 'api/Payment/GetPlans',
    SubscribeTo : 'api/Payment/SubscribeTo',
    CancelSubscriptions : 'api/Payment/CancelSubscriptions',
    BeginCheckoutSession : 'api/Payment/BeginCheckoutSession',
    GetPaymentStatus : 'api/Payment/GetPaymentStatus',

    
 }

 export const FreePlanId = 'free';

 export const PlansData =  [
{
    id : 'free',
    isEnterprise : false,
    perYear : true,
    perMonth : true,
    title : '2 Links',
    subtitle : 'free',
    price : 0,
    links : 2,
    p1 : '2 published AR Links <br \/>\r\niOs and Android <br \/>\r\n200 views/month <br \/>\r\nWEB‣AR watermark',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br \/>\r\nUp to 5 mb per scene<br \/>\r\nTextures 1024x1024px<br\/>\r\nAnimations<br\/>\r\n'
}, 
{
    id : '5_links_pm',
    isEnterprise : false,
    perYear : false,
    perMonth : true,
    title : '5 Links',
    subtitle : '/ month',
    price : 15,
    links : 5,
    p1 : '5 published AR Links<br\/>\r\niOS and Android<br\/>\r\nButton + background styling<br\/>\r\n5.000 views\/month<br\/>\r\n€ 0,03\/extra view<br\/>\r\nNo WEB‣AR watermark',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br\/>\r\nUp to 10 mb per scene<br\/>\r\nTextures 2048x2048px<br\/>\r\nAnimations<br\/>'

}, 
{
    id : '20_links_pm',
    isEnterprise : false,
    perYear : false,
    perMonth : true,
    title : '20 Links',
    subtitle : '/ month',
    price : 25,
    links : 20,
    p1 : '20 published AR Links<br\/>\r\niOS and Android<br\/>\r\nButton + background styling<br\/>\r\n20.000 views\/month<br\/>\r\n€ 0,005\/extra view<br\/>\r\nNo WEB‣AR watermark<br\/>\r\n\r\n',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br\/>\r\nUp to 20 mb per scene<br\/>\r\nTextures 2048x2048px<br\/>\r\nAnimations<br\/>'

}, 
        
{
    id : '100_links_pm',
    isEnterprise : false,
    perYear : false,
    perMonth : true,
    title : '100 Links',
    subtitle : '/ month',
    price : 99,
    links : 100,
    p1 : '100 published AR Links<br\/>\r\niOS and Android<br\/>\r\nButton + background styling<br\/>\r\n100.000 views\/month<br\/>\r\n€ 0,004\/extra view<br\/>\r\nNo WEB‣AR watermark<br\/>',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br\/>\r\nUp to 20 mb per scene<br\/>\r\nTextures 2048x2048px<br\/>\r\nAnimations<br\/>'


}, 
{ 
    id : 'enterprise_links_pm',
    isEnterprise : true,
    perYear : false,
    perMonth : true,
    title : 'Custom',
    subtitle : 'Starts at € 50,00 / month',
    price : 100,
    links : 101,
    p1 : 'Costum number of AR Links<br\/>\r\niOS and Android<br\/>\r\nButton + background styling<br\/>\r\nCustom views\/month<br\/>\r\nAnalytics<br\/>\r\nNo WEB‣AR watermark<br\/>',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br\/>\r\nUp to 25 mb per scene<br\/>\r\nTextures 2048x2048px<br\/>\r\nAnimations<br\/>'

},

{
    id : '5_links_py',
    isEnterprise : false,
    perYear : true,
    perMonth : false,
    title : '5 Links',
    subtitle : '/ year',
    price : 144,
    links : 5,
    p1 : '5 published AR Links<br\/>\r\niOS and Android<br\/>\r\nButton + background styling<br\/>\r\n5.000 views\/month<br\/>\r\n€ 0,03\/extra view<br\/>\r\nNo WEB‣AR watermark',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br\/>\r\nUp to 10 mb per scene<br\/>\r\nTextures 2048x2048px<br\/>\r\nAnimations<br\/>'
}, 
{
    id : '20_links_py',
    isEnterprise : false,
    perYear : true,
    perMonth : false,
    title : '20 Links',
    subtitle : '/ year',
    price : 240,
    links : 20,
    p1 : '20 published AR Links<br\/>\r\niOS and Android<br\/>\r\nButton + background styling<br\/>\r\n20.000 views\/month<br\/>\r\n€ 0,005\/extra view<br\/>\r\nNo WEB‣AR watermark<br\/>\r\n\r\n',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br\/>\r\nUp to 20 mb per scene<br\/>\r\nTextures 2048x2048px<br\/>\r\nAnimations<br\/>'

}, 
        
{
    id : '100_links_py',
    isEnterprise : false,
    perYear : true,
    perMonth : false,
    title : '100 Links',
    subtitle : '/ year',
    price : 950,
    links : 100,
    p1 : '100 published AR Links<br\/>\r\niOS and Android<br\/>\r\nButton + background styling<br\/>\r\n100.000 views\/month<br\/>\r\n€ 0,004\/extra view<br\/>\r\nNo WEB‣AR watermark<br\/>',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br\/>\r\nUp to 20 mb per scene<br\/>\r\nTextures 2048x2048px<br\/>\r\nAnimations<br\/>'

}, 
{ 
    id : 'enterprise_links_py',
    isEnterprise : true,
    perYear : true,
    perMonth : false,
    title : 'Custom',
    subtitle : 'Starts at € 50,00 / month',
    price : 1000,
    links : 101,
    p1 : 'Costum number of AR Links<br\/>\r\niOS and Android<br\/>\r\nButton + background styling<br\/>\r\nCustom views\/month<br\/>\r\nAnalytics<br\/>\r\nNo WEB‣AR watermark<br\/>',
    p2 : 'Formats: fbx, gltf, obj,<br\/>\r\ndae, 3ds, jpg, png<br\/>\r\nUp to 25 mb per scene<br\/>\r\nTextures 2048x2048px<br\/>\r\nAnimations<br\/>'

}];


export const Validation = {

    Form : {
        UserLink : { required: true, maxLength: 50, minLength: 2, pattern: /^[0-9a-zA-Z$\-_+!*'(),]+$/ },
        UserName : { required: true, maxLength: 50, minLength: 2, pattern: /^[0-9a-zA-Z$\-_+!*'(),]+$/ },
        Email : { required: true, maxLength: 50, minLength: 2, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
        Password : { required: true, maxLength: 50, minLength: 6} /* , pattern: /^[0-9a-zA-Z$\-_+!*'(),]+$/  */
    }
 
}

export const SettingType = {
    draft : 0,
    published : 1
}

export const TransformationStatus = {
    inUpload : 0,

    waitingToBeProcessed : 1,

    downloadForProcessing : 2,

    beingProcessed : 3,


    processedWithWarnings : 4,

    processedWithErrors : 5,

    processedSuccessfully : 6
}