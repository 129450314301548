import React, { Fragment } from 'react'



export function TextInputLine(props){
    return <div className="form-group">
        <TextInput {...props}></TextInput>
    </div>
}

export function TextInput(props)
{


    return <Fragment>

        { props.label && <label htmlFor={props.name}>{props.label }</label> }

        <input type="text" ref={props.forwardRef} name={props.name} className={"form-control " +  (props.error && "is-invalid")} id={props.name} placeholder={props.placeholder} /> 
        { !props.error && <div className="valid-feedback">
            Looks good!
        </div> } 

        { props.error && props.error.message && <div className="invalid-feedback">
            { props.error.message }
        </div> } 

        { props.error && !props.error.message && props.error.type && <div className="invalid-feedback">
            { props.t(props.error.type) }
        </div> } 
    </Fragment>
}