import React, {Component, Fragment} from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    CardElement,
    injectStripe,
    StripeProvider,
    Elements,
} from 'react-stripe-elements';
import User from '../common/User';
import Request from '../common/Request';
import  StripeScriptLoader  from 'react-stripe-script-loader'

import './PaymentMethod.css';
import Utilities from '../common/Utilities';

import { Redirect as RedirectReact } from 'react-router';

import { ChangePlan } from './ChangePlan';

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {

      base: {
          
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class SplitFieldsForm extends Component {
    state = {
      errorMessage: '',
    };
  
    handleChange = ({error}) => {
      if (error) {
        this.setState({errorMessage: error.message});
      }
    };
  
    handleSubmit = (evt) => {
      evt.preventDefault();
      if (this.props.stripe) {
        


           this.props.stripe.createToken().then(this.props.handleResult);
      } else {
        console.log("Stripe.js hasn't loaded yet.");
      }
    };

    render() {
      return (
        <form onSubmit={this.handleSubmit.bind(this)}>

            
        <div class="split-form form-row "> 
            <div className="col">
                <label>
                Card number

                </label>
                <CardNumberElement
                    {...createOptions()}
                    onChange={this.handleChange}
                />
            </div>
            <div class="col"> 
                <label>
                Expiration date

                </label>
                <CardExpiryElement
                    {...createOptions()}
                    onChange={this.handleChange}
                />
            </div>
          </div>
          <div className="split-form form-row">
          <div class="col"> 
                <label>
                CVC
                
                </label>
                <CardCVCElement {...createOptions()} onChange={this.handleChange} />
            </div>
            <div class="col"> 
                <label>
                Postal code
                </label>

                <input
                    name="name"
                    type="text"
                    placeholder="94115"
                    className="StripeElement form-control"
                    required
                />
            </div>
          </div>
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
          <button>Pay</button>
        </form>
      );
    }
  }

class CardForm extends Component {
  state = {
    errorMessage: '',
  };

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };


    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }   

    async handleSubmit (evt)
    {
        evt.preventDefault();

        var usr = await User.Get();
        const cardElement = this.props.elements.getElement('card');
        console.log(usr);
        this.props.stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: { email: usr.email, name: usr.name }
        })
        .then(({paymentMethod}) => 
        {
            var result = Request.Post(Request.GetPaths().NewPaymentMethod, paymentMethod);
            console.log('Received Stripe PaymentMethod:', paymentMethod);

            if (this.props.callback)
              this.props.callback(result, paymentMethod);

        });

        // if (this.props.stripe) {
        //   this.props.stripe.createToken().then(this.props.handleResult);
        // } else {
        //   console.log("Stripe.js hasn't loaded yet.");
        // }
    };

  render() {
    return (
      <div className="CardDemo">
        <div> Enter new payment method:</div>

        <div> Demo card numbers:</div>
        <div> 4242424242424242	Succeeds and immediately creates an active subscription.</div>
        <div> 4000002500003155	Requires authentication. confirmCardPayment will trigger a modal asking for the customer to authenticate. Once the user confirms, the subscription will become active.</div>
        <div> 4000008260003178	Always fails with a decline code of insufficient_funds.</div>
 
        <form ref="cardForm" onSubmit={this.handleSubmit}>
          <label>
            Card details
            <CardElement className="cardElement"
              onChange={this.handleChange}
              {...createOptions()}
            />
          </label>
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
          <button>Pay</button>
        </form>
      </div>
    );
  }
}





export class PaymentStatus extends Component{
  constructor(props) {
    super(props);

    this.state = { rdy : false, changePlan : false};

    this.additionalAction = this.additionalAction.bind(this);


    this.startPaymentSession = this.startPaymentSession.bind(this);


    this.triggerAction = this.triggerAction.bind(this);
  }

    
  async componentDidMount() {
        


  }

  async additionalAction(ev){

    var paymentStatus = this.props.paymentStatus;
    var stripe = this.props.stripe;

    // This can be found on invoice.payment_intent.client_secret
    var paymentIntentSecret = paymentStatus.clientSecret;
    stripe.confirmCardPayment(paymentIntentSecret).then(function(result) {
      if (result.error) {
        console.log(result);
        Utilities.showError(this.props.t("msgFailedToChargeCreditCard"));
      } else {
        Utilities.showSuccess(this.props.t("msgCreditCardCharged"));
      }
    });

  }

  changePlan(){
    this.setState({changePlan : true});
  }


  async startPaymentSession(purchasePlan = false){
    
    var user = await User.Get();

    console.log("purchasePlan", purchasePlan);
    var args = this.props.metadata;
    if (purchasePlan === true){
      args.purchasePlan = purchasePlan;
    }

    var result = await Request.Post(Request.GetPaths().BeginCheckoutSession, args)
    
    if (result.ok){
      this.props.stripe.redirectToCheckout({

        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: result.data
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      });
    }

  }



  async triggerAction(ev){

    var paymentStatus = this.props.paymentStatus;

    if (paymentStatus.lastInvoiceStatus == "requires_payment_method" ||
        paymentStatus.lastInvoiceStatus == "succeeded"){
        await this.startPaymentSession();
    }
    else if (paymentStatus.lastInvoiceStatus == "requires_action"){
        await this.additionalAction();
    }

  }



  render() {
    if (this.props.paymentStatus != null)
    {
      var paymentStatus = this.props.paymentStatus;
      var enterNewPaymentMethod =  paymentStatus.lastInvoiceStatus == null || paymentStatus.lastInvoiceStatus == "requires_payment_method";

      if (this.state.changePlan){
        return <RedirectReact push to="/changePlan" ></RedirectReact>
      }

      return <Fragment>


         

            {/* <div className="row  d-flex">

              <label className="col-sm-2 col-form-label">{this.props.t("CurrentPlan")}</label>

                <div className="col-sm-10 d-flex">
                <label type="name" className="col-form-label flex-grow-1"  placeholder={this.props.t("NamePlaceholder")}> {this.state.currentPlan} </label>

      
                  <button type="button" id="changePlan" className="btn btn-link" onClick={this.changePlan}>Change</button> 
                </div>
            </div>

            <div className="row  d-flex">

              <label className="col-sm-2 col-form-label">{this.props.t("TotalAvailableLinks")}</label>

                <div className="col-sm-10 d-flex">
                <label type="name" className="col-form-label flex-grow-1"  placeholder={this.props.t("NamePlaceholder")}> {this.state.totalAvailableActiveLinks} </label>
                </div>
            </div> */}


        
    
            {/* <div className="row  d-flex">

              <label className="col-sm-2 col-form-label">{this.props.t("CurrentCard")}</label>

              <div className="col-sm-10 d-flex">
                <label type="name" className="col-form-label flex-grow-1"  placeholder={this.props.t("NamePlaceholder")}> 
                  { paymentStatus.card != null && <Fragment>
                    {paymentStatus.card.brand} {paymentStatus.card.last4}  
                  </Fragment> }
                </label>
                </div>
              </div>

          

              
          */}


      
      <div className={"row threeCol " + ((paymentStatus.lastInvoiceStatus == "requires_payment_method") ? "is-invalid " : "") } >

        <label className=" col-form-label">{this.props.t("PaymentMethod")} </label>
        <label className="col-form-label flex-grow-1">
          { paymentStatus.card != null && <Fragment> {paymentStatus.card.brand} ***{paymentStatus.card.last4}  </Fragment> }
        
          { paymentStatus.card == null && this.props.t("NoPaymentMethodProvided") }
         </label>
         { paymentStatus.card != null && <input type="button" id="changePaymentMethod" className="btn btn-link" onClick={this.startPaymentSession} value={this.props.t("Change")}></input>}
           
      </div>            


          
        {/* {  (paymentStatus.lastInvoiceStatus == "requires_action") && <input type="button" onClick={this.additionalAction} value="payment requires additon action (3D Secure)"></input> } */}
        
   
        { (paymentStatus.lastInvoiceStatus == "requires_payment_method") && <div className="row oneCol invalid-feedback"> { this.props.t("lblFailedToChargeCreditCard")} </div>  }
    
        


        {/* paymentStatus.lastInvoiceStatus == "succeeded" &&  */}
        
        { (paymentStatus.invoicePdf != null && paymentStatus.invoicePdf.length > 0)  &&  <Fragment>
            <div className="separator">
            </div><div className={"row threeCol " } >
              <label className=" col-form-label">{this.props.t("LastInvoice")} </label>
              <div></div>
              <a href={paymentStatus.invoicePdf} type="button" id="downloadInvoice" className="btn btn-link">{this.props.t("Download")} </a>
            </div>
        </Fragment> }  


    

        {  enterNewPaymentMethod &&  <div > 
      {/*   <CardForm {... this.props}  ></CardForm>   
        
        <SplitFieldsForm {... this.props}></SplitFieldsForm>   */}

        {/* */}    </div>  }
        
        {this.props.children}
      </Fragment>
    }
    else{
      return null;
    }
  }

}

class _InjectedWrapper extends Component {
  
  constructor(props){
    super(props);
  }

  render(){
    return <Fragment> {this.props.render(this)} </Fragment>
  }
 
}


// React.forwardRef((props, ref) => (
//   <button ref={ref} className="FancyButton">
//     {props.children}
//   </button>

 const InjectedWrapper = injectStripe(_InjectedWrapper);

// const CardForm = injectStripe(_CardForm);
// const Redirect = injectStripe(_Redirect);



export class StrypeWrapper extends Component {



  render() {



    return (
        <StripeScriptLoader
            uniqueId='myUniqueId'
            script='https://js.stripe.com/v3/'
            loader="Loading..."> 

          <StripeProvider apiKey={this.props.stripeApiKey}>
            <Elements>
        
              <InjectedWrapper render={ (p) => (<Fragment>  { React.cloneElement(this.props.children, {...p.props}) }</Fragment>   )}>

              </InjectedWrapper>
              
            
            </Elements>
          </StripeProvider>
     
      </StripeScriptLoader>
    );
  }
}