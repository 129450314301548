import React, { Fragment } from 'react';
import OverlayContainer from '../common/OverlayContainer';
import { HashLink as HashLink } from 'react-router-hash-link';

export function PaymentFailedOverlay(props){


    const close = (ev) => {


        if (props.abort)
            props.abort();
    }

    return <OverlayContainer className="upgrade withSkip "  abort={close}>

        <Fragment>
            <h1>Something went wrong!</h1>

            <div className="text">There seems to be a problem with your <br/> payment information. Your links have <br/> been temporarily unpublished <br/> 
            <HashLink to="/Settings" className="btn btn-link underline p-0 abort" style={{"textTransform" : "none"}}>Check your payment information.</HashLink>
            </div>
            <HashLink to="/ChangePlan?perYear=false#20_links_pm" className="btn btn-ts abort" >Upgrade</HashLink> 
            <button onClick={ close } className="btn btn-link pl-0 pr-0 gray w-100 text-center abort skip" >Skip</button>

        </Fragment>
    </OverlayContainer>
}