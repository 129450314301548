import React, { Component, Fragment } from 'react';

export default class LanguageBar extends Component {
	constructor(props) {
		super(props);

        this.switchLangauge = this.switchLangauge.bind(this);
    }

    switchLangauge(ev){
        var lang = ev.target.getAttribute('name');
        this.props.i18n.changeLanguage(lang);

        console.log(this.props.i18n);
    }

    render() {
        return <Fragment>
            <div onClick={this.switchLangauge} name="de" className={"btn pl-0" + (this.props.i18n.language == "de" ? ' selected' : '') } >
                de
            </div>
            <div onClick={this.switchLangauge} name="en" className= { "btn pl-0" + (this.props.i18n.language == "en" ? ' selected' : '') } >
                en
            </div>
         </Fragment>
	}   
}