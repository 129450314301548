import React, { Component, Fragment, useState} from 'react';
import './public.css';
import Request from '../common/Request';
import Utilities from '../common/Utilities';
import { Validation, Paths } from '../common/Constants';
import { Redirect } from 'react-router-dom';
import OverlayContainer from '../common/OverlayContainer';
import { useForm } from "react-hook-form";


export function ResetPassword(props){
    var auth = props.auth;
	  const { handleSubmit, register, getValues, errors, setError, clearError, formState, triggerValidation } = useForm();
    const [successfullySent, setSuccessfullySent] = useState(false);
    //const [failure, setFailure] = useState(false);

    const onSubmit = async (values) => {
        clearError("passwordsDontMatch")
        clearError("tokenNotValid");
        
        if (values.password != values.passwordConfirmation){
          setError("passwordsDontMatch");
          return;
        }

        var code = props.parsedQueryString.code;

        var result = await Request.Post(Paths.ResetPassword, { Email: values.email, Password: values.password, Code:code });
        if (result.ok){
  
          setSuccessfullySent(true);
        }
        else{

          setError("tokenNotValid");
        }
    };


  
	const close = async () => {
		console.log("close");
		if (props.overlayStates){
            props.overlayStates.hide();
        }
        else{
            return Redirect("/dashboard");
        }
    }

    var userVal = Validation.Form.UserName;
    var emailVal = Validation.Form.Email;
    var passwordVal = Validation.Form.Password;

    return <OverlayContainer className="upgrade withSkip "  abort={close}>
          <Fragment>

            { !successfullySent  && <Fragment> 
                <h1>Reset password</h1>
                
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="form-group">
                    <label className={((errors.email)  ? " is-invalid" : "" )}>Email</label>
                    <input name="email" className={"form-control" +  ((errors.email)  ? " is-invalid" : "" )}  ref={register(emailVal)} />
                    {errors.email  && <div className="invalid-feedback">{props.t('emailInvalid')} </div> }
                  </div>
    
                  <div className="form-group">
                    <label className={((errors.password)  ? " is-invalid" : "" )}>Password</label>
                    <input name="password" className={"form-control" +  ((errors.password)  ? " is-invalid" : "" )}  type="password" ref={register(passwordVal)} />
                    {errors.password  && <div className="invalid-feedback"> {props.t('passwordInvalid')} </div> }
                
                  </div>

                  <div className="form-group">
                    <label className={((errors.passwordConfirmation || errors.passwordsDontMatch )  ? " is-invalid" : "" )}>Confirm password</label>
                    <input name="passwordConfirmation" className={"form-control" +  ((errors.passwordConfirmation || errors.passwordsDontMatch )  ? " is-invalid" : "" )}  type="password" ref={register(passwordVal)} />
                    {errors.passwordConfirmation  && <div className="invalid-feedback"> {props.t('passwordInvalid')} </div> }
                    {errors.passwordsDontMatch  && <div className="invalid-feedback"> {props.t('passwordsDontMatch')} </div> }
                   
                    
                  </div>

                  {errors.tokenNotValid && <div class="text-danger validation-summary-errors" data-valmsg-summary="true"><ul><li> {props.t('tokenNotValid')}</li></ul></div> }

                  <button  className="btn btn-ts mb-2" type="submit">Reset</button>
                </form>
              </Fragment>}

              { successfullySent  && <Fragment> 
                <h1>Reset password</h1>
                <p> Passwort wurde erfolgreich gewechselt!</p> 
              </Fragment> }
          </Fragment>
    
        </OverlayContainer>
}