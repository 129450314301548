import React, { Component, useState, useEffect, useRef } from 'react';
import User from './User';
import { useIsMobile } from '../common/IsMobile'
import { HttpResponse } from '@microsoft/signalr';

import ReactDOM from "react-dom";

export function QrCodeToLink (props) {

    var QRCode = require('qrcode.react');

    var IsMobile = useIsMobile();


    var qrCodeCanvas = useRef(null);

    var size = 100;
    if (props.size)
        size = props.size;

    var fgColor =  "#FF5100";
    if (props.fgColor)
        fgColor = props.fgColor;

    const [userSetting, setUserSetting] = useState(null);

    const getUserSettings = async () => {

        var setting = await User.GetUserSettings();
        setUserSetting (setting);
    }


    useEffect( () => {
        if (userSetting == null && !props.relativePath){
            
            getUserSettings();
        }
            
    }, []);


    const downloadQrCode = () => {
  
        const node = ReactDOM.findDOMNode(qrCodeCanvas.current);
        var canvas = node.querySelector('canvas');
        var url = canvas.toDataURL("image/png");
        var link = document.createElement('a');
        link.download = 'filename.png';
        link.href = canvas.toDataURL();
        link.click();


    };

    if (props.relativePath != null)
    {
        
        var fullLink = window.location.origin + props.relativePath;

    }

    else if (userSetting != null){

        var combinedPrefix = window.location.origin + "/view/" + userSetting.userPrefix + "/"; // userSetting.domain
    

        var changeAbleLinkPart = props.value;
        if (changeAbleLinkPart){
            var split = changeAbleLinkPart.trim("/").split("/");
            changeAbleLinkPart = split[split.length-1];
        }    
        var fullLink = combinedPrefix + changeAbleLinkPart;
    
    
      
    }
    else{
        return null;	
    }

    if (IsMobile && !props.showDownloadButton){
        if (props.hideMobileArButton)
            return null;
       return <div className="qrContainer"> 
                <a href ={fullLink} target="_blank">
                    <button slot="ar-button" className="switchToArButton"  >
                        { props.t("ActivateAR") } 
                    </button>  
                </a>
            </div>
    }
    else
    {
        return  <div className="qrContainer" ref={qrCodeCanvas}  > 
			<QRCode size={720} className="qr" value={fullLink} fgColor={fgColor} style={{width : size+ "px", height : size+"px"}}></QRCode>
                { props.text && <div className="txt">{props.text}</div>  }
                
            { props.showDownloadButton && <div className="btn btn-ts" onClick={downloadQrCode} >Download</div> }
         </div>
    }
   

    

}

