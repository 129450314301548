import React, { Component, useState, useEffect, Fragment } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Loading from '../common/Loading'

import { useLocation } from "react-router-dom";
import Dropzone, {useDropzone} from 'react-dropzone'
import Request from '../common/Request';

import Utilities from '../common/Utilities';
import ColorPicker from '../common/ColorPicker';
import update from 'immutability-helper';
import User from '../common/User';
import UrlEditor from '../common/UrlEditor';
import ArModelViewer from './ArModelViewer';
import SignalRClient from '../common/SignalRClient';
import "./AssetLinkDetails.css"
import { SettingType, PlansData } from '../common/Constants'
import  UploadAsset  from './UploadAsset';


import {QrCodeToLink } from '../common/QrCodeToLink'

import AuthTokenProvider from '../common/AuthToken'
import OverlayContainer from '../common/OverlayContainer';
import Url from '../common/Url';
import { UpgradeOverlay } from '../overlays/UpgradeOverlay';
import { usePaymentStatus } from '../common/Hooks';

export default function AssetLinkDetails (props) {
	const [doInit, setDoInit] = useState(true);
	const [isBusy, setIsBusy] = useState(true);
	const [result, setResult] = useState(null);
	const [link, setLink] = useState(null);
	const [linkId, setLinkId] = useState(0);
	const [acceptedFiles, setAcceptedFiles] = useState([]);
	const [user, setUser] = useState(null);

	const [showQr, setShowQr] = useState(false);

	const [autoSaveTask, setAutoSaveTask] = useState(null);
	const [isUploadingNewFiles, setIsUploadingNewFiles] = useState(false);

	const [authToken, setAuthToken] = useState(null);
	
	const [showUpgradeOverlay, setShowUpgradeOverlay] = useState(false);

	const [fullUrl, setFullUrl] = useState(null);

	var paymentStatus = usePaymentStatus();

	var isNotPaying = paymentStatus == null || paymentStatus.currentPlan == PlansData[0].id;

	const publish = async () => {

		if (isBusy)
			return;

		setIsBusy(true);

		var response = await Request.Post(Request.Paths.Publish, linkId  );

		if (!response.ok && response.statusCode == 507){
			setShowUpgradeOverlay(true)
		}

		Utilities.showResponse(response, props.t);
		
		
		if (response.ok){
			var result = (await Request.Get(Request.Paths.AssetLink, { linkId :  linkId})).data;
			setLink(result);
		}

		setIsBusy(false);
	}

	const restore = async () => {

		if (isBusy)
			return;

		setIsBusy(true);

		await Request.Post(Request.Paths.Restore, linkId );

		var result =(await Request.Get(Request.Paths.AssetLink, { linkId :  linkId })).data;

		setLink(result);
		setIsBusy(false);
	}

	const unpublish = async () => {

		if (isBusy)
			return;

		setIsBusy(true);

		await Request.Post(Request.Paths.Unpublish,  linkId);

		var result = (await Request.Get(Request.Paths.AssetLink, { linkId : linkId})).data;

		setLink(result);




		setIsBusy(false);
	}


	const getLink = async (parLnkId) => {


		var result = (await Request.Get(Request.Paths.AssetLink, { linkId : parLnkId } )).data;

		setLink(result);
		setLinkId( result.assetLinkId);

		await SignalRClient.EnsureConnectionIsOpen();
		SignalRClient.onAssetTransformationUpdated( (refreshedLink) => {
		//var result = await Request.Get(Request.Paths.AssetLink, { linkId : Request.ParseQueryString(props.location.search).id });
		//	link : result, 
			console.log("onAssetTransformationUpdated", refreshedLink.assetLinkId, result.assetLinkId);
			if (refreshedLink.assetLinkId == result.assetLinkId){
				console.log("setting link");
				setLink(refreshedLink);
			}
		});

		setFullUrl(await Url.getFullLink(result.linkAddress));
	}


	const doInitTask = async () => {
		setIsBusy(true);
		await getLink(Request.ParseQueryString(props.location.search).id);

		var user = await User.Get();
		setUser(user);


		var token = await AuthTokenProvider.getAuthToken();

		
		if (token){
		
			setAuthToken(token.token);
		}
			
		setIsBusy(false);
	}


    useEffect( () => {


		if (doInit)
		{
			setDoInit(false);
			doInitTask();
		}


		


	});

	const saveLinkSettings = async (data) => {
		await Request.Put(Request.Paths.Asset, data);
	}

	
	const  handleSubmit = async (ev) => {
		ev.preventDefault();

		if (isBusy)
			return;

		setIsBusy(true);

		await saveLinkSettings();

		if (acceptedFiles.length > 0){
			const data = new FormData();

			acceptedFiles.map((file) => data.append('file', file));
			
			data.append('assetLinkId', link.assetLinkId);
	
			var assetTransformationId = (await Request.PostForm(Request.Paths.AssetTransformation, data)).data;
			
			setIsBusy(false);
			setAcceptedFiles( []);

		}
		else{
			setIsBusy(false);
		}

	}

	const onDrop = (acceptedFiles) => {

		setAcceptedFiles(acceptedFiles);

		console.log(acceptedFiles);
	}

	const bgColorChanged = (ev) => {
		console.log(ev);
		var changedlink = link;
		changedlink.assetLinkSettings[0].bgColor = ev;

		setLink(changedlink);
	} 
		
	const settingsPropertyChanged = (event) => {
		const target = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
		var upd = {  assetLinkSettings : { [0] : { [name] : { $set : value}} }};
		var copy = Utilities.copyUpdate(link,  upd);

		setLink(copy);
		invokeAutoSave(copy);
	}

	const linkPropertyChanged = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
		var upd = {  [name] : { $set : value} };
		var copy = Utilities.copyUpdate(link,  upd);
		
		setLink(copy);
		invokeAutoSave(copy);
	}
	

	const invokeAutoSave = (data) => {
		if (autoSaveTask != null){
			clearTimeout(autoSaveTask);
		}
		setAutoSaveTask(setTimeout(saveLinkSettings, 1000, data));
	}


	const placement = 'right';

	const setBackgroundImage = async (newFiles) => {
		console.log(newFiles);
		if (newFiles.length == 1){
			setIsBusy(true);
			const data = new FormData();

			data.append('file', newFiles[0]);		
			data.append('assetLinkId', link.assetLinkId);
			var result = await Request.PostForm(Request.Paths.SetAssetLinkBackground, data);

			getLink(link.assetLinkId);
			setIsBusy(false);
		}
	};

	const removeBgImage = async (e) => {



		e.stopPropagation();

		if (isBusy)
			return;

		setIsBusy(true);
		var result = await Request.Post(Request.Paths.RemoveAssetLinkBackground,  link.assetLinkId);
		await getLink(link.assetLinkId);
		setIsBusy(false);
	};

	const deleteLink = async () => {

		if (isBusy)
			return;

		setIsBusy(true);
	
		var result = (await Request.Delete(Request.Paths.DeleteAssetLink, link.assetLinkId)).data;

		window.history.back();

		setIsBusy(false);

		
	};

	const uploadNewFiles = async () => {
		setIsUploadingNewFiles(true);
	};

	const uploadSucceeded = async (result) => {
		if (result.success){
			await getLink(link.assetLinkId);
		}
		setIsUploadingNewFiles(false);
	};

	const {getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles} = useDropzone({onDrop: setBackgroundImage, minSize:Utilities.minUploadFileSize, maxSize:Utilities.maxUploadFileSize, multiple:false, accept: ["image/png", "image/jpg", "image/jpeg", ".hdr"], });
	const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > Utilities.maxUploadFileSize;

	var hasChanges = false;

	if (link != null){


		var bgImageStyle = {

		}

		var draft = link.assetLinkSettings.find(p => p.assetLinkSettingType == SettingType.draft);
		var live = link.assetLinkSettings.find(p => p.assetLinkSettingType == SettingType.published);
		
		

		var hasProduction = live && live != null;

		if (hasProduction){
			hasChanges =  !(draft.bgImage == live.bgImage && draft.bgColor == live.bgColor && draft.fgColor == live.fgColor && draft.textColor == live.textColor && draft.activeAssetTransformationId == live.activeAssetTransformationId); 
		}

		if (draft.bgImage && draft.bgImage.length > 0){
			bgImageStyle["backgroundImage"] = "url(\"" + Utilities.AzureStorageUrl + "/"  + draft.bgImage + "\")";
		}

	}
	else
	{
		return <div></div>
	}

	const renderImagePicker = () => {
		return <Fragment>
			<div className= { "noSelect" +   (( rejectedFiles.length > 0 )  ? " is-invalid" : "" )}  style={{width:"80px", height:"40px"}} >
				
				<div {...getRootProps()} >
					<input {...getInputProps()} />
					<div className="bgImageButton btn" style={ { ... bgImageStyle}}> 
						{ draft.bgImage && draft.bgImage.length > 0 && <div className="bgImageClearButton btn" onClick={removeBgImage}> </div> }
					</div>
				</div>
			
			</div>

			
		</Fragment>

	}

	

	var showQrClicked = () => {
		if (isNotPaying){
			setShowUpgradeOverlay(true);
		}
		else{
			setShowQr(true);

		}
	}

	var showPayOverlayIfNeeded = () => {
		if (isNotPaying){
			setShowUpgradeOverlay(true);
		}
	}

	var copyToClipboard = async (ev) => {

		
		const textField = document.createElement('textarea');
		textField.innerText = fullUrl;
		const parentElement = document.getElementById("assetLinkDetails");
		parentElement.appendChild(textField);
		textField.select();
		textField.setSelectionRange(0, 99999);
		document.execCommand('copy');
		parentElement.removeChild(textField);
	}

	return (
		<div className="mikesFunnyBorder assetLinkDetails"> 

	{ showUpgradeOverlay && <UpgradeOverlay abort={() => setShowUpgradeOverlay(false)}></UpgradeOverlay> }
	
			<div id="assetLinkDetails" className="container pageContainer ">

		{ isUploadingNewFiles && <UploadAsset {... props} closeOnBgClick={true} onFinished={ uploadSucceeded} assetLink={link} > </UploadAsset> }

		{ showQr && <OverlayContainer className="qrOverlay" abort={() => setShowQr(false)} > 
			{ !props.isMobile  && <QrCodeToLink  size={180} fgColor="#000000" showDownloadButton onClick={() => setShowQr(false) } t={props.t} value={link.linkAddress} > </QrCodeToLink> }
		</OverlayContainer>	
		}

		<div className="row">

			<div className="col-sm" >

				<form onSubmit={handleSubmit}>



				{/* <div className="text-center mt-2 dropzoneParent" >
					<Dropzone	
						onDrop={onDrop}
					
						minSize={Utilities.minUploadFileSize}
						maxSize={Utilities.maxUploadFileSize}
						multiple>
						{
							({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => 
							{
								const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > Utilities.maxUploadFileSize;
								return (
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										{!isDragActive && props.t('clickHereOrDragNDrop')}
										{isDragActive && !isDragReject && props.t('dropIt')}
										{isDragReject && props.t('errorFileTypeNotAccepted')}
										{isFileTooLarge && (
											<div className="text-danger mt-2">
												{props.t('errorFileTooLarge')}
											</div>
										)}
									</div>
								)
							}
						}
					</Dropzone>
				</div> 

							<ul className="list-group mt-2">
					{acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
						<li className="list-group-item list-group-item-success" key={acceptedFile.name}>
							{acceptedFile.name}
						</li>
					))}
				</ul>
				*/}

				{/* <div className="form-group">
						<label htmlFor="Name">{props.t('name')}</label>
						<input onChange={linkPropertyChanged} name="name" value={link.name}  type="text" className="form-control" id="Name" placeholder={props.t('namePlaceHolder')} />
				</div> */}


				<div className="form-group">
					<div className="pageTitle"> { link.name } </div>
				</div>

				<div className="form-group">
					<label htmlFor="CustomUrl"> {props.t('CustomUrl')}</label>

					<div className="form-row customUrl form-control">
						<a href={fullUrl} target="_blank" > <label htmlFor="CustomUrl" name="linkAddress"><span>/{link.linkAddress.split("/")[0]}/</span><span>{link.linkAddress.split("/")[1]}</span></label> </a>
						<div className="btn btn-link" onClick={copyToClipboard}>copy</div>
					</div>
					{/* <input onChange={linkPropertyChanged} name="linkAddress" value={link.linkAddress} type="text" className="form-control" id="linkAddress" readOnly={true}  /> */}
				</div>

				{/* <div className="form-group">
					<label htmlFor="DisplayName"> {props.t('displayName')}</label>
					<input onChange={linkPropertyChanged} name="displayName" value={link.displayName} type="text" className="form-control" id="DisplayName" placeholder={props.t('displayNamePlaceholder')} />

				</div> */}


				<div className="twoColumns"> 
					<div className="form-group">
						<label > {props.t('Files')}</label>
						{/* <div className="pl-1">
							{ draft.activeAssetTransformation.asset.files && draft.activeAssetTransformation.asset.files.split(";").map ( (val, index) => { return <div key={index}> {val} </div>}) }	
						</div>
					*/}

						<div className="form-row">
							<button  onClick={uploadNewFiles} type="button" className="clearall" className= {"btn colorButton files " } ></button> 
						</div>


					</div>
					
				
				

					<div className="form-group">
					
						<label className={(isNotPaying ? "isNotPaying" : "")} > {props.t('QR Code')}</label>

						{  (paymentStatus != null) && <div className={"form-row"} onClick={showQrClicked}>
							<button  type="button" className= {"btn colorButton qr " + (isNotPaying ? "isNotPaying" : "")  } ></button> 
						</div>}

					</div>
					{/* <UrlEditor t={props.t}  onChange={linkPropertyChanged} name="linkAddress" readOnly={true} value={link.linkAddress}></UrlEditor> */}

					{/* <label htmlFor="Link">CustomUrl</label>
					<div className="input-group mb-3">
						
						<div className="input-group-prepend">
							<span className="input-group-text" id="emailpre">{combinedPrefix}</span>
						</div>
						<input onChange={linkPropertyChanged} name="linkAddress" value={changeAbleLinkPart} type="text" className="form-control" id="Link" aria-describedby="emailpre" />
						<div >
							<a href={fullLink}><span >lnk</span></a>
						</div>
					</div> */}
				</div>	
				<div className="twoColumns"> 
					<div>
						<label className={(isNotPaying ? "isNotPaying" : "")}>{props.t('lblBgSkyboxImage')}</label>
						<div onClick={showPayOverlayIfNeeded}  className="form-row">

						{/* { draft.bgImage && draft.bgImage.length > 0 && <div className={"form-group col-md-3 d-flex" }> { renderImagePicker() } </div> }

							{ (!draft.bgImage || draft.bgImage.length <= 0) &&  } */}

							<div className={"form-group col-md-3 d-flex " + (isNotPaying ? "isNotPaying" : "")  }> { renderImagePicker() } </div> 
						</div>
					</div>
					
					
					<div>
						<label className={(isNotPaying ? "isNotPaying" : "")}>{props.t('bgColor')}</label>
						<div onClick={showPayOverlayIfNeeded}  className="form-row">
							<ColorPicker  isNotPaying={isNotPaying}  name="bgColor" onChange={settingsPropertyChanged} color={draft.bgColor}></ColorPicker>
						</div>
					</div>
				</div>
				<div className="twoColumns"> 
					<div>
						<label className={(isNotPaying ? "isNotPaying" : "")}>{props.t('fgColor')}</label>
						<div onClick={showPayOverlayIfNeeded} className="form-row" >
							<ColorPicker isNotPaying={isNotPaying}  name="fgColor" onChange={settingsPropertyChanged} color={draft.fgColor} ></ColorPicker>
						</div>
					</div>

					<div>
						<label className={(isNotPaying ? "isNotPaying" : "")}>{props.t('textColor')}</label>
						<div onClick={showPayOverlayIfNeeded} className="form-row" >
							<ColorPicker isNotPaying={isNotPaying} name="textColor" onChange={settingsPropertyChanged} color={draft.textColor} ></ColorPicker>
						</div>
					</div>
				</div>

				<div>
					
					{  !hasProduction && <button type='button' onClick={publish}  className="btn btn-ts btn-al publish">{props.t('publish')}</button> }

					{  hasProduction && hasChanges && <button type='button' onClick={publish}  className="btn btn-ts btn-al republish">{props.t('republish')}</button> }

					{  hasProduction && !hasChanges && <button type='button' onClick={unpublish}  className="btn btn-ts btn-al unpublish">{props.t('unpublish')}</button> }
				</div>



					{  ((hasProduction && !hasChanges) || !hasProduction) && <div  className="d-flex justify-content-center lnkButtonLine first"> <button type='button'  onClick={deleteLink} className="btn btn-link underline">{props.t('delete')}</button> </div> }
{ hasProduction && hasChanges && <Fragment>
				<div  className="d-flex justify-content-center lnkButtonLine first">
					{/* <button className="btn btn-primary m-2">{props.t('save')}</button> */}
					
					{/* {  hasProduction &&  <button type='button' onClick={unpublish} className="btn btn-primary m-2">{props.t('unpublish')}</button> } */}
					<button type='button' onClick={unpublish} className="btn btn-link underline pr-1">{props.t('unpublish')}</button><span className="textBetweenButtons"> or </span><button type='button'  onClick={deleteLink} className="btn btn-link underline pl-1">{props.t('delete')}</button> 
				</div>

				<div  className="d-flex justify-content-center lnkButtonLine">
					<button type='button' onClick={restore} className="btn btn-link underline pr-1">{props.t('restore')}</button><span className="textBetweenButtons"> published version</span>  
				</div>


			</Fragment> 
}


	
			</form>
		</div>
						
	{ authToken && <ArModelViewer isMobile={props.isMobile} t={props.t} link={link} token={authToken} isBusy={isBusy}></ArModelViewer> }
			

	</div> 
	</div>
	</div>);
	

	 
}


