import React, { Component } from 'react';

export default class Loading extends Component {


	constructor(props) {
		super(props);

    }

    render() {
        return <div className= {"loadingOverlay" + (this.props.fullScreen ? " fullScreen" : "" ) }>
          <div className="verticalcenter">
            <div className="horizontalcenter">

                    {/*  <svg className="rotate360" width="64px" height="64px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            
                      <title>Loading_Icon</title>
                      <desc>Created with Sketch.</desc>
                      <defs>
                          <polygon id="path-1" points="0.593163043 0.207080851 12.6404348 0.207080851 12.6404348 12.2149106 0.593163043 12.2149106"></polygon>
                          <polygon id="path-3" points="0.894467391 0.509957447 12.9430543 0.509957447 12.9430543 12.5129021 0.894467391 12.5129021"></polygon>
                      </defs>
                      <g id="Loading_Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g id="Group-19" transform="translate(5.000000, 4.000000)">
                              <g id="Group-3" transform="translate(41.847826, 22.128698)">
                                  <mask id="mask-2" fill="white">
                                      <use href="#path-1"></use>
                                  </mask>
                                  <g id="Clip-2"></g>
                                  <path d="M6.61805435,0.207080851 C3.29055435,0.207080851 0.593163043,2.89984681 0.593163043,6.21337872 C0.593163043,9.52452766 3.29055435,12.2149106 6.61805435,12.2149106 C9.9443587,12.2149106 12.6405543,9.52452766 12.6405543,6.21337872 C12.6405543,2.89984681 9.9443587,0.207080851 6.61805435,0.207080851" id="Fill-1" fill="#B3B3B3" mask="url(#mask-2)"></path>
                              </g>
                              <path d="M42.2496848,7.38461277 C40.7084891,7.38461277 39.1672935,7.97082553 37.9907717,9.14086809 C35.6377283,11.4892936 35.6377283,15.2889532 37.9907717,17.6326128 C39.1672935,18.8038468 40.7084891,19.3900596 42.2496848,19.3900596 C43.7920761,19.3900596 45.3332717,18.8038468 46.5109891,17.6326128 C48.8604457,15.2889532 48.8604457,11.4892936 46.5097935,9.14325106 C45.3332717,7.97082553 43.7908804,7.38461277 42.2496848,7.38461277" id="Fill-4" fill="#D9D9D9"></path>
                              <path d="M42.2508804,37.2907574 C40.7084891,37.2907574 39.1660978,37.8769702 37.9895761,39.0493957 C35.6389239,41.3930553 35.6365326,45.1950979 37.9895761,47.5387574 C39.1649022,48.7099915 40.7060978,49.2950128 42.2472935,49.2950128 C43.7896848,49.2950128 45.3332717,48.7088 46.5097935,47.5363745 C48.8604457,45.1950979 48.8616413,41.3930553 46.5109891,39.0493957 C45.3344674,37.8769702 43.7920761,37.2907574 42.2508804,37.2907574" id="Fill-6" fill="#8C8C8F"></path>
                              <g id="Group-10" transform="translate(20.326087, 0.681889)">
                                  <mask id="mask-4" fill="white">
                                      <use href="#path-3"></use>
                                  </mask>
                                  <g id="Clip-9"></g>
                                  <path d="M12.9430543,6.51137021 C12.9430543,9.82728511 10.245663,12.5129021 6.91816304,12.5129021 C3.5918587,12.5129021 0.894467391,9.82728511 0.894467391,6.51137021 C0.894467391,3.1978383 3.5918587,0.509838298 6.91816304,0.509838298 C10.245663,0.509838298 12.9430543,3.1978383 12.9430543,6.51137021" id="Fill-8" fill="#404042" mask="url(#mask-4)"></path>
                              </g>
                              <path d="M27.24425,43.4847149 C23.9179457,43.4847149 21.2205543,46.1727149 21.2205543,49.4838638 C21.2205543,52.8021617 23.9179457,55.4901617 27.24425,55.4901617 C30.57175,55.4901617 33.2691413,52.8021617 33.2691413,49.4838638 C33.2691413,46.1727149 30.57175,43.4847149 27.24425,43.4847149" id="Fill-11" fill="#666669"></path>
                              <path d="M6.0248913,34.3438468 C2.69858696,34.3438468 0,31.6534638 0,28.3423149 C0,25.028783 2.69858696,22.336017 6.0248913,22.336017 C9.35,22.336017 12.0473913,25.028783 12.0473913,28.3423149 C12.0473913,31.6534638 9.35,34.3438468 6.0248913,34.3438468" id="Fill-13" fill="#404042"></path>
                              <path d="M16.4997609,47.5362553 C14.1455217,49.8822979 10.3337826,49.8822979 7.98073913,47.5362553 C5.62769565,45.1949787 5.62769565,41.3929362 7.98073913,39.0468936 C10.3337826,36.705617 14.1455217,36.705617 16.4997609,39.0492766 C18.8516087,41.3929362 18.8516087,45.1949787 16.4997609,47.5362553" id="Fill-15" fill="#404042"></path>
                              <path d="M7.98073913,17.6349957 C5.6265,15.2913362 5.6288913,11.4892936 7.98073913,9.14325106 C10.332587,6.79959149 14.1467174,6.79720851 16.4997609,9.14325106 C18.8528043,11.4892936 18.8516087,15.2913362 16.4997609,17.6326128 C14.1467174,19.9762723 10.332587,19.9762723 7.98073913,17.6349957" id="Fill-17" fill="#404042"></path>
                          </g>
                      </g>
                  </svg>*/}

                   <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div> 
                  </div>

            </div>
         </div>
	}   
}