
import { useState, useEffect } from 'react';

import User from './User'


function useLoadOnce(request, props){
    var [data, setData] = useState(null);
    var [isLoading, setIsLoading] = useState(false);

    var doLoad = true;
    if (doLoad && props && props.auth && !props.auth.isAuthenticated ){ //only load data when not authenticated
        doLoad = false;
    }

    if (doLoad && props && props.history && props.history.location.pathname.startsWith("authentication/") ){ //dont load data too early, auth callback
        doLoad = false;
    }

    var loadData = async () => {
        var data = await request();
        if (data != null)
            setData(data);
    };

    useEffect( ()=> {
        
        if (!isLoading && doLoad){
            console.log("loading...");
            setIsLoading(true);
            loadData();
            
        }
    });

    return data; 
}

export function usePaymentStatus(props) {


	var data = useLoadOnce(async () => await User.GetPaymentStatus(), props);
	return data;
}

export function useUserSettings(props) {

	var data = useLoadOnce(async () => await User.GetUserSettings(), props);
	return data;
}
