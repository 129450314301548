import React, { Component, useState } from 'react';
import './Faq.css'
import './public.css'

export default function Faq (props)
{

  var faqData = [
    {q:"	What is Web-Ar.space?	",a:"	Web-Ar.space is a web augmented reality (Web-AR) platform. This Web-AR platform offers a number of services and functions for publishing (web) augmented reality experiences via a web browser. Contents (e.g. 3D models, sound, animations, pictures) can be uploaded and modified by different functions. This content is published in the form of an augmented reality link.	"},
    {q:"	Is there any terminology I need to know before getting started?	",a:"	AR: AR is short for Augmented Reality; AR Trigger: Enables the loading and display of AR experiences. Content: Is the content that is supported to be used in Web-Ar.space, such as 3D Models, Images and Sound.	"},
    {q:"	What is Web Augmented Reality?	",a:"	Web AR is a type of augmented Reality that works within a browser on your smartphone and does not require the use of an app. 	"},
    {q:"	What is the user journey?	",a:"	You need your users to know that there is an AR experience available. If you place your AR Triggers, where users do not expect an AR experience, try to explain the need for action in a clear and compelling way. The user sees a digital or physical touch point, such as a digital banner or button, printed QR code on flyers, or a link on social media and clicks on it or scans it with the smartphone Camera (QR Code Reader). The AR experience is triggered and user engaes in an immersive augmented reality experience.	"},
    {q:"	Which AR Triggers can I use?	",a:"	As AR triggers you can create Web AR links or Web AR QR codes, both of which are linked to your content.	"},
    {q:"	Where will my AR experience be viewed?	",a:"	Web AR can be launched on your website, emails, social media posts, printed flyers (QR Code) etc..You can place your Web AR links or QR Codes anywhere you want. 	"},
    {q:"	Which Browsers are supported?	",a:"	Web-AR is supported by the most common mobile web browsers.	"},
    {q:"	Are desktop Browsers supported?	",a:"	You can view your content on a Website on the most common Desktop Browser. You can also embed the Web View on your Website. Viewing content in AR on Desktop Browsers is not supported. 	"},
    {q:"	Which Smartphones are supported?	",a:"	Basically every Android phone that is AR-Core capable and all iPhones from iPhone 6S on.	"},
    {q:"	Which Content Type is supported?	",a:"	Currently we support: For 3D Models .FBX, .GLTF, .OBJ, .DAE, .3DS; For Image: .PNG, .JPG ;For Sound: .mp3; Videos are not supported at the moment.	"},
    {q:"	Can I use Animations in my 3D models.	",a:"	Yes. In the 3D Model included animation is imported. The included animation is looping, when beeing activated. Be aware, if more animations are included, the system is only using the first animation.	"},
    {q:"	How long will my AR Triggers be live for?	",a:"	It's completely up to you. You can decide how long your Web AR experiences are public. You can publish and unpublish AR Triggers anytime.	"},
    {q:"	How can I embed AR Triggers on my website?	",a:"	You can show the Web AR QR codes on your website/blog/social media, or link the Web AR links to buttons. If you want to embed the 3D view directly to your website, please contact us at support@web-ar.space 	"},
    {q:"	Can I use Web-Ar.space for free?	",a:"	Yes. The free subscription offers users the option of using 2 Web AR links (with a watermark from Web-Ar.space), including the associated Web AR-QR codes, and a total of 200 views per month. You are not able to extend your monthly views with your free subscription.	"},
    {q:"	How much does Web-Ar.space cost?	",a:"	Depends on you subscription. See all subscriptions here: https://web-ar.space/#Plans	"},
    {q:"	Can I use Web-Ar.space commercially?	",a:"	Yes all subscriptions are suited to be used commercially. 	"},
    {q:"	What if I exceed my total monthly views?	",a:"	Every subscription includes a certain number of views per month, if you exceed those, we will inform you in advance. If reach 100% of your views, you will be charged per additional view. The rate of the additional view depends on your subscription.  See all subscriptions here: https://web-ar.space/#Plans	"},
    {q:"	What is a view, and how is my total count of views calculated?	",a:"	A view is counted if a user views your AR Trigger. Your total amount of views included in your subscription, is the sum of all views per AR Trigger per month.	"},
    {q:"	Are you offering custom development and custom 3D Model creation?	",a:"	Yes. Web-Ar.space offers customization services on basis of hourly rates. Contact us at support@web-ar.space for further information.	"},
    {q:"	How can I reach out to you?	",a:"	Send us an email directly to support@web-ar.space 	"},
    
  ];

  return (
      <div className="public faq" id="ContactUs">

          <div className="pagePadding"></div>
          <div className="contactUsContainer ColorBlock" >
            <div className="CenteredText">
              You need help? <br/>
              Drop us a line.
            </div>

            <a href="mailto:support@web-ar.space" className="btn contactUsButton">support@web-ar.space</a>
          </div>


          <div className="faqContainer" id="Faq">
            <div className="faqHeader">
              <h2>Answers</h2>

            </div>

           <div className="qaLineContainer" > 
            {
                faqData.map( (faqEntry, index) => {

                  return <div className="line" key={index}> <div className="bubbleContainer"> <div className="question">{faqEntry.q}</div></div> <div className="bubbleContainer"><div className="answer">{faqEntry.a}</div></div> </div>
                })

            }
            </div>
          </div>


      </div>
    );
}
