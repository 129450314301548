import React, { Component, Fragment } from 'react';

import { ChromePicker } from 'react-color';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';

export default class ColorPicker extends Component {

	constructor(props) {
		super(props);


		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(value){
		this.props.onChange({
			name:this.props.name,
			value:value.target.value
		});
	}
	handleChangeComplete = (color) => {
		this.props.onChange( {
			name:this.props.name,
			value:color.hex 
		});
	  };
	render() {
		const placement = 'right';
		const MenuIcon = (props) =>(
            <svg xmlns="http://www.w3.org/2000/svg" height="38px"  width="100%" fill={props.fill} className={props.class}>
				<rect x="0" height="100%" y="0" width="100%" />
			</svg>
			);
			
		return <Fragment>  
						<div className={"form-group d-flex "   + (this.props.isNotPaying ? "isNotPaying" : "")  }  >

							<div style={{minWidth:"90px", marginRight:"10px"}} className="d-flex">
								{ this.props.children }

					
									<OverlayTrigger
										trigger="click"
										key={placement}
										placement={placement}
										rootClose={true}
										overlay={
											<Popover id={"colorPickerPopOver"}>
											{/* <Popover.Title as="h3">{`Popover ${placement}`}</Popover.Title> */}
											<Popover.Content>
												<ChromePicker disableAlpha color={ this.props.color } onChangeComplete={ this.handleChangeComplete }></ChromePicker>
											</Popover.Content>
											</Popover>
										}>
											
											<button type="button"  className= {"colorButton"  + (this.props.children ? " small ml-1" : "") } style={{background : this.props.color}} > </button> 
								
											
											
										</OverlayTrigger>
								

							</div>
							{/* <input type="text" value={this.props.color} onChange={this.handleInputChange}  className="form-control" id={this.props.label} /> */}
						</div>
</Fragment>

	
	}
}