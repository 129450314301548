import React, { Component, Fragment, useEffect, useState } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Request from './Request';
import Paths from './Constants';
import Cookies from 'js-cookie';

export function useAuthentication(){
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState(null);
    const [user, setUser] = useState(null);

    const  populateState = async () => {

        if (Request.TokensAvailable()){
            var user = await Request.Get(Request.Paths.User);
            if (user.ok){
                setUserName(user.data.name);
                setIsAuthenticated(true);
                setUser(user.data);
            }
            else
            {
               await logout();
            }
        }
        else{
            await logout();
        }
         
       console.log(user);

    }

    const logout = async () =>
    {
        setUser(null);
        setUserName(null);
        setIsAuthenticated(false);    
        Request.RemoveTokens();
    }

    useEffect( () => 
    {
 
        populateState();


        //const _subscription = authService.subscribe(() => populateState());
        
        //

        //return () => authService.unsubscribe(_subscription);
    }, []);


    return {
        userName : userName,
        isAuthenticated : isAuthenticated,
        logout : logout,
        populateState : populateState //,
        //profilePath : `${ApplicationPaths.Profile}`,
        //logoutPath : { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } },
        //registerPath : `${ApplicationPaths.Register}`,
        //loginPath : `${ApplicationPaths.Login}`
    }

}

//c