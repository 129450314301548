import React, { Component, Fragment } from 'react';
import './public.css';
import Request from '../common/Request';
import Utilities from '../common/Utilities';
import { Validation } from '../common/Constants';
import { Redirect } from 'react-router-dom';
import OverlayContainer from '../common/OverlayContainer';
import { useForm } from "react-hook-form";


export function Register(props){
    var auth = props.auth;
	  const { handleSubmit, register, getValues, errors, setError, clearError, formState, triggerValidation } = useForm();


    const submit = async (values) => {

      clearError("passwordsDontMatch")
      clearError("usernameAlreadyExists")
      clearError("emailAlreadyExists")

      if (values.password != values.passwordConfirmation){
        setError("passwordsDontMatch");
        return;
      }

      var result = await Request.Post("api/user/register", { Email: values.email, Password: values.password, Name: values.userName });
      console.log(result.ok);
      if (result.ok){
        Request.StoreAccessToken(result.data.accessToken);
        Request.StoreRefreshToken(result.data.refreshToken);
        auth.populateState();
        props.overlayStates.hide();
        props.history.push("/dashboard");
      }
      else
      {
        if (result.data == "username"){
          setError("usernameAlreadyExists");
        }
        else if (result.data == "email"){
          setError("emailAlreadyExists");
        }
  
      }
    };

    const onSubmit = (values) => {
      submit(values);
    };


  
    const close = async () => {
        if (props.overlayStates){
            props.overlayStates.hide();
        }
        else{
            return Redirect("/dashboard");
        }
    }

    var userVal = Validation.Form.UserName;
    var emailVal = Validation.Form.Email;
    var passwordVal = Validation.Form.Password;

    return <OverlayContainer className=""  abort={close}>
          <Fragment>
              <h1>Register</h1>
              
              <form onSubmit={handleSubmit(onSubmit)}>

              <div className="form-group">
                  <label className={((errors.userName || errors.usernameAlreadyExists)  ? " is-invalid" : "" )}>Username</label>
                  <input name="userName" className={"form-control" +  ((errors.userName || errors.usernameAlreadyExists)  ? " is-invalid" : "" )}  ref={register(userVal)} />
                  {errors.userName  && <div className="invalid-feedback">{props.t('uernameInvalid')} </div> }
                  {errors.usernameAlreadyExists  && <div className="invalid-feedback">{props.t('usernameAlreadyExists')} </div> }
                </div>

                <div className="form-group">
                  <label className={((errors.email || errors.emailAlreadyExists)  ? " is-invalid" : "" )}>Email</label>
                  <input name="email" className={"form-control" +  ((errors.email || errors.emailAlreadyExists)  ? " is-invalid" : "" )}  ref={register(emailVal)} />
                  {errors.email  && <div className="invalid-feedback">{props.t('emailInvalid')} </div> }
                  {errors.emailAlreadyExists  && <div className="invalid-feedback">{props.t('emailAlreadyExists')} </div> }
                </div>
  
                <div className="form-group">
                  <label className={((errors.password)  ? " is-invalid" : "" )}>Password</label>
                  <input name="password" className={"form-control" +  ((errors.password)  ? " is-invalid" : "" )}  type="password" ref={register(passwordVal)} />
                  {errors.password  && <div className="invalid-feedback"> {props.t('passwordInvalid')} </div> }
              
                </div>

                <div className="form-group">
                  <label className={((errors.passwordConfirmation || errors.passwordsDontMatch)  ? " is-invalid" : "" )}>Confirm password</label>
                  <input name="passwordConfirmation" className={"form-control" +  ((errors.passwordConfirmation || errors.passwordsDontMatch)  ? " is-invalid" : "" )}  type="password" ref={register(passwordVal)} />
                  {errors.passwordConfirmation  && <div className="invalid-feedback"> {props.t('passwordInvalid')} </div> }
                  {errors.passwordsDontMatch  && <div className="invalid-feedback"> {props.t('passwordsDontMatch')} </div> }
                </div>

  
                <button  className="btn btn-ts mb-2" type="submit">Register</button>
  
                <a className="btn btn-link pl-0 pr-0 gray w-100 text-center underline" onClick={ () => props.overlayStates.showOverlay(props.overlayStates.loginOverlayState) } >Log In</a>
              </form>
  
          </Fragment>
    
        </OverlayContainer>
}