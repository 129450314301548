import React from 'react';
import { HashLink as HashLink } from 'react-router-hash-link';



export function Footer(props)
{
    //sofort einblenden, wenn man in den sichtbaren bereich kommt
    /*const para = useVerticalScrollParallax  ( { factor : 1.0}); 
    ref={ para.ref } */
    return <div className="footerContainer" >
                <div className ="footer">
                    <div className="ts_row">
                        <div className="footer_col">
                            <h1 className="header">Company</h1>
                            <HashLink className="btn pl-0" to="/about#About">About</HashLink>
                            <HashLink className="btn pl-0" to="/Imprint">Imprint</HashLink>
                        </div>
                        {/* <div className="footer_col language">
                            <h1>Language</h1>
                            <LanguageBar  {...appProps} />
                        </div> */}
                        <div className="footer_col">
                            <h1 className="header">Social Media</h1>

                            <a className="btn pl-0" href={props.t("lnkInstagram")}>Instagram</a>
                            <a className="btn pl-0" href={props.t("lnkFacebook")}>Facebook </a>
                            {/* <div className="ts_row mw50">
                            
                                <a className="btn pl-0" href={appProps.t("lnkBehance")}>Behance </a>
                            
                        
                            </div> */}


                        </div>
                        <div className="footer_col">
                            <h1 className="header">Support</h1>
                            <HashLink className="btn pl-0" to="/faq#ContactUs">Support</HashLink>
                            <HashLink className="btn pl-0" to="/faq#Faq">FAQ </HashLink>

                        </div>
                    </div>
                    <div className="ts_row">
                        <div className="footer_col copyright">
                            <h1>© { new Date().getFullYear() } twospot GmbH</h1>
                
                        </div>
                        {/* <div className="footer_col language">
                            <h1>Language</h1>
                            <LanguageBar  {...appProps} />
                        </div> */}
                        <div className="footer_col">

                        </div>
                        <div className="footer_col">
                            <h1> <HashLink className="terms_privacy" to="/TermsAndPrivacy#Terms" >Terms of service, Privacy policy </HashLink></h1>

                        </div>
                    </div>
                </div> 
            </div>
}