import React, { Fragment } from 'react';

export function Imprint(props){

    return <div id="Imprint" className="pageContainer container">

        <p> 
        Responsible for the content — <br/>
        Media owner and publisher: <br/>
        WEB•AR by twospot GmbH <br/>
        Mariahilferstraße 118/1/33 <br/>
        1070 Wien <br/>
        support@web-ar.space <br/>
        </p>

        <p> 
        Managing directors: <br/>
        Michael Leithner BA , Felix Heuritsch BSC <br/>
        Company register: FN479858z <br/>
        Commercial court: HG Wien <br/>
        UID-Number: ATU 72696736 <br/>
        Company Headquarters: Wien <br/>
        Supervisory Authority: BH Wien <br/>
        Trade regulations: Trade regulations <br/>
        www.ris.bka.gv.at <br/>
        Member of the Vienna Economic Chamber <br/>
        </p>

        <p>
        Bank details: Erste Bank, twospot GmbH <br/>
        IBAN: AT 042011183787665400 <br/>
        BIC: GIBAATWWXXX <br/>
        </p>

        <p>
        The website www.web-ar.space contains various links. Despite careful control, we assume no liability for the
        content of external links. For this only their operators are responsible. twospot.com has taken the utmost care
        in creating the website. However, there is no guarantee for the completeness, accuracy and timeliness of the
        data, information and downloads. The content contained on the web-ar.space website, such as texts, images,
        logos, graphics, audio works, animations and videos are protected by copyright.
        </p>

        <p> 
        Leaf line: The web portal is used to promote the goods and services we distribute and the presentation of our
        company.
        </p>

        <p>Copyright: All texts, data, pictures. All rights reserved. Product photos of the respective manufacturer.</p>


    </div>

}