
import User from './User';

class Url {


    async getFullLink(url){
        return await this.getPrefix() + this.getLastLinkPart(url);
    }

    async getPrefix(){
        var userSetting = await User.GetUserSettings();
        return window.location.origin + "/view/" + userSetting.userPrefix + "/"
    }
    
    getLastLinkPart(url){
        if (url){
            var split = url.trim("/").split("/");
            url = split[split.length-1];
        }    
        return url;
    }

}

export default new Url();