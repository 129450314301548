

import React, {useState } from 'react';
import { Redirect } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import {usePaymentStatus} from '../common/Hooks';

import './OrderStatus.css'

export default function OrderStatus(props){
    var to = "/dashboard";
    var paymentStatus = usePaymentStatus();


    if (!paymentStatus)
        return null;

    var title =  props.t("orderSuccess" );
    var buttonText = props.t("navBackToMyLinks" );

    return <div className="container pageContainer orderStatus">

        <div className="t100 mb-5 mt-5">  {title}</div>
        
        <div className="l40 mb-5 pb-5"> 
            {  props.t("orderSuccessThankYou1" )}
            <br/> 
            {  props.t("orderSuccessThankYou2" )}
        </div>

        <HashLink className="btn btn-ts noCap"  to={to}> { buttonText } </HashLink>
    </div> 
}