import React, { Component, useEffect } from 'react';
import './public.css';
import { useAuthentication } from '../common/Authentication';
import { Redirect } from 'react-router-dom';

export function Logout(props){

    var auth = props.auth;

    useEffect( () => {
      auth.logout();
    }, [])
 

    return Redirect("/dashboard");
 
}
