import React, { Component, useState } from 'react';
import './public.css';

import {QrCodeToLink} from '../common/QrCodeToLink';
import {SampleUrls} from '../common/Constants';
import Loading from '../common/Loading';
import './Intro.css';


export function Intro (props){

  var [gesturesEnabled, setGesturesEnabled] = useState(false);

  var redirectUrl = encodeURI(window.location.href + "dashboard");
  var hashIndex = window.location.href.indexOf("#");
  if ( hashIndex > 0){
    redirectUrl = encodeURI(window.location.href.substr(0, hashIndex ) + "dashboard");
  }
  



  var modelViewerProps = gesturesEnabled ? { "camera-controls" : "camera-controls"  } : {};
  return (
    <div id="Intro" >
      <div className="public Intro">

          <h1> Create Augmented </h1>
          
          <h1> Reality Links </h1>
          {/* 
          <a  href={`${ApplicationPaths.Register}?${QueryParameterNames.ReturnUrl}=${redirectUrl}`}  className="promo">
              <div className="promoBg"></div>
              <div className="promoText"> </div>
            </a>
          */}

          <div className="introImage">

          {/* { !gesturesEnabled && <div onClick={() => setGesturesEnabled(true)}>Tap to unlock gestures</div> } */}


            <model-viewer id="welcomeAr" ar="true" autoplay="true" shadow-intensity="0"  onClick={() => setGesturesEnabled(true)}
                src={window.location.origin +  SampleUrls.intro.modelUrl } 
                ios-src={window.location.origin +  SampleUrls.intro.modelUrliOs }
                background-image="/images/Background.jpg" auto-rotate { ... modelViewerProps }  //camera-controls
                // skybox-image="https://modelviewer.dev/shared-assets/environments/spruit_sunrise_1k_HDR.hdr"  background-color="#581717" 
                alt="&quot;test&quot; powered by weholo.at"> 

    
                <button slot="ar-button" className="switchToArButton"  >
                    { props.t("ActivateAR") } 
                </button>  
                
                <QrCodeToLink  t={props.t} relativePath = { SampleUrls.intro.webArLink }> </QrCodeToLink>

            </model-viewer>

         
    
            <div className="innerIntroUpper" >

            </div>
            <div className="innerIntroLower" >
                
            </div>
            {/* <div className="qr"></div> */}
          </div>

      </div>
      </div>
    );
}
