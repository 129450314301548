import update from 'immutability-helper';


import { Paths } from './Constants';
 
class Utilities{
    
    constructor(){
        this.minUploadFileSize = 0;
        this.maxUploadFileSize = 20242880;
        this.AzureStorageUrl = "https://webarstorage.blob.core.windows.net";

        this.showAlert = this.showAlert.bind(this);
        this.subscribeForAlerts = this.subscribeForAlerts.bind(this);
    }

    copyUpdate(original, changes){
        return update(original, changes);
    }


    subscribeForAlerts(addAlert){
        this.addAlert = addAlert;
    }

    showSuccess(message){
        this.showAlert({ message : message , type : 'success' });
    }

    showResponse(response, t){
        if (response.ok){
            if (response.url.endsWith(Paths.SubscribeTo)){
                this.showSuccess(t("orderSuccess"))
            }
            else if (response.url.endsWith(Paths.Publish)){
                this.showSuccess(t("msgPublishSuccess"))
            }
            else{
                this.showSuccess(t("msgChangeSuccess"))
            }
           
        }
        else
        {
            if (response.statusCode == 507 && response.url.endsWith(Paths.Publish))
                this.showError(t("linkLimitReached"));
            else if (response.statusCode == 409)
                this.showError(t("urlAlreadyTaken"));
            else
                this.showError(t("defaultError"));
        }
    }

    showErrorRespone(response, t){
    
        if (response.statusCode == 409)
            this.showError(t("urlAlreadyTaken"));
        else
            this.showError(t("defaultError"));
    }

    showError(message){
        this.showAlert({ message : message , type : 'danger' });
    }

    showAlert(alert){
        if (this.addAlert != null && this.addAlert !== 'undefined'){
            this.addAlert(alert);
        }
    }

    handleResponse( response, translate){
        if (response.statusCode == 409){
            return translate("ErrorAlreadyTaken");
        }
        else {
            return translate("respone.statusCode");
        }
    }


    // async getUser(){
    //     const usr = authService.getUser();
    //     console.log(usr);
    //     return usr;
    // }



}

export default new Utilities();