import React, { Component, Fragment, useState, useEffect } from 'react';
import Request from '../common/Request';
import Utilities from '../common/Utilities';
import './Settings.css';

import User from '../common/User';

import { Redirect } from 'react-router';

import { Logout } from '../public/Logout';

import {PlansData, Validation }  from '../common/Constants';

import { StrypeWrapper, PaymentStatus } from './PaymentMethod';

import { useForm } from "react-hook-form";

export const Modes = {
    changeEmail: 'changeEmail',
    changeName: 'changeName',
    changePassword: 'changePassword',
    changeLink: 'changeUrl',
    deletingAccount : 'deletingAccount',
    changePlan : 'changePlan',
    changePaymentMethod : 'changePaymentMethod'
  };

  const Inputs = {
    newEmail: 'newEmail',
    newName: 'newName',
    oldPassword: 'oldPassword',
    newPassword: 'newPassword',
    confirmPassword : 'confirmPassword',
    newUrl: 'newUrl'
};

export function Settings (props)  {

    const [mode, setMode] = useState(null);

    const [email, setEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');

    const [url, setUrl] = useState('');

    const [userName, setUserName]  = useState('');
    
    
    

    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const [needsInit, setNeedsInit] = useState(true);
    const [rdy, setRdy] = useState(false);
    const [accountDeleted, setAccountDeleted] = useState(false);
   

    const [totalAvailableActiveLinks, setTotalAvailableActiveLinks] = useState('');

    const [currentPlan, setCurrentPlan ]= useState(null);

    const [userSetting, setUserSetting ]= useState(null);

    const [focus, setFocus] = useState(false);

    const [paymentStatusRdy, setPaymentStatusRdy] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);



    const loadData = async () => {
        await loadUserData();
        await loadPaymentStatus();
    }


    useEffect( () => {
        if (needsInit){
            setNeedsInit(false);
            loadData();
        }
        return () => {};
    });




    const  loadPaymentStatus = async () => {
        var paymentStatus = await User.GetPaymentStatus();

        setPaymentStatusRdy(true);
        setPaymentStatus(paymentStatus);
    }

    const loadUserData = async () => {

        var result = await User.Get();
        var userSetting = await User.GetUserSettings();

        setRdy(true);

        setUrl(result.link);


        setUserName(result.name);
    

        setEmail(result.email);
        setNewEmail(result.email);

        setCurrentPlan(result.currentProduct);
        
        setTotalAvailableActiveLinks( result.totalAvailableActiveLinks);
        

        setUserSetting(userSetting);

    }

    
    const changeClicked = async (ev) => { 
        setMode(ev.target.id);
        setFocus(true);

    }

    const abortClicked = (ev) => {
        setMode(null);
    }

    const submitClicked = async (ev) => {
        ev.preventDefault();
        switch( mode){

            case Modes.changeEmail:
            var newEmail = newEmail;
            var result = (await Request.Post(Request.Paths.ChangeEmail, newEmail ));

            if (result.ok){
                Utilities.showSuccess (props.t("msgChangeSuccess"));
                setMode(null);
            }
            else
            {
                Utilities.showErrorRespone(result, props.t);
            }
            break;
        case Modes.changePassword:


            break;

        case Modes.deletingAccount:

            var result = await Request.Post(Request.Paths.DeleteAccount );
            if (result.ok){
            
                Utilities.showSuccess (props.t("msgAccountDeleted"));
                setAccountDeleted(true);
            }
            else{
                Utilities.showErrorRespone(result, props.t);
            }
            
        break;
        }
    }

    const renderAcceptAndBackButton = () =>{
        return <div className="cancelConfirmContainer">
                <button type="button" onClick={abortClicked} className="btn btn-link cancel">{props.t("btnAbort")}</button>
                <button type="button" onClick={submitClicked} className="btn btn-link confirm">{props.t("btnConfirm")}</button>
        </div>
    }

    const renderAcceptAndBackButton2 = () =>{
        return <div className="cancelConfirmContainer">
                <button type="button" onClick={abortClicked} className="btn btn-link cancel">{props.t("btnAbort")}</button>
                <button type="submit"  className="btn btn-link confirm">{props.t("btnConfirm")}</button>
        </div>
    }

    const propertyChanged = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

     //   var upd = { [name] :  value};

       // setState(upd);
    }

    const labelClasses = "";
    const buttonClasses = "btn btn-link btnChange";
        
    var planDisplayName = "";
    if (paymentStatusRdy &&  paymentStatus){
        planDisplayName = paymentStatus.currentPlan; 
        var planData =  PlansData.filter(p => p.id == paymentStatus.currentPlan);
        if (planData.length > 0)
        {
            planDisplayName = planData[0].title + ' ' + planData[0].subtitle; 
        }
    }
    else{
        planDisplayName = "free";
    }




    const renderErrorLine = (errors) => {
        console.log(errors);
        if (errors)
        {
            return  <div className="row oneCol" >
                { errors && errors.message && <div className="invalid-feedback" style={{"display":"block"}}>
                    { errors.message }
                </div> } 
        
                { errors && !errors.message && errors.type && <div className="invalid-feedback" style={{"display":"block"}}>
                    { props.t(errors.type) }
                </div> } 
            </div>
        }
        else
        {
            return null;
        }

    }
    
    const urlForm = useForm();
    const renderUrl = () => {

        const { handleSubmit, register, getValues, errors, setError, clearError, formState } = urlForm;
        var prefix = window.location.origin + "/view/"; // userSetting.domain
        

        var ref = register( Validation.Form.UserLink);
        
        const onSubmit =  async data => {
            var result = (await Request.Post(Request.Paths.ChangeLink, data.newUrl ));
            if (result.ok){
                Utilities.showSuccess (props.t("msgChangeSuccess"));
          
                setMode(null);
                setUrl(data.newUrl);

                var userSetting = await User.GetUserSettings();
                userSetting.userPrefix = data.newUrl;
            }
            else
            {
                Utilities.showErrorRespone(result, props.t);
        
            }   
            
        }

        if (userSetting == null){
            return (<div> </div>);	
        }
    
        console.log(errors);

        return <Fragment>
            <div className="separator"></div>
            <form onSubmit={handleSubmit(onSubmit)}> 
                <div className="row threeCol">
                    <label className="col-form-label">URL</label>

                    
                
                
                
                    <div className="flex-grow-1 d-flex">
                        <label type="name" className="col-form-label " style={{ color: "#969696" }}   placeholder={props.t("phUrl")}> { prefix } </label>

                        { mode != Modes.changeLink  && <label style={{"textTransform": "lowercase"}} type="name" className="col-form-label "  placeholder={props.t("phUrl")}> {  url  } </label> }

                        { mode == Modes.changeLink  && <input style={{height:"unset"}} ref={ref} autoFocus   className={"form-control " + (errors.newUrl ? "is-invalid" : "") } type="text" id={Inputs.newUrl} name={Inputs.newUrl}  onChange={propertyChanged}  placeholder={props.t("UrlPlaceholder")} /> }
                    </div>
           

                    {  mode != Modes.changeLink && <button type="button" id={Modes.changeLink}  className="btn btn-link btnChange" onClick={changeClicked}>{ props.t("btnChange") }</button> }

                    { mode == Modes.changeLink  && renderAcceptAndBackButton2() }
   
                </div> 

                {  mode == Modes.changeLink && renderErrorLine(errors.newUrl) }

            </form>
           
        </Fragment>
    }
  
    const nameForm = useForm();
    const renderName = () => {
        const { handleSubmit, register, getValues, errors, setError, clearError, formState } = nameForm;
        var ref = register( Validation.Form.UserName);
        const onSubmit =  async data => {
            var result = (await Request.Post(Request.Paths.ChangeName, data.newName  ));
            if (result.ok){
                Utilities.showSuccess (props.t("msgChangeSuccess"));
                setMode(null);
                setUserName(data.newName);
            }
            else
            {
                Utilities.showErrorRespone(result, props.t);
            }
        }

        return <Fragment>
            <div className="separator"></div>
            <form onSubmit={handleSubmit(onSubmit)}> 
            <div className="row threeCol">
            <label className="col-form-label">Name</label>

           
            {  mode != Modes.changeName && <Fragment> 
                <label type="name" className="col-form-label flex-grow-1"  placeholder={props.t("NamePlaceholder")}> {userName} </label>
                <button type="button" id={Modes.changeName} className={buttonClasses} onClick={changeClicked}>{ props.t("btnChange") }</button> 
                </Fragment>
            }

            { mode == Modes.changeName  && <Fragment> 
                        
                <input  className="form-control flex-grow-1" type="text" autoFocus ref={ref} id={Inputs.newName} name={Inputs.newName}  placeholder="Name" />
                        {renderAcceptAndBackButton2()}
                </Fragment>  }
    
            </div>

            {  mode == Modes.changeName && renderErrorLine(errors.newName) }

        </form></Fragment>
    }

    
    const renderEmail = () => {

        return <Fragment>
                <div className="separator"></div>

                <div className="row threeCol">
                <label className="col-form-label">Email</label>

                
         
                {  mode != Modes.changeEmail && <Fragment> 
                    <label type="email" className="col-form-label flex-grow-1"  placeholder={props.t("EmailPlaceholder")}> {email} </label>
                    {/* <button type="button" id={Modes.changeEmail} className={buttonClasses} onClick={changeClicked}>Change</button>  */}
                    </Fragment>
                }

                { mode == Modes.changeEmail  && <Fragment> 
                            
                    <input  className="form-control flex-grow-1" type="text" id={Inputs.newEmail} name={Inputs.newEmail} value={newEmail}  onChange={propertyChanged}  placeholder="Email" />
                            {renderAcceptAndBackButton()}
                    </Fragment>  }

          
                 
      
            </div>
        </Fragment>
    }

    const passwordForm  =useForm();
    const renderPassword = () => {
        const { handleSubmit, register, getValues, errors, setError, clearError, formState } = passwordForm;
        var oldPasswordRef = register( Validation.Form.Password);
        var newPasswordRef = register( Validation.Form.Password);
        var confirmPasswordRef = register( Validation.Form.Password);
        
        const onSubmit =  async data => {
            console.log(data);
            if (data.confirmPassword != data.newPassword){
            //    setError(Inputs.newPassword, "passwordsDontMatch");

                setError(Inputs.confirmPassword, "passwordsDontMatch");
            
                return;
            }
            else
            {
            //    clearError(Inputs.newPassword);
                clearError(Inputs.confirmPassword);
            }
        
            if (data.oldPassword == data.newPassword){
                setError(Inputs.newPassword, "passwordIsOldPassword");
                return;
            }
            else
            {
                clearError(Inputs.newPassword);
            }


            var result = (await Request.Post(Request.Paths.ChangePassword, {
                OldPassword : data.oldPassword,
                NewPassword : data.newPassword,
                ConfirmPassword : data.confirmPassword
            }));

            Utilities.showResponse(result, props.t);

            if (result.ok)
                setMode(null);
        }

        return <Fragment>
        <div className="separator"></div>

        <form onSubmit={handleSubmit(onSubmit)}> 
        <div className="row threeCol">
            <label className="col-form-label">Password</label>

            
            {  mode != Modes.changePassword && <Fragment> 
                <label type="email" className="col-form-label flex-grow-1"> ********** </label>
                <button type="button" id={Modes.changePassword} onClick={changeClicked} className="btn btn-link btnChange">Change</button> 
              
                </Fragment>
            }

            { mode == Modes.changePassword  && <Fragment>  
                        
                <input autoFocus className="form-control flex-grow-1" type="password" id={Inputs.oldPassword} name={Inputs.oldPassword} ref={oldPasswordRef}  placeholder={props.t("phOldPassword")} />
                { props.isMobile && renderAcceptAndBackButton2()}


                </Fragment>  }

                

        </div>

        { mode == Modes.changePassword  && <Fragment>

            {  renderErrorLine(errors.oldPassword) }

            <div className="separator"></div>
            <div className="row threeCol">
                <label className="col-form-label"></label>
           
                <input className="form-control flex-grow-1" type="password" id={Inputs.newPassword} name={Inputs.newPassword}  ref={newPasswordRef}  placeholder={props.t("phNewPassword")} />
                                  
                              
            </div> 
            {  renderErrorLine(errors.newPassword) }


            <div className="separator"></div>
            <div className="row threeCol">
                <label className="col-form-label"></label>
           
                <input className="form-control flex-grow-1" type="password" id={Inputs.confirmPassword} name={Inputs.confirmPassword} ref={confirmPasswordRef}  placeholder={props.t("phConfirmPassword")} />
                { !props.isMobile && renderAcceptAndBackButton2()}
                   
            </div> 
            {  renderErrorLine(errors.confirmPassword) }

            </Fragment> }
        </form>
        </Fragment>
    } 


     

      return (

        <Fragment>
            { accountDeleted && <Logout inProcess={true}></Logout> }
            { (mode == Modes.changePlan) && <Redirect push to="/ChangePlan"></Redirect>}
            { (mode == Modes.changePaymentMethod) && <Redirect push  to={{
                                                                pathname: "/ChangePlan",
                                                                search: "?id=" + paymentStatus.currentPlan ,
                                                                state: { selectedPlanId : paymentStatus.currentPlan }}}></Redirect>}

        <div className="settings container pageContainer">
            <div className="mikesFunnyBorder"> 
 
                <h4 className="l30">{props.t("Profile")}</h4>

                
                {renderName() }

                {renderEmail()}


                { renderPassword() }
                
                {renderUrl()}



                <div className="separator"></div>


                <h4 className="mt-5 l30">{props.t("Payment")}</h4>



{ paymentStatusRdy && paymentStatus &&
    <Fragment>
        <div className="separator"></div>

        <div className="row threeCol">

            <label className="col-form-label">{props.t("CurrentPlan")} </label>

            <label type="name" className="col-form-label flex-grow-1" > {planDisplayName}</label>

            <button type="button" id={Modes.changePlan} className="btn btn-link btnChange" onClick={changeClicked}>{props.t("Change")}</button>  
     
        </div>

        {/* <div className="separator"></div>

        <div className="row">

            <label className="col-sm-2 col-form-label">{props.t("TotalAvailableLinks")} </label>

            <div className="col-sm-10 d-flex">

                <label type="name" className="col-form-label flex-grow-1" > { paymentStatus && paymentStatus.totalAvailableActiveLinks} </label>

         
            </div>
        </div>*/}
        <div className="separator"></div> 

        <StrypeWrapper stripeApiKey = {paymentStatus.stripeApiKey}>  
            <PaymentStatus paymentStatus={paymentStatus}  t={props.t}  metadata={ {  }} >


            </PaymentStatus>
        </StrypeWrapper>

        {/* <div className="row threeCol">

            <label className="col-form-label">{props.t("PaymentMethod")} </label>


            <label className="col-form-label flex-grow-1">{   paymentStatus && paymentStatus.card != null && <Fragment> {paymentStatus.card.brand} ***{paymentStatus.card.last4}  </Fragment> } </label>
    
            <button type="button" id={Modes.changePaymentMethod} className="btn btn-link btnChange" onClick={changeClicked}>{props.t("Change")}</button>  
        

        </div> */}
        <div className="separator"></div>
    </Fragment>
}

      
{/* 
            <PaymentMethod {... props}></PaymentMethod> */}

        <form onSubmit={submitClicked} className="deleteAccount">    
            <h4 className="mt-5 l30 ">{props.t("Delete Account")}</h4>

            <div className="separator"></div>
            <div className="row threeCol">

               

                    <label className="col-form-label flex-grow-1">{props.t("RlyDelete")}</label>
                    <div></div>
                    {  mode != Modes.deletingAccount && <button type="button" id={Modes.deletingAccount} className={buttonClasses} onClick={changeClicked}>{props.t("btnDelete")}</button>  }
                
            </div>

            {  mode == Modes.deletingAccount && <Fragment> 
                <div className="separator"></div>

                <div className="row">
                 
                    <label className="col-form-label flex-grow-1">{props.t("RlyRlyDelete")}</label>
                    <div></div>
                    {renderAcceptAndBackButton()}
           
                </div>
                </Fragment> }
            
            <div className="separator"></div>
        </form>
            {/* <div className="form-group row">
                <label htmlFor="inputPassword" className={labelClasses}>{props.t("LabelPassword")}</label>
                {
                    mode != Modes.changeEmail && <Fragment>
                        <div className="col-sm-8">
                            <label type="password" className={labelClasses} id="inputPassword" placeholder={props.t("EmailPlaceholder")}> test@test.at </label>
                        </div>
                        <div className="col-sm-2">
                            <button type="button" id={Modes.changeEmail} className={buttonClasses} onClick={changeClicked}>Change</button>
                        </div>
                    </Fragment>
                }
                {
                    mode == Modes.changeEmail && <Fragment>
                        <div className="col-sm-8">
                            <input type="password" className="form-control" id="inputPassword" placeholder="Email" />
                        </div>
                        {renderAcceptAndBackButton()}
                    </Fragment>
                }
            </div>

            <div className="form-group row">
                <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-10">
                <input type="password" className="form-control" id="inputPassword3" placeholder="Password" />
                </div>
            </div>
            <fieldset className="form-group">
                <div className="row">
                <legend className="col-form-label col-sm-2 pt-0">Radios</legend>
                <div className="col-sm-10">
                    <div className="form-check">
                    <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked />
                    <label className="form-check-label" htmlFor="gridRadios1">
                        First radio
                    </label>
                    </div>
                    <div className="form-check">
                    <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" />
                    <label className="form-check-label" htmlFor="gridRadios2">
                        Second radio
                    </label>
                    </div>
                    <div className="form-check disabled">
                    <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios3" value="option3" disabled />
                    <label className="form-check-label" htmlFor="gridRadios3">
                        Third disabled radio
                    </label>
                    </div>
                </div>
                </div>
            </fieldset>
            <div className="form-group row">
                <div className="col-sm-2">Checkbox</div>
                <div className="col-sm-10">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="gridCheck1" />
                    <label className="form-check-label" htmlFor="gridCheck1">
                    Example checkbox
                    </label>
                </div>
                </div>
            </div> */}

        
          

        </div>
        </div>
        </Fragment>);
  
}
