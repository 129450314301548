

import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from "react-dom";

export function useVerticalScrollParallax( props){

    var myRef = useRef();



    const handleScroll = (ev) => 
    {
  
     

        if (myRef.current){

            
            const node = ReactDOM.findDOMNode(myRef.current);
    
            var scrollPos = window.scrollY;



            var remainingScroll = ( node.offsetTop - scrollPos -200 ) / 2;
            if (remainingScroll > 0){
                node.firstChild.style.position = "relative";
                
                if (remainingScroll > 1000){
                    remainingScroll = 1000;
                }
                node.firstChild.style.top = remainingScroll + "px"; // "20px";
            }
            else{
                node.firstChild.style.top = 0 + "px"; 
            }
    

            // var factor = 1.25;
            // if (props && props.factor)
            //     factor = props.factor;
                

            // console.log(window.innerHeight);

            // if (scrollPos > (node.offsetTop - ( window.innerHeight / factor  ))){
            //     if ( !node.firstChild.classList.contains("shown"))
            //         node.firstChild.classList.add("shown");
            // }
            // else{
            //     if ( node.firstChild.classList.contains("shown"))
            //         node.firstChild.classList.remove("shown");
            // }



            // console.log(node, node.offsetTop, scrollPos);
        }
    
        
    }


    useEffect( () => {

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    });


    return {  ref:myRef };
} 