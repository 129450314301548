import React, { Component, Fragment, useState, useEffect } from 'react';
import './public.css';
import Request from '../common/Request';
import Utilities from '../common/Utilities';
import { Validation, Paths } from '../common/Constants';
import { Redirect } from 'react-router-dom';
import OverlayContainer from '../common/OverlayContainer';
import { useForm } from "react-hook-form";

export function ConfirmEmail (props){

  var [message, setMessage] = useState("emailConfirmationInProgress");

  useEffect(  () => {

    console.log(props.history);
    async function confirmEmail(){


        var result = await Request.Post(Paths.ConfirmEmail, { Code:  props.parsedQueryString.code, UserId : props.parsedQueryString.userId});
        if (result.ok){
          setMessage("emailConfirmationSuccess")
        }
        else{
          setMessage("emailConfirmationFailure");
        }
      
    }

    confirmEmail();
  

  }, []);

  const close = async () => {
    if (props.overlayStates){
      props.overlayStates.hide();
    }
    else{
      return Redirect("/dashboard");
    }
  }
  

    return <OverlayContainer  abort={close}>
        <Fragment>
  

            <h1>Email Confirmation</h1>
            
            <p>{props.t(message)}</p>
   

        </Fragment>
      </OverlayContainer>
  
 
  
}
