import React, { Component } from 'react';
import './public.css';

export class Intro2 extends Component {

    async componentDidMount() {

    }



    render() {

        return (
            <div id="Intro2"  className="parallexContainer">
                <div  className="public Intro2">
                    <h2>With WEB‣AR you can display content via mobile browsers in augmented reality.</h2>
                   
                </div>

            </div>
        );
    }
}
