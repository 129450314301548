import React, { Component, Fragment } from 'react';


import {useVerticalScrollParallax} from '../common/VerticalScrollParallax';

export function Tutorial (props){

  const p4 = useVerticalScrollParallax  ();

  return (

    <div id="Tutorial"  className={ props.onHome ? "parallexContainer" : "" } ref={p4.ref}>
          { !props.onHome && <div className="pagePadding"></div>

        }

        <div className={"public Tutorial " + (props.alt ? " alt" : "")}>
            <div className="tutorial_header" ><h2>Tutorials</h2></div>
            <div className="tutorial_container" >
                <a href={props.t("lnkTutorialOne")}> </a>
                <a href={props.t("lnkTutorialTwo")}> </a>
                <a href={props.t("lnkTutorialThree")}> </a>
                <a href={props.t("lnkTutorialFour")}> </a>

            </div>
        </div>
      </div>
    );
}
