import React, { Component, useState, useEffect } from 'react';
import User from './User';

import Url from './Url';

import {useUserSettings} from './Hooks';

export default function UrlEditor (props) {

    const [prefix, setPrefix] = useState("");
    const [fullLink, setFullLink] = useState("");

    var readOnly = false;

    if (props.readyOnly !== 'undefined')
        readOnly = props.readOnly;

    
    var userSettings = props.userSettings;

    if (userSettings){
        console.log("accessing url");
        var init = async () => {

            setPrefix((await Url.getPrefix()).replace("https://", ""));
            setFullLink(await Url.getFullLink());
        };
        init();
    }
    else{
        return null;
    }
    
    if (readOnly)
    {
        return (		
            <div>		
                <label htmlFor="Link">{props.t("lblCustomUrl")}</label>
                <div className="input-group mb-3">
                    <a href={fullLink}>
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="emailpre">{fullLink}</span>
                        </div>
                    </a>

                </div>
            </div> );
    }
    else
    {
        var className = "form-control";
        if (props.error)
        {
            className = "form-control is-invalid";
            
        }
        // else if (props.isValid === false){
        //     className = "form-control is-valid";
        // }
        

        return (		
            <div className="form-group">

                <label htmlFor="Link">{props.t("lblCustomUrl")}</label>


                
            {/*    <div className="input-group mb-3">
                    
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="emailpre">{prefix}</span>
                    </div>
                     <input  placeholder={props.t("phCustomUrl")} ref={props.forwardRef}  name="linkAddress"  type="text" className={className} id="Link" aria-describedby="emailpre" />
      
                    { props.error && props.error.message && <div class="invalid-feedback">{props.error.message}</div> }

                    { props.error &&  !props.error.message && props.error.type && <div class="invalid-feedback">
                        { props.t(props.error.type) }
                    </div> } 
                </div> */}


                <div className={"form-row customUrl form-control d-flex justify-content-start " +  (props.error && "is-invalid") } style={ {"margin" : "0px"}}  >
                    <label  style={ {"paddingLeft" : "0px", "paddingRight" : "0px" }} htmlFor="CustomUrl" name="linkAddress" id="lblLinkAddress" className="col-form-label"><span>{prefix}</span></label> 
                    <input placeholder={props.t("phCustomUrl")} ref={props.forwardRef}  name="linkAddress"  type="text" style={ {"height" : "unset", "border" : "0px"}} className={className} id="Link" ariaDescribedby="lblLinkAddress" ></input>
                </div>
                
                { props.error && props.error.message && <div className="invalid-feedback">{props.error.message}</div> }

                { props.error &&  !props.error.message && props.error.type && <div class="invalid-feedback">
                    { props.t(props.error.type) }
                </div> } 

                
                {/* {
                    props.isValid &&
                    <div class="valid-feedback">
                        Looks good!
                    </div>
                } */}
               
                    

               
            </div> );
    }


    

}

