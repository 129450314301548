import React, { Component, useState, useRef, useEffect, Fragment } from 'react';
import Dropzone, {useDropzone} from 'react-dropzone'
import { Redirect } from 'react-router';
import Request from '../common/Request';
import Utilities from '../common/Utilities';
import UrlEditor from '../common/UrlEditor';
import { useForm } from "react-hook-form";

import { TextInput ,TextInputLine } from '../common/TsInput';

import { User } from '../common/User';
import { PlansData } from '../common/Constants';
import { usePaymentStatus, useUserSettings } from '../common/Hooks';

import './UploadAsset.css';
import Loading from '../common/Loading';

export default function UploadAsset (props) {
	
	const { handleSubmit, register, getValues, errors, setError, clearError, formState, triggerValidation } = useForm();


	const [allFiles, setAllFiles] = useState([]);


	var acceptedFiles = allFiles.filter(p => p.isValid);
	var tsRejectedFiles = allFiles.filter(p => !p.isValid || ( typeof p.textureSizeInvalid !== 'undefined' && !p.textureSizeInvalid));

	const [navigateToAsesetLinks, setNavigateToAsesetLinks] = useState(false);

	const [isBusy, setIsBusy] = useState(false);
	
	const [linkAddressIsValid, setLinkAddressIsValid] = useState(true);

	const [allValid, setAllValid] = useState(true);

	var userSettings = useUserSettings();
	var paymentStatus = usePaymentStatus();
	
	useEffect( () => {
		console.log("useEffect triggered");
		if (userSettings && paymentStatus)
			triggerValidation("acceptedFiles");
	}, [allFiles]);

	const abortUpload = (ev) =>
	{
		if (!ev.target.classList.contains("abort")){
			return;
		}
		props.onFinished({
			success : false
		});
	}

	const onSubmit = async (ev) => {

		setIsBusy(true);
		console.log(errors);
		console.log(ev);
	


		const data = new FormData();


		//create new
		if (!props.assetLink){
			allFiles.map((file) => data.append('file', file));
		
			data.append('Name', ev.name);
			data.append('DisplayName', ev.name);
			data.append('LinkAddress', ev.linkAddress);
	
	
	
			var ret = (await Request.PostForm(Request.Paths.PostAssetLink, data)).data;
		}
		//update => new asset transformation
		else
		{
			allFiles.map((file) => data.append('file', file));
			
			data.append('assetLinkId', props.assetLink.assetLinkId);
	
			var ret = (await Request.PostForm(Request.Paths.AssetTransformation, data)).data;
		}
		


	
		


		console.log("paymentStatus ", paymentStatus);

		if (ret && ret > 0)
		{
			Utilities.showAlert({ message: 'Success', type : 'success' });

			if ( props.onFinished){
				props.onFinished({
					id : ret,
					success : true
				});
			}
			else
			{
				setNavigateToAsesetLinks(true);
			}
			
		}

		setIsBusy(false);
	}


	const checkImage = (file, files) => {
		
		var reader = new FileReader();

		//Read the contents of Image File.
		reader.readAsDataURL(file);
		reader.onload = function (e) {

			//Initiate the JavaScript Image object.
			var image = new Image();

			//Set the Base64 string return from FileReader as source.
			image.src = e.target.result;

			//Validate the File Height and Width.
			image.onload = function () 
			{
				var height = file.height =  this.height;
				var width = file.width = this.width;
				console.log("image dimensions", height, width);



				checkImages(files);
				//show error message

			//	alert("Uploaded image has valid Height and Width.");
				return true;
			};

			
		}
	}

	const checkImages = (files) => {
		if (files.length > 0){

			var imgSizeDoesntMatter = files.filter(p => isSupportedImageFile(p.name)).length == files.length;

			files.map ( (file) => {

				if (file.height && file.width){
					if ( (file.height > paymentStatus.textureHeightLimit  || file.width > paymentStatus.textureWidthLimit) && ! imgSizeDoesntMatter) {
						file.textureSizeInvalid = false;
					}
					else{
						file.textureSizeInvalid = true;
					}
				}
			});
		}


		
	//	setAllFiles(JSON.parse(JSON.stringify(files))); //redraw

		setTimeout(() => triggerValidation("acceptedFiles"), 100);

		
	}


	const isSupportedImageFile = (p) => {
		return p.toLowerCase().endsWith(".png")  || p.toLowerCase().endsWith(".jpg") || p.toLowerCase().endsWith(".jpeg");
	}
	
	

		//glb only

		//gltf + max one bin + jpg or pngs
		
		//one png or one jpg
		
		
	
	const updateFiles = (files) => {

		var newAcceptedFiles = [];
		var unsupportedFiles = [];

		if (files.length > 0)
		{
			if (files.find(p => p.name.toLowerCase().endsWith(".fbx")) != null)
			{
				var filter = (p) =>  p.toLowerCase().endsWith(".fbx") || isSupportedImageFile(p) ;
				newAcceptedFiles = files.filter( p => filter(p.name)  );
				unsupportedFiles = files.filter(p => !filter(p.name) );
			}

			else if (files.find(p => p.name.toLowerCase().endsWith(".dae")) != null)
			{
				const filter = (p) =>  p.toLowerCase().endsWith(".dae") || isSupportedImageFile(p);

				newAcceptedFiles = files.filter( p => filter(p.name) );
				unsupportedFiles = files.filter(p => !filter(p.name) );
			}
			else if (files.find(p => p.name.toLowerCase().endsWith(".3ds")) != null)
			{
				const filter = (p) =>  p.toLowerCase().endsWith(".3ds") || isSupportedImageFile(p);

				newAcceptedFiles = files.filter( p => filter(p.name) );
				unsupportedFiles = files.filter(p => !filter(p.name) );
			}

			else if (files.find(p => p.name.toLowerCase().endsWith(".obj")) != null)
			{
				const filter = (p) =>  p.toLowerCase().endsWith(".obj") ||  p.toLowerCase().endsWith(".mtl") || isSupportedImageFile(p);

				newAcceptedFiles = files.filter( p => filter(p.name) );
				unsupportedFiles = files.filter(p => !filter(p.name) );
			}
			else if (files.find(p => p.name.toLowerCase().endsWith(".glb")) != null && files.find(p => p.name.toLowerCase().endsWith(".usdz")) != null) //finished transform
			{
				const filter = (p) =>  p.toLowerCase().endsWith(".glb") ||  p.toLowerCase().endsWith(".usdz");

				newAcceptedFiles = files.filter( p => filter(p.name) );
				unsupportedFiles = files.filter(p => !filter(p.name) );
			}
			else if (files.find(p => p.name.toLowerCase().endsWith(".gltf")) != null && files.find(p => p.name.toLowerCase().endsWith(".usdz")) != null) //finished transform
			{
				const filter = (p) =>  p.toLowerCase().endsWith(".gltf") || isSupportedImageFile(p) || p.toLowerCase().endsWith(".bin") || p.toLowerCase().endsWith(".usdz");
				newAcceptedFiles = files.filter( p => filter(p.name));
				unsupportedFiles = files.filter( p => !filter(p.name));
			}
			else if (files.find(p => p.name.toLowerCase().endsWith(".gltf")) != null) //we do the usdz
			{
				const filter = (p) =>  p.toLowerCase().endsWith(".gltf") || isSupportedImageFile(p) || p.toLowerCase().endsWith(".bin");
				newAcceptedFiles = files.filter( p => filter(p.name));
				unsupportedFiles = files.filter( p => !filter(p.name));
			}
			else if (files.find(p => p.name.toLowerCase().endsWith(".glb")) != null) //we do the usdz
			{
				const filter = (p) =>  p.toLowerCase().endsWith(".glb");
				newAcceptedFiles = files.filter( p => filter(p.name));
				unsupportedFiles = files.filter( p => !filter(p.name));
			}
			else if (files.find(p => isSupportedImageFile(p.name))){
				files.map ( (val, index) => {
	
					//checkImage(val);
	
					if (index == 0){
						newAcceptedFiles.push(val);
					}
					else{
						unsupportedFiles.push(val);
					}
				});
			}
			else{
				files.map ( (val, index) => {
					unsupportedFiles.push(val);
				});
			}
		}
	
	
		files.filter(p => isSupportedImageFile(p.name)).map( f => checkImage (f, files));
		

		// acceptedFiles = newAcceptedFiles;
		// tsRejectedFiles =  unsupportedFiles; 
	
		newAcceptedFiles.map (p => p.isValid = true);
		unsupportedFiles.map (p => p.isValid = false);

		setAllFiles(files);
		checkImages(files);

		triggerValidation("acceptedFiles");

		
	}

	// setAcceptedFiles(newAcceptedFiles);
	// setTsRejectedFiles(unsupportedFiles);

		
	

	

	const {getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles} = useDropzone({onDrop: (newFiles) => updateFiles(allFiles.concat(newFiles)) , minSize:Utilities.minUploadFileSize, maxSize:Utilities.maxUploadFileSize, multiple:true});

	const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > Utilities.maxUploadFileSize;


	const validateUrl = (urlEnding) => {

		var isValid = true;
		console.log("validaing", urlEnding);
		if (urlEnding !== 'undefined'){
			if (props.assetLinks.length > 0){
				
				props.assetLinks.forEach(link => {
				
					if (link.linkAddress.endsWith('/' + urlEnding)){
						console.log("already exists");
						isValid = false;
					}
				});
			}
		}
		
		return isValid;
	}


	const validateCharacters = (val) => {
		return val.length > 2 && val.length < 100;
	}

	//https://getbootstrap.com/docs/4.4/components/forms/
	//https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
	//minSize = { 0}
	//maxSize = { 20242880}

	const validateAccptedFiles = (p) => {
		

		var allGood = true;



		var totalLength = allFiles.filter(p => p.isValid).reduce( (total, file) => {return total + file.size; }, 0 ) / 1024 / 1024;
		

		console.log("total length" , totalLength, paymentStatus.uploadLimit);
		if ( totalLength > paymentStatus.uploadLimit ){
			//setError("acceptedFiles", "uploadLimit", props.t("uploadLimit").replace("{0}", paymentStatus.uploadLimit ) );
			allGood = false;
			return props.t("uploadLimit").replace("{0}", paymentStatus.uploadLimit );
		}

		if (allFiles.filter(p => typeof p.textureSizeInvalid !== 'undefined' && !p.textureSizeInvalid).length > 0 ){
		//	setError("acceptedFiles", "imageSize", props.t("imageSize").replace("{0}", paymentStatus.uploadLimit ) );
			allGood = false;
			return props.t("imageSize").replace("{0}",  paymentStatus.textureWidthLimit + "x" + paymentStatus.textureHeightLimit);
		}
	
	
		if (allFiles.filter(p => !p.isValid).length > 0 ){
			
			allGood = false;

			//setError("acceptedFiles", "unsupportedFiles", props.t("thereAreUnsupportedFiles") )
			return props.t("thereAreUnsupportedFiles");
			
		}
		
		
		if (allFiles.filter(p => p.isValid).length <= 0 ){
			allGood = false;
	
			//setError("acceptedFiles", "required", props.t("requiredField"));
			return props.t("requiredField");
		}
		



		if (allGood){
			console.log("files valid!");
		}
		else{
			console.log("Files invalid!");
		}
		return allGood;
	}

	// if (formState.isSubmitted)
	// 	validateAccptedFiles();

	const submitHandler = handleSubmit(onSubmit);

	const mySubmitHandler = (ev) => {
	
		submitHandler(ev);

	}

	const removeFile = (id, ev) => {
		console.log(ev.target);
	
		ev.stopPropagation();

		updateFiles(allFiles.filter((element, index) => index  != id) );
	}


	const renderX = () => {
		return <svg width="20px" height="20px" style={{"margin":"5px" }} viewBox="0 0 30 30" >
			<title>Options_idle Copy</title>
			<desc>Created with Sketch.</desc>
			<g id="Options_idle-Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M15,13.4241245 L18.1712062,10.3891051 L19.6692607,11.8093385 L16.4980545,14.844358 L19.6692607,17.8793774 L18.1712062,19.2996109 L15,16.2645914 L11.8287938,19.2996109 L10.3307393,17.8793774 L13.5019455,14.844358 L10.3307393,11.8093385 L11.8287938,10.3891051 L15,13.4241245 Z M15,27.5097276 C21.9649805,27.5097276 27.3540856,21.92607 27.3540856,15.0194553 C27.3540856,8.11284047 21.9649805,2.52918288 15,2.52918288 C8.03501946,2.52918288 2.6459144,8.11284047 2.6459144,15.0194553 C2.6459144,21.92607 8.03501946,27.5097276 15,27.5097276 Z M15,30 C6.75097276,30 0,23.2490272 0,15 C0,6.75097276 6.75097276,0 15,0 C23.2490272,0 30,6.75097276 30,15 C30,23.2490272 23.2490272,30 15,30 Z" id="×" fill="#FFFFFF" fillRule="nonzero"></path>
			</g>
		</svg>
	}

	if (navigateToAsesetLinks){
		return  <Redirect to='/asset/AssetLinks' />
	}


	return (
		<div className={"assetUpload overlay" +  (props.closeOnBgClick ? " abort" : "") } onClick={abortUpload}>
 
			{isBusy && <Loading></Loading> }
			<div className="container">
		

				<div className="headline d-flex justify-content-between">

					<div> </div>
					<h1> { !props.assetLink  ? props.t('uploadAsset') : props.t('changeModel') }</h1>

					{
						props.onFinished &&  <button className="abortButton abort" onClick={abortUpload}></button>
						
					}
				</div>

				<input name="acceptedFiles" type="hidden" ref={register({ validate: { alreadyExists:  validateAccptedFiles } } )} ></input> 
			
					
				<form  onSubmit={mySubmitHandler} >
		

					{ !props.assetLink && <Fragment>
						<TextInputLine t={props.t}  forwardRef={register({ required: props.t('requiredField'), maxLength: 50, minLength: 2 })} label={props.t("lblLinkName")} name={"name"} placeholder={props.t("phLinkName")} error={errors.name} > </TextInputLine>
					
						{/* <TextInputLine t={props.t}  forwardRef={register({ required: props.t('requiredField'), maxLength: 50 }  )} label={props.t("displayName")} name="displayName" placeholder={props.t("displayNamePlaceholder")} error={errors.displayName} > </TextInputLine> */}

						<UrlEditor userSettings={userSettings} t={props.t} forwardRef={register({  required: props.t('requiredField'), maxLength: 50, minLength: 2, validate: { alreadyExists:  validateUrl } } )} error={errors.linkAddress} ></UrlEditor>
					</Fragment>
					}
	
			

					<div  className= { "text-center mt-2 dropzoneParent noSelect" + ( isDragActive ? " dragActive" :"")  + ( (allFiles.length > 0) ? " isFilled" :"" ) +  ((errors.acceptedFiles)  ? " is-invalid" : "" )    }  >

						<div {...getRootProps()} >
							<input {...getInputProps()}  />


								{allFiles.length <= 0  &&  <div>
									<div className="title"> {props.t('clickHereOrDragNDrop') } </div>
									<div className="subtitle"> {props.t('weSupportTheseFiles') }<br/>{props.t('animationHint') } </div>
								</div>}

								{ (allFiles.length > 0 ) && <div>
									<ul className="list-group">

									{allFiles.length > 0 && allFiles.map( (file, index) => (
											<li key={index}  >
												<div  className= { (file.isValid && (typeof file.textureSizeInvalid === 'undefined' || (typeof file.textureSizeInvalid !== 'undefined' &&  file.textureSizeInvalid))) ? "accepted" : "rejected"} id={index}  onClick={(ev) => removeFile(index, ev)}>
													<span> {file.name}</span>

													{renderX()}
												</div>
											</li>
										))}

	
								
										<li className="flex-placeholder"> <div></div></li>
									</ul>
								</div>}

				
			
						</div>

					</div>

			
			
		
					{isFileTooLarge && <div className="invalid-feedback">
						{props.t('errorFileTypeNotAccepted')}
					</div> }

					{isFileTooLarge && <div className="invalid-feedback">
						{props.t('errorFileTooLarge')}
					</div> }

					{errors.acceptedFiles && <div className="invalid-feedback">
							{errors.acceptedFiles.message}
					</div> }





					<div  className="text-center" style={{ marginTop : "34px" }} >
						<button type="submit" className="btn btn-ts">{props.t('upload')}</button>
					</div>
				</form>


			</div>
		</div>
	);
	
}