import React, { Component } from 'react';
import Interweave from 'interweave';
import { Redirect, Link } from 'react-router';

import { HashLink as HashLink } from 'react-router-hash-link';

export default class Plan extends Component {


    constructor (props){
        super(props);

        this.navToPlan = this.navToPlan.bind(this);
        this.state = { navToPlan : false};
    }

    async componentDidMount() {

    }


    navToPlan(){
    
        this.setState( { navToPlan : true});
    }

    render() {
        var plan = this.props.plan;
        if (this.state.navToPlan)
        {
            this.setState({ anchorSet : true, navToPlan : false} );
            return <Redirect push  to={{
                pathname: "/",
                search: "#Plans" }}></Redirect>
        }

        if (this.state.anchorSet){
            
            this.setState({ anchorSet : false} );
            return <Redirect push  to={{
                pathname: "/ChangePlan",
                search: "#" + plan.id,
                state: { selectedPlanId : plan.id } }}></Redirect>
        }
     

        

        return (
            <div className="plan">
                <div  >
                    <h3> {plan.title} </h3>


                    <div className="currencyContainer">
                        {!plan.isEnterprise && <div>€ </div>}
                        {plan.isEnterprise && <div> </div>}
                        {!plan.isEnterprise && <div>{plan.price}</div>}
                        {plan.isEnterprise && <div ><div className="dot"></div></div>}
                        <div>{plan.subtitle}</div>
                    </div>


                    <div className="separator"></div>
                       
                    <p>
                        Includes:
                        <br/>
                        <br/>
                        <Interweave content={plan.p1} />
                    </p>

                    <div className="separator"></div>

                    <p>
                        Content:
                        <br/> <br/>
                        <Interweave content={plan.p2} />

                    </p>
                </div>
         
                <HashLink className="subscribe"  to={ "/ChangePlan?perYear=" + !plan.perMonth + "#" + plan.id } hash={plan.id} >
                    {plan.isEnterprise ? "Get in touch" : "Subscribe" }
                </HashLink>
            </div>
        );
    }
}
