import React, { Fragment, useState, useRef } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Loading from '../common/Loading'
import Request from '../common/Request';
import { Redirect } from 'react-router';

import {SettingType, TransformationStatus} from '../common/Constants';
import './AssetLink.css';

export default function AssetLink (props) {

	const [isBusy, setIsBusy] = useState(false);
	const [navigateToDetails, setNavigateToDetails] = useState(false);
	const [moveClicked, setMoveClicked] = useState(false);
	const [overlayOpen, setOverlayOpen] = useState(false);
	const [overlayCloseForced, setOverlayCloseForced] = useState(true);
	const moveDirectory = useRef(null);
	
	
	const doMove = async (event)=>{
		setIsBusy(true);

        var dir = moveDirectory.value;
        var result = (await Request.Post(Request.Paths.MoveAssetLink, { linkId: props.assetLink.assetLinkId, directory: dir })).data;

        if (result != null) {
            props.onActionFinished({ actionType: 'move', value: { item: props.assetLink, directory: dir } });
        }
	}


    const invokeMoveClicked = (event) => {
   
		setMoveClicked(true);
	}

	const clicked = () => {
		setNavigateToDetails(true);
	}

	const stopClick = (ev) => {
		ev.stopPropagation();
	}

	const menuItemClicked = async (ev) => {
	
		setOverlayOpen(false);

		var name = ev.target.getAttribute('name');
		if ( name == 'delete'){
			if (isBusy)
				return;
			setIsBusy(true);
	
			var result = (await Request.Delete(Request.Paths.DeleteAssetLink, props.assetLink.assetLinkId)).data;
	
			setIsBusy(false);
			if (result != null)
			{
				 props.onActionFinished({ actionType : 'delete', item: props.assetLink });
			}
		}
		else if (name == 'openLink'){
			var win = window.open(window.location.origin + "/view/" +  props.assetLink.linkAddress, '_blank');
			win.focus();

			 props.onActionFinished({ actionType : 'openLink', item: props.assetLink });

		}
		else if (name == 'editLink'){
			 setNavigateToDetails(true);
		}
		else if (name == 'duplicate'){
			setIsBusy(true);
	
			var result = (await Request.Post(Request.Paths.DuplicateAssetLink, { 
				LinkId :  props.assetLink.assetLinkId,
			    Link :  props.assetLink.assetLinkAddress })).data;
	
			setIsBusy(false);
			if (result != null)
			{
				 props.onActionFinished({ actionType : 'duplicate', item: props.assetLink });
			}
		}
	
	}


	const overlayClosed = () => {
		 setOverlayOpen(false);
	}
	const overlayOpened = () => {
		 setOverlayOpen(true);
	}


	var published = props.assetLink.assetLinkSettings.find(p => p.assetLinkSettingType == SettingType.published);
	
	var draft = props.assetLink.assetLinkSettings.find(p => p.assetLinkSettingType == SettingType.draft);

	var draftStatus = draft.activeAssetTransformation.lastAssetTranformationStatus.status;
	
	const placement = 'right';

	const overlay = (<OverlayTrigger
							trigger="click"
							key={placement}
							placement={placement}
							rootClose={true}
							onExit={ overlayClosed}
							onEnter={ overlayOpened}
							defaultShow={true}
							overlay={
								<Popover id={`popover-podsitioned-${placement}`}>
								{/* <Popover.Title as="h3">{`Popover ${placement}`}</Popover.Title> */}
								<Popover.Content>
										<div className="contextMenuItem"><button className="btn btn-outline-secondary" onClick={ menuItemClicked} name="openLink">{ props.t("openLink")}</button></div>
									<div className="contextMenuItem"><button className="btn btn-outline-secondary"   onClick={ menuItemClicked} name="editLink">{ props.t("editLink")}</button></div> 
								{/* { <div className="contextMenuItem"><button className="btn btn-outline-secondary"  onClick={ menuItemClicked}  name="duplicate">{ props.t("duplicate")}</button></div> } */}
								{/* {    moveClicked && <div><input ref={(ref) => {  moveDirectory = ref; }} type="text"></input> <Button onClick={ doMove} variant="outline-dark" size="sm">Ok</Button></div> }
								{ !   moveClicked && <div><Button variant="outline-dark" size="sm" onClick={ moveClicked} >Move</Button></div> } */}
									<div className="contextMenuItem"><button className="btn btn-outline-secondary"   onClick={ menuItemClicked} name="delete">{ props.t("delete")}</button></div>
								</Popover.Content>
								
								</Popover>
							}>
									<button className={  overlayOpen ? "optionsButton selected" : "optionsButton"  } ></button>
						</OverlayTrigger>);

				
	if (   navigateToDetails)
		return  <Redirect push to={'/asset/AssetLinkDetails?id=' +  props.assetLink.assetLinkId } />
	
	return (
		<div className="assetLinkContainer">
			<div className="assetLink" >

				<div onClick={ clicked} className="d-flex justify-content-between"> 
					<div className="title"> {  props.assetLink.name } </div>	
			
					<div onClick={ stopClick} className="optionsButtonContainer" >
						{     overlayOpen && overlay}
						{  !overlayOpen && <button className={ "optionsButton"  } onClick={ overlayOpened} ></button> }
						{/* { !   isBusy && overlayCloseForced &&   */}

						{/* {    isBusy && <div className="verticalcenter">
													<div className="horizontalcenter">
															<div className="spinner-border" role="status">
															<span className="sr-only">Loading...</span>
															</div>
														</div>
													</div> } */}

					</div>
				</div>


				<div style={ {position:"relative", top : "-12px", height:"31px"}}>
					{ published && <Fragment><svg height="16" width="16" style={ {position:"relative", top : "-2px"}}> 
										<circle cx="8" cy="8" r="5" fill={"#4bc8a5"} />
									</svg>
									<span style={{ "paddingLeft":"3px", "paddingTop" : "6px"}} className="description">{  props.assetLink.viewCnt } Views{draft.activeAssetTransformation.assetId != published.activeAssetTransformation.assetId ? ", unpublished changes" : null} </span> </Fragment> }

					{ !published && <span style={{ "paddingLeft":"3px", "paddingTop" : "6px"}} className="description"> unpublished </span> }

					{ }
				
				</div>
				
			</div>
		</div>
	);

	

	
}


