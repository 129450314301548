
import {useEffect, useState} from 'react';


export function useScrollHeightGt(scrollHeight){

    var [isGreater, setIsGreater] = useState(false);
    const handleScroll = () => 
    {
        // var header = document.querySelector("header");
        // function add_class_on_scroll() {
        //     header.classList.add("active");
        // }

        // function remove_class_on_scroll() {
        //     header.classList.remove("active");
        // }

        //Here you forgot to update the value
        var scrollpos = window.scrollY;

        
        setIsGreater( scrollpos > scrollHeight);
        if (scrollpos > scrollHeight) { //10
           // add_class_on_scroll();
        }
        else {
          //  remove_class_on_scroll();
        }
    }


    useEffect( () => {

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    });


    return isGreater;
}

    

   