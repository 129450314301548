import React, {  Suspense, Component, useEffect, Fragment, useState } from 'react';


import { Route,  Redirect, Link , Switch } from 'react-router';
import { Home } from './components/Home';
import { Tutorial } from './components/public/Tutorial';
import { Settings } from './components/user/Settings';
import { ChangePlan } from './components/user/ChangePlan';
import  UploadAsset  from './components/asset/UploadAsset';
import './custom.css'
import  AssetLinks  from './components/asset/AssetLinks';
import  AssetLinkDetails  from './components/asset/AssetLinkDetails';
import { LinkNotPublished  } from './components/public/LinkNotPublished';
import { Login  } from './components/public/Login';
import { Logout  } from './components/public/Logout';

import { useTranslation  } from 'react-i18next';

import { useIsMobile } from './components/common/IsMobile'

import { Container, NavLink } from 'reactstrap';
import { NavMenu } from './components/NavMenu';
import AlertContainer from './components/common/AlertContainer';

import LanguageBar from './components/common/LanguageBar';

import {Helmet} from "react-helmet";

import { useHistory } from "react-router-dom";

import  Faq  from "./components/public/Faq";
import  About  from "./components/public/About";
import  Support  from "./components/public/Support";

import OrderStatus from "./components/user/OrderStatus";
import { Footer } from './components/public/Footer';
import { PaymentFailedOverlay } from './components/overlays/PaymentFailedOverlay';

import { useAuthentication } from './components/common/Authentication';
import { usePaymentStatus, useUserSettings } from './components/common/Hooks';

import { TermsAndPrivacy } from './components/public/TermsAndPrivacy';

import { Imprint } from './components/public/Imprint';

import FullScreenLoad from './components/common/FullScreenLoad';
import { Register } from './components/public/Register';
import { ResetPassword } from './components/public/ResetPassword';
import { PasswordForgotten } from './components/public/PasswordForgotten';
import { ConfirmEmail } from './components/public/ConfirmEmail';
import Request from './components/common/Request';

function OverlayMessages(props){

    var [overlayClosed, setOverlayClosed] = useState(false);

    return  <Fragment>
        { props.payment && props.payment.hasForcefullyUnpublishedLinks && !overlayClosed && <PaymentFailedOverlay abort={ () => setOverlayClosed(true) }> </PaymentFailedOverlay>}
    </Fragment>
}

function useOverlayState(){
    const [isShown, setIsShown] = useState(false);
    const [redirect, setRedirect] = useState(null);
    return {
        isShown : isShown,
        setIsShown : setIsShown,
        redirect : redirect,
        setRedirect : setRedirect
    }
}

function WebAr (appProps) {

    const trans = useTranslation('common');

    const history = useHistory();
    //console.log(history, history.location, history.location);
    var isMobile = useIsMobile();

    const [blurIntensity, setBlurIntensity] = useState (14);
    const [blurAnimation, setBlurAnimation] = useState (false);
    var showFooter = !history.location.pathname.startsWith('/asset/AssetLinkDetails');


    const auth = useAuthentication();

   // var payment = usePaymentStatus({ auth : auth, history:history});
    var user = useUserSettings({ auth : auth, history:history});  

    var loginOverlayState = useOverlayState();
    var registerOverlayState = useOverlayState();
    var forgotPasswordOverlayState = useOverlayState();
    var confirmEmailOverlayState = useOverlayState();
    var resetPasswordOverlayState = useOverlayState();

    var hide = () => {
        loginOverlayState.setIsShown(false);
        registerOverlayState.setIsShown(false);
        forgotPasswordOverlayState.setIsShown(false);
        confirmEmailOverlayState.setIsShown(false);
        resetPasswordOverlayState.setIsShown(false);
      
        history.push("");
    };
    var overlayStates = {
        loginOverlayState: loginOverlayState,
        registerOverlayState : registerOverlayState,
        forgotPasswordOverlayState : forgotPasswordOverlayState,
        confirmEmailOverlayState : confirmEmailOverlayState,
        resetPasswordOverlayState : resetPasswordOverlayState,

        hide : hide,
        showOverlay : (overlay) => {
            hide();
            overlay.setIsShown(true);
        }
    }

    //console.log("translation", trans);


    const [parsedQueryString, setParsedQueryString] = useState(null);

    const needsAuth = () => {
        return history.location.pathname == "/ChangePlan" ||  history.location.pathname == "/dashboard"; 
    }

    useEffect( () => {

        if (history.location.pathname == "/login"){
            loginOverlayState.setIsShown(true);
            history.replace("");
        }

        if (history.location.pathname == "/register"){
            registerOverlayState.setIsShown(true);
            history.replace("");
        }

        if (history.location.pathname == "/passwordForgotten"){
            forgotPasswordOverlayState.setIsShown(true);
            history.replace("");
        }

        if (history.location.pathname == "/confirmEmail"){
            setParsedQueryString(Request.ParseQueryString(history.location.search));
            confirmEmailOverlayState.setIsShown(true);
            history.replace("");
        }

        if (history.location.pathname == "/resetPassword"){
            setParsedQueryString(Request.ParseQueryString(history.location.search));
            resetPasswordOverlayState.setIsShown(true);
            history.replace("");
        }

        if (blurIntensity > 0){
            setTimeout( () => setBlurIntensity(blurIntensity-1), 60 );
        }
        

    }, [blurIntensity]);

    // useEffect( () => {
    //     console.log("useEffect");
    //     if (blurIntensity > 0){
    //         setTimeout( () => setBlurIntensity(blurIntensity-1), 1000 );
    //     }
        

    // }, [setBlurAnimation]);

    if (!blurAnimation && trans.ready){
        setBlurAnimation(true);
        setBlurIntensity(blurIntensity-1);
    }


    return (
        <div className={ isMobile ? "mobile" : "notMobile" } >
           { blurIntensity > 0 && <div style={  { "top" : "0px", "left" : "0px", "width" : "100%", "height" : "100%", "position" : "fixed", "backdropFilter" : "blur(" + blurIntensity + "px)", "zIndex" : 5, "backgroundColor": "#00000000" }}> </div> }
         
         
          {/* { (!trans.ready) &&  }  */}
{/* 
            width: 100%;
	top: 0px;
	left: 0px;
	height: 100vh;
	backdrop-filter: blur(6px);
	position: fixed;
	z-index: 3;
	background-color: #00000080; */}

            <Helmet>
                <title>WEB-AR</title>
                <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="Expires" content="0" />
                <link rel="stylesheet" type="text/css" href="/css/global.css" />
                <link rel="icon"  type="image/png"  href="/images/favicon.jpg" />
                {/* <script src="https://js.stripe.com/v3/"></script> */}
            </Helmet>
            
            
    
            <Route exact path='*' render={(props) => { return <NavMenu overlayStates={overlayStates}  user={user} auth={auth} {...props} isMobile={isMobile} {...appProps} {... trans} /> }}  /> 

            <OverlayMessages user={user}></OverlayMessages>

            <AlertContainer></AlertContainer>


            {/* <Route exact path='/login' render={(props) => { return <Home {...props} {...appProps}  {... trans}/> }} /> */}
            {  (loginOverlayState.isShown || ( needsAuth() && !auth.isAuthenticated ) ) &&  <Login history={history}  overlayStates={overlayStates} auth={auth} {...appProps}  {... trans}/> }


            {/* <Route exact path='/register' render={(props) => { return <Register auth={auth} {...props} {...appProps}  {... trans}/> }} /> */}
            {  registerOverlayState.isShown &&  <Register history={history}  overlayStates={overlayStates}  auth={auth} {...appProps}  {... trans}/> }
            {  forgotPasswordOverlayState.isShown &&  <PasswordForgotten overlayStates={overlayStates}  auth={auth} {...appProps}  {... trans}/> }
            {  resetPasswordOverlayState.isShown &&  <ResetPassword overlayStates={overlayStates} parsedQueryString={parsedQueryString} auth={auth} {...appProps}  {... trans}/> }

            {  confirmEmailOverlayState.isShown &&  <ConfirmEmail overlayStates={overlayStates}  parsedQueryString={parsedQueryString} auth={auth} {...appProps}  {... trans}/> }


            <Route exact path='/logout' render={(props) => { return <Logout auth={auth} {...props} {...appProps}  {... trans}/> }} />
            {/* <Route exact path='/' render={(props) => { return <Home {...props} {...appProps}  {... trans} /> }} />*/}
         
                {
                    !auth.isAuthenticated &&  <Fragment>
                        <Switch>
                            <Route exact path='/'  render={(props) => { return <Home {...props} {...appProps}  {... trans} /> }} />
                            <Route exact path='/support' render={(props) => { return <Support {...props} isMobile={isMobile} {...appProps} {... trans} /> }}  /> 
                            <Route exact path='/about' render={(props) => { return <About {...props} isMobile={isMobile} {...appProps} {... trans} /> }}  /> 
                            <Route exact path='/faq' render={(props) => { return <Faq {...props} isMobile={isMobile} {...appProps} {... trans} /> }}  /> 
                            <Route exact path='/tutorial' render={(props) => { return <Tutorial alt {...props}  {...appProps} {... trans} /> }} />
                            <Route exact path='/NotPublished' render={(props) => { return <LinkNotPublished {...props} {...appProps} {... trans} /> }} />
                            <Route exact path='/TermsAndPrivacy' render={(props) => { return <TermsAndPrivacy {...props} {...appProps} {... trans} /> }} />
                            <Route exact path='/Imprint' render={(props) => { return <Imprint {...props} {...appProps} {... trans} /> }} />
   
                            <Route path='*'  render={(props) => { return <Home {...props} {...appProps}  {... trans} /> }} /> 
                            </Switch>
                        </Fragment>

                }

                {
                    auth.isAuthenticated && <Fragment>
                        <Switch>

                            <Route exact path='/'  render={(props) => { return <Home {...props} {...appProps}  {... trans} /> }} />
                            <Route exact path='/support' render={(props) => { return <Support {...props} isMobile={isMobile} {...appProps} {... trans} /> }}  /> 
                            <Route exact path='/about' render={(props) => { return <About {...props} isMobile={isMobile} {...appProps} {... trans} /> }}  /> 
                            <Route exact path='/faq' render={(props) => { return <Faq {...props} isMobile={isMobile} {...appProps} {... trans} /> }}  /> 
                            <Route exact path='/tutorial' render={(props) => { return <Tutorial alt {...props}  {...appProps} {... trans} /> }} />
                            <Route exact path='/NotPublished' render={(props) => { return <LinkNotPublished {...props} {...appProps} {... trans} /> }} />
                            <Route exact path='/TermsAndPrivacy' render={(props) => { return <TermsAndPrivacy {...props} {...appProps} {... trans} /> }} />
                            <Route exact path='/Imprint' render={(props) => { return <Imprint {...props} {...appProps} {... trans} /> }} />


                            <Route  path='/settings' render={(props) => { return <Settings {...props} {...appProps}  {... trans}/> }} />
                            <Route  path='/changePlan'  render={(props) => { return <ChangePlan {...props} {...appProps}  {... trans}/> }} />
                            <Route  path='/orderStatus'  render={(props) => { return <OrderStatus {...props} {...appProps}  {... trans}/> }}  />
                            <Route  path='/dashboard' render={(props) => { return <AssetLinks {...props} {...appProps}  {... trans}/> }}  />
                            <Route path='/asset/UploadAsset' render={(props) => { return <UploadAsset {...props} {...appProps}  {... trans}/> }}  />
                            <Route path='/asset/AssetLinkDetails' render={(props) => { return <AssetLinkDetails {...props} {...appProps}  {... trans}/> }}  />


                            <Route path='*'  render={(props) => { return <Home {...props} {...appProps}  {... trans} /> }} /> 
                        </Switch>
                    </Fragment>
                }

          
			{/*  <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} /> */}
            
            { showFooter && <Footer {...appProps} {... trans} isMobile={isMobile}></Footer> }
        </div>
    
      
    );
  
}

// export default withTranslation('common') (App); //

export default function App() {
    return (

        <WebAr />
        // <Suspense fallback={<FullScreenLoad></FullScreenLoad>}>
            
        // </Suspense>
    );
}

{/* <FullScreenLoad></FullScreenLoad> */}



   /* <AuthorizeRoute path='/asset/UploadAsset' component={UploadAsset} /> */