import {useState, useEffect} from 'react';

export function useIsMobile(){
    const [isMobile, setIsMobile] = useState()



    const updateWindowDimensions = () => {
        setIsMobile( window.innerWidth < 576);
    };

    useEffect( () => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        }
    });
  

    return isMobile;
}