import React, { Component, useState, useEffect, useRef } from 'react';

export default function OverlayContainer(props)
{

    var doAbort = (ev) => 
    {
        if (!ev.target.classList.contains("abort")){
			return;
        }
        if (props.abort)
            props.abort();
    }



    return <div  className= {"overlay abort " + (props.className ? props.className : "")}  onClick={doAbort }  >
        <div className="container" >

            <div style={{position : "relative", width : "100%"} }> 
                {
                    doAbort &&  <button style={{position : "absolute", right : "0px"}}  className="abortButton abort" onClick={ doAbort}></button>
                }
            </div>

            <div> { props.children } </div>

        </div>
    </div>
}